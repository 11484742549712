import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../reducer'
import { Button, Card, Flex, Form, message, Space, Tabs } from 'antd';
import VisaForm from './VisaForm';
import { useNavigate } from 'react-router-dom';
import _ from "lodash"
import { convertFileToBase64 } from '../../utils/base64Converter';
import AxiosService from '../../utils/APIService';
import { onLoading } from '../../action';

const { TabPane } = Tabs;

function ApplicationForm({ reqParams, isLoading }: any) {
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState('0')
  const [formList, setFormList] = useState([])

  const axiosService = new AxiosService()
  let saveSubmit: boolean = false;

  const handleFinish = async (values: any) => {
    onLoading(true)
    let data = _.toArray(values)
    if (data.length !== formList.length) {
      message.error("Please Fill All the Forms.")
      return
    }
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      element.traveling = reqParams.travelling
      element.nationality = reqParams.nationality
      if (element.birthId) {
        element.birthId = await convertFileToBase64(element.birthId)
      }
      if (element.passportBack) {
        element.passportBack = await convertFileToBase64(element.passportBack)
      }
      if (element.passportFront) {
        element.passportFront = await convertFileToBase64(element.passportFront)
      }
      if (element.travellerPhoto) {
        element.travellerPhoto = await convertFileToBase64(element.travellerPhoto)
      }
      if (element?.extraDoc?.fileList.length > 0) {
        let docList: any[] = []
        for (let j = 0; j < element?.extraDoc?.fileList.length; j++) {
          const files = element?.extraDoc?.fileList[j];
          let imgCode = await convertFileToBase64(files.originFileObj)
          docList.push(imgCode)
        }
        element.extraDoc = docList
      }
    }

    axiosService.post('/app/create-group', {
      data: data,
      childVisaType: reqParams?.childVisaType || undefined,
      adultVisaType: reqParams?.visaType || undefined,
      isSubmitted: saveSubmit
    }, true, false).then((res: any) => {
      message.success(res.message)
      navigate('/visa-request')
    })
      .catch((e: any) => {
        if (e.status === 402) {
          message.info(e.message)
          navigate('/visa-request')
        } else {
          message.error(e.message)
        }
      })
      .finally(() => onLoading(false))
  };

  const handleFinishFailed = (errorInfo: any) => {
    if (errorInfo && errorInfo.errorFields.length > 0) {
      let activeKey = errorInfo.errorFields[0].name[0]
      setActiveTab(activeKey.toString())
    }
  };

  useEffect(() => {
    if(reqParams && reqParams?.noOfChildrens){
      const tabs: any = [];
      for (let i = 0; i < reqParams.noOfChildrens + 1; i++) {
        tabs.push(
          <TabPane tab={i === 0 ? 'Main Person' : `Child ${i}`} key={i} tabKey={`${i + 1}`}>
            <VisaForm
              form={form}
              removeExtraDoc={(val: any) => console.log("called", val)}
              index={i}
              reqParams={reqParams}
              updateAppData={null}
            />
          </TabPane>
        );
      }
      setFormList(tabs)
    }else{
      navigate("/home")
    }
  }, [reqParams])

  const tabChangeHandle = (val: any) => {
    setActiveTab(val.toString())
  }

  return (
    <div className='container'>
      <Card style={{ marginBottom: "30px" }}>
        <div className='form-detail'>
          <Form
            form={form}
            name="dynamic_form"
            autoComplete="off"
            layout="vertical"
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            scrollToFirstError
          >
            <Flex justify='end'>
              <Space>
                <Button loading={isLoading} type="primary" htmlType="submit" onClick={() => saveSubmit = true}>
                  Save and Submit
                </Button>
                <Button loading={isLoading} type="default" htmlType="submit">
                  Save as Draft
                </Button>
              </Space>
            </Flex>
            <Tabs onChange={tabChangeHandle} activeKey={activeTab} type="card">
              {formList}
            </Tabs>
          </Form>
        </div>
      </Card>
    </div>
  )
  

}

const mapStateToProps = ({ visaReq, loading }: RootState) => {
  const { reqParams } = visaReq;
  const { isLoading } = loading;

  return { reqParams, isLoading }
}

export default connect(mapStateToProps, { onLoading })(ApplicationForm)
