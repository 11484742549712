import { DeleteOutlined, FilterOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, DatePicker, Card, Dropdown, Form, Row, Select, Space, Alert, Popconfirm } from "antd";
import Table from "antd/es/table";
import { useState } from "react";
import { useEffect } from 'react';
import AxiosService from "../utils/APIService";
import {setVisaParams} from "../action/visaReqAction"
import { connect } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "../reducer";
import moment from "moment";
import { getCountryNameByCountryCode } from "../country";
import downloadDocument from "../utils/docDownloader";
import { UserInterface } from "../interface";
import Column from "antd/es/table/Column";
import { APPLICATION_STATUS, ROLE } from "../consts";
import Export from "../component/Export";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import VisaRequestModal from "../component/VisaRequestModal";
import { InputBox } from "../component/FormElements";
const { RangePicker } = DatePicker;

const ManageRequest = ({setVisaParams, isLoading, user, permission}:{
    setVisaParams:any,
    isLoading:boolean,
    user: UserInterface,
    permission: any
}) => {
   
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [reqList, setReqList] = useState([]);
    const [typeList, setTypeList] = useState<any>([])
    const navigate = useNavigate()
    const [form2] = Form.useForm()
    const [size , setSize] = useState<number>()
    const [tableParams, setTableParams] = useState<any>({
        pagination: {
          current: 1,
          pageSize: 10,
        },
      });
    const [filter , setFilter] = useState<any>()
    
    const [searchParams] = useSearchParams()
    
    const checkPermissionForTableRow = (key:string) => {
        const per = permission?.VISA_LIST?.find((data:any) => data.key === key)
        return (user?.role === ROLE.subAgent && per?.allow) ||  user?.role !== ROLE.subAgent
    }
    
    
    const axiosService = new AxiosService()
    const onMenuClick: any = (e:any, rec:any) => {
        let fileName="insurance"
        switch (e.key) {
            case '1':
                fileName="visa"
                break;
            case '2':
                fileName="insurance"
                break;
        }
        axiosService.get(`/app/visa-doc/${rec.key}/${fileName}`)
            .then((res:any) => {
                downloadDocument(res, rec?.restData?.response_attatchment[fileName])
            })
            .catch(() => {
                console.log("error");
            })
    };

    
    const country = searchParams.get("contry")
    useEffect(() => {
        document.body.classList.add('body-bg');
        if(user){
            console.log(filter);
            fetchListData({
                startDate:filter?.startDate, 
                endDate:filter?.endDate, 
                search:filter?.search, 
                page:tableParams.pagination.current , 
                pageSize:tableParams.pagination.pageSize,
                country: country,
                status: filter?.status,
                visaType: filter?.visaType,
            });
            fetchVisaType()
        }
    }, [user, tableParams, filter]);
    
    


    const updateApplicant = (record:any) => {
        navigate(`/form-detail?edit=${record.key}`)
    }
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchListData = ({country, startDate, endDate, search, status, visaType , page , pageSize}: 
        {country?:string | null, startDate?:any, endDate?:any, search?: string, status?:string[], visaType?:string , page:number , pageSize:number}) => {
        axiosService.get('/app/get-list', {
            country: country,
            startDate: startDate,
            endDate: endDate,
            search,
            status,
            visaType,
            page:page,
            pageSize:pageSize
        })
        .then((res:any) => {
            let newList = res.data.map((data:any) => ({
                key: data._id,
                reqDateId: {
                    id: data.app_id,
                    reqdate: data.created_at
                },
                passangerDetail: {
                    name: data.passanger_detail.name,
                    country: data.passport_detail.passport_country,
                    passportNumber: data.passport_detail.passport_number,
                },
                type: data.pricing_detail.title,
                status: data.request_status,
                pricing: {
                    amount: data?.rate,
                    currency: "INR"
                },
                lastDay: "-",
                restData: data
            }))
            setReqList(newList)
            setSize(res.size)
        })
        .catch((e:any) => {
            console.log(e)
        })
    }
    const fetchVisaType = () => {
        axiosService.get('/price/get')
        .then((res:any) => {
            let visaType = res.data.map((data:any) => ({
                value: data._id,
                label: data.title,
                data: data
            }))
            setTypeList(visaType)
        })
        .catch((e:any) => {
            console.log(e)
        })
    }

    const applicationSubmition = (id:string) => {
        axiosService.post(`/app/submit-by-agent/${id}`, {})
        .then(() => {
            fetchListData({page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize})
        })
        .catch(e => {
            console.log(e);
        })
    }
    const filterHandler = (val:any) => {
        const startDate = val.reqDateRange?.length > 0 ? moment(val.reqDateRange[0].toString()).format("DD-MM-YYYY") : null
        const endDate = val.reqDateRange?.length > 0 ? moment(val.reqDateRange[1].toString()).format("DD-MM-YYYY") : null
        val.startDate = startDate;
        val.endDate = endDate
        setFilter(val)
    }
    const filterReset = () => {
        form2.resetFields()
        fetchListData({page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize})
    }

    const deleteHandle = (id:string) => {
        axiosService.delete(`/app/delete/${id}`)
            .then(() => {
                fetchListData({page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize})
            })
            .catch((e:any) =>{
                console.log(e);
            })
    }
    const exportToCSV = () => {       
        const csvRows = [];
        const header = [ "reqdate" , "Passenger" , "Visa Type" , "Request Status" , "Last Day" , "Rate"  ]
        csvRows.push(header.join(','));
        reqList.forEach((item:any) => {
            const row = [
                `${moment(item.reqdate).format("DD-MM-YYYY HH:mm")} - ID#: ${item.reqDateId.id}`,
                `${item.passangerDetail.name?.first_name} ${item.passangerDetail.name?.middle_name} ${item.passangerDetail.name?.last_name}`,
                item.type,
                item.status,
                item.lastDay,
                `${item.pricing.amount}\n${item.pricing.currency}`
                // Add additional columns if needed
            ];
            csvRows.push(row.join(','));
        })
        const csvData = csvRows.join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `visarequest.csv`);
        link.click();
    };
    
    const exportToPDF = () => {
   
        const header = [ "reqdate" , "Passenger" , "Visa Type" , "Request Status" , "Last Day" , "Rate"  ]
        let dataArray = reqList.map((item:any) => [
            `${moment(item.reqdate).format("DD-MM-YYYY HH:mm")} - ID#: ${item.reqDateId.id}`,
            `${item.passangerDetail.name?.first_name} ${item.passangerDetail.name?.middle_name} ${item.passangerDetail.name?.last_name}`,
            item.type,
            item.status,
            item.lastDay,
            `${item.restData.rate}`
        ]);
        const doc = new jsPDF();
        doc.setFontSize(10);
        autoTable(doc, {
            head: [header],
            body: dataArray,
          });
        doc.save(`visarequest.pdf`);
    };
    return (
        <div className="container">
            <VisaRequestModal 
                title="New Visa Request" 
                buttonText="Submit"
                width={500}
                handleCancel={handleCancel}
                setVisaParams={setVisaParams}
                isModalOpen={isModalOpen}
                loading={isLoading}
                user={user} 
            />
            <div className="flex justify-between items-center mb-4 max-md-block">
                <div className="flex max-md-mb-3 max-sm-block">
                    <p className="page-title relative pr-4 mr-4 font-medium mb-2">Manage Visa Request</p>
                    <Breadcrumb
                        items={[
                            {
                                title: 'Home',
                                path: "/home"
                            },
                            {
                                title: 'Visa',
                            },
                            {
                                title: 'Country Wise Data',
                                path: "/home"
                            },
                            {
                                title: 'Manage Visa Request',
                            },
                        ]}
                        itemRender={(data:any) => {
                            if(data.path){
                                return <Link to={data.path}>{data.title}</Link>
                            }
                            return data.title
                        }}
                    />
                </div>
                <div>
                    <Space size={"middle"} className="max-sm-block">
                        <Button icon={<FilterOutlined />} className="max-sm-mb-2 max-sm-w-full" onClick={() => setIsDrawerOpen(!isDrawerOpen)}>Filter</Button>
                        <Export 
                            exportToCsv={exportToCSV}
                            exportToPdf={exportToPDF}
                        />
                        {(user?.role === ROLE.subAgent && permission?.NEW_VISA_REQUEST.allow) ||  user?.role !== ROLE.subAgent ? <Button disabled={!user?.is_profile_updated} onClick={showModal} type="primary" className="max-sm-w-full" icon={<PlusOutlined />}>New Visa Request</Button> : null}
                    </Space>
                </div>
            </div>
            {isDrawerOpen ? (
                <Card
                    style={{ marginBottom: "1em" }}
                >
                    <Form onFinish={filterHandler} layout="vertical" form={form2}>
                        <Row align={'middle'} gutter={16}>
                            <Col lg={5}>
                                <Form.Item label="Request Date" name="reqDateRange">
                                    <RangePicker size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={4}>
                                <Form.Item label="Status" name="status">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        placeholder="Select Status"
                                        size="large"
                                        options={
                                            Object.keys(APPLICATION_STATUS).map((key) => ({
                                                label: APPLICATION_STATUS[key], 
                                                value: key  
                                            }))}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={4}>
                                <Form.Item label="Visa Type" name="visaType">
                                    <Select
                                        placeholder="Select Visa Type"
                                        allowClear
                                        size="large"
                                        options={typeList}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={4}>
                                <InputBox  label="Search" name="search" placeholder="search..."/>
                            </Col>
                            <Col>
                                <Space>
                                    <Button style={{ marginTop: ".5em" }} className="max-sm-w-full" type="primary" htmlType="submit">
                                        Filter
                                    </Button>
                                    <Button style={{ marginTop: ".5em" }} className="max-sm-w-full" type="default" onClick={filterReset}>
                                        Reset Filter
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                    </Form>
                </Card>
                ) : (null)
            }
            {checkPermissionForTableRow("REQUEST_ID") || 
            checkPermissionForTableRow("PASSENGER") || 
            checkPermissionForTableRow("VISA_TYPE") ||
            checkPermissionForTableRow("REQUEST_STATUS") ||
            checkPermissionForTableRow("LAST_DAY") ||
            checkPermissionForTableRow("RATE") ? (<Table
                className='border-around'
                pagination={{showSizeChanger:true,
                    onChange(page, pageSize) {
                        setTableParams({
                            pagination: {
                              current: page,
                              pageSize: pageSize,
                            },
                          })
                    },
                    total:size
                }}
                dataSource={reqList}
                scroll={{ x: 1219 }}
                loading={isLoading}
            >
                {checkPermissionForTableRow("REQUEST_ID") && <Column
                    render={(e:any) => (<>
                        <span 
                            className="text-sm block mb-1"
                            style={{
                                color: "#5F617A",
        
                            }}
                        >{moment(e.reqdate).format("DD-MM-YYYY")}</span>
                        <span 
                            className="text-sm block"
                            style={{
                                color: "#1A73E8"
                            }}
                        >ID#: {e.id}</span>
                    </>)}
                    title="Request Date/ID"
                    dataIndex="reqDateId"
                    key="reqDateId"
                />}
                {checkPermissionForTableRow("PASSENGER") && <Column
                    render={(e:any) => (<div>
                        <span 
                            className="block font-semibold text-sm"
                            style={{
                                color: "#000"
                            }}
                        >{e.name.first_name} {e?.name?.middle_name} {e?.name?.last_name}</span>
                        <span 
                            className="block text-sm"
                            style={{
                                color: "#5F617A"
                            }}
                        >{getCountryNameByCountryCode(e.country)} - {e.passportNumber}</span>
                    </div>)}
                    title="Passenger"
                    dataIndex="passangerDetail"
                    key="passangerDetail"
                />}
                {checkPermissionForTableRow("VISA_TYPE") && <Column
                    render={(e:any) => (<span 
                        className="block text-sm"
                        style={{
                            color: "#5F617A",
                            maxWidth: "180px"
                        }}
                    >{e}</span>)}
                    title="Visa Type"
                    dataIndex="type"
                    key="type"
                />}
                {checkPermissionForTableRow("REQUEST_STATUS") && <Column
                    render={(e:any) => (
                        <p className="block flex items-center" style={{color: "#5F617A"}}>
                            <span
                                className={`text-lg leading-3 mr-1 ${e === "COMPLETE" ? "complete" : e === "PENDING" ? "pending" : e === "REJECTED" ? "rejected" : null} `}>•</span> 
                            {e}
                        </p>)}
                    title="Request Status"
                    dataIndex="status"
                    key="status"
                />}
                {checkPermissionForTableRow("LAST_DAY") && <Column
                    title="Last Day"
                    dataIndex="lastDay"
                    key="lastDay"
                />}
                {checkPermissionForTableRow("RATE") && <Column
                    render={(e:any) => (
                        <>
                            <span className="text-sm" style={{color: "#1A73E8"}}>{e.amount}</span><br />
                            <span className="text-sm" style={{color: "#5F617A"}}>{e.currency}</span>
                        </>)}
                    title="Rate"
                    dataIndex="pricing"
                    key="pricing"
                />}
                <Column
                    render={(_, rec:any) => <>
                        {rec.restData.submit_by_agent ? (
                            <Dropdown menu={{ items: [
                                {
                                  key: '1',
                                  label: 'Visa Download',
                                  disabled:  rec?.restData?.response_attatchment?.visa ? false : true
                                },
                                {
                                  key: '2',
                                  label: 'Insurance Download ',
                                  disabled: rec?.restData?.response_attatchment?.insurance ? false : true
                                }
                              ], onClick: (e:any) => onMenuClick(e, rec) }}>                      
                                <MoreOutlined />
                            </Dropdown>
                        ) : (
                            <Space>
                                {checkPermissionForTableRow("EDIT") && <Button shape="round" size={"small"} className="text-sm" style={{color: "#5F617A"}} onClick={() => updateApplicant(rec)}>Edit</Button>}
                                {checkPermissionForTableRow("SUBMIT") && <Button shape="round" size={"small"} className="text-sm" style={{color: "#5F617A"}} onClick={() => applicationSubmition(rec.key)}>Submit</Button>}
                                <Popconfirm
                                    title="Are you sure you want to delete this row?"
                                    onConfirm={() => deleteHandle(rec.key)}
                                    okText="Yes"
                                    >
                                    <Button shape="round" size={"small"} className="text-sm" danger><DeleteOutlined /></Button>
                                </Popconfirm>
                            </Space>
                        )}
                    </>}
                    title="Action"
                    dataIndex=""
                    key="x"
                />
            </Table>) : 
                (
                    <Alert message="You Don't Have Enough Permissions" type="error" />
                )
            }
            
        </div>
    )
}
const mapStateToProps = ({loading, login}:RootState) => {
    const {isLoading} = loading
    const {user, permission} = login
    return {isLoading, user, permission}
}
const mapDispatchToProps = {
    setVisaParams,
}
export default connect (mapStateToProps, mapDispatchToProps) (ManageRequest);