import { useEffect, useState } from 'react';
import type { MenuProps } from 'antd';
import { Col, Dropdown, Flex, InputNumber, Row, Select, message } from 'antd';
import arrow from "../assets/svg/dropdwon-arrow.svg"
import enIcon from "../assets/svg/en_flag.svg"
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../assets/svg/logo.svg"
import { Button, Form, Input  } from 'antd';
import AxiosService from '../utils/APIService';
import { connect } from 'react-redux';
import { RootState } from '../reducer';
import CountryFlag from 'react-country-flag';
import { ContactInput, InputBox } from '../component/FormElements';
import { isValidPhoneNumber } from 'react-phone-number-input';

const CustomerRegistration = ({
  isLoading
}: any) => {
    const navigate =useNavigate()
    const axiosService:any = new AxiosService();
    const [form] = Form.useForm()
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Link to="/home">
                    Home
                </Link>
            ),
        }
    ]
    const onFinish = async (values: any) => {
      axiosService.post('/auth/registration', {
            email: values.email,
            password: values.password,
            contactNo: values.phoneNumber,
        })
        .then(() => {
            navigate("/login")
        })
        .catch((e:any) => console.log(e.message))
    };
    
    return (
      <div style={{ height: "100vh" }}>

        <div
          className="login-header flex items-center justify-end m-auto"
          style={{ paddingTop: "26px", paddingBottom: "26px", width: "95%" }}
        >
          <Dropdown menu={{ items }} className="lang-dropdown">
            <a onClick={(e) => e.preventDefault()}>
              <div className="dropdown-align inline-flex items-center">
                <img src={enIcon} alt="flag" style={{ marginRight: "10px" }} />
                <span style={{ marginRight: "10px" }}>English</span>
                <img src={arrow} alt="down-arrow" />
              </div>
            </a>
          </Dropdown>
          <p className="header-text relative" style={{ paddingLeft: "15px" }}>
            Already have an account? <Link to={"/login"}>Sign In</Link>
          </p>
        </div>
        <div
          className="flex items-center justify-center"
          style={{ height: "calc(100vh - 81px)" }}
        >
          <div
            className="flex flex-col justify-between login-content w-full"
            style={{
              maxWidth: "400px",
              margin: "auto",
            }}
          >
            <div>
              <img
                src={Logo}
                alt="logo"
                className="block m-auto"
                style={{ marginBottom: "43px" }}
              />
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                style={{ marginBottom: "20px" }}
                form={form}
              >
                <Row gutter={8}>
                    <Col span={24}>
                        <InputBox
                          name="email"
                          style={{ width: "100%" }}
                          rules={[
                              {
                                  required: true,
                                  message: "Please enter email address!",
                                  type: "email"
                              },
                          ]}
                          placeholder="Enter Email"
                        />
                    </Col>
                    <Col span={24}>
                      <ContactInput name='phoneNumber' form={form}/>
                    </Col>
                    <Col span={12}>
                        <InputBox
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Password!",
                                },
                            ]}
                            placeholder="Enter password"
                            type='password'
                        />
                    </Col>
                    <Col span={12}>
                        <InputBox
                            name="confirmPassword"
                            style={{ width: "100%" }}
                            dependencies={["password"]}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter confirm password!",
                                },
                                ({ getFieldValue }:any) => ({
                                    validator(_:any, value:any) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "Password does not match!"
                                            )
                                        );
                                    },
                                }),
                            ]}
                            type='password'
                            placeholder='Confirm Password'
                        />
                    </Col>
                </Row>
                <Button
                  className="signin-btn"
                  htmlType="submit"
                  block
                  loading={isLoading}
                >
                  Sign Up
                </Button>
              </Form>
            </div>

            <div
              className="text-sm flex justify-between ml-auto mr-auto footer-text"
              style={{
                color: "#5F617A",
                width: "380px",
              }}
            >
              <span>Got a question?</span>
              <a href="#" className="underline">
                contact@company.com
              </a>
              <span className="resp-hide">|</span>
              <a href="#">+91 9426773719</a>
            </div>
          </div>
        </div>
      </div>
    );
}
const mapStateToProps = ({loading}: RootState) => {
    const {isLoading} = loading
    return {isLoading}
}
export default connect(mapStateToProps, {}) (CustomerRegistration);