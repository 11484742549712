import {
  EyeInvisibleOutlined,
  EyeOutlined,
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
  SearchOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Card,
  Row,
  Select,
  Space,
  Table,
  TableColumnsType,
  Flex,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import user1 from "../assets/img/user1.png";
import { useEffect } from "react";
import AxiosService from "../utils/APIService";
import { connect } from "react-redux";
import { RootState } from "../reducer";
import { AddBalanceInterface, agentTypeInterface, recordInterface, updateUserInterface } from '../interface/Agent'
import FormModal from "../component/FormModal";
import { useNavigate } from "react-router-dom";
import { UserInterface } from "../interface";
import { ROLE } from "../consts";
import Alert from "antd/es/alert/Alert";
import { decryptPassword, decryptSalt } from "../utils/passwordDecs";
import { InputBox } from "../component/FormElements";

interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  upgradeNum: string;
}
const ToggleView = ({ text }: { text: string }) => {
  const [isVisible, setisVisible] = useState(false);

  return (
    <>
      {isVisible ? (
        <div className="flex items-center">
          <span className="text-sm block mr-2">{text}</span>
          <Button
            type="text"
            onClick={() => setisVisible(false)}
            icon={<EyeInvisibleOutlined />}
          ></Button>
        </div>
      ) : (
        <div className="flex items-center">
          <span className="text-sm block mr-2">*********</span>
          <Button
            type="text"
            onClick={() => setisVisible(true)}
            icon={<EyeOutlined />}
          ></Button>
        </div>
      )}
    </>
  );
};

const EditView = ({
  record,
  isLoading,
}: {
  record: recordInterface;
  isLoading: boolean;
}) => {
  const axiosService = new AxiosService();
  const [isEditing, setIsEditing] = useState(false);
  const handleCancel = () => {
    setIsEditing(false);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("name", record?.resData?.name?.first_name);
    form.setFieldValue("email", record?.resData?.email);
    form.setFieldValue("password", record?.password);
  }, []);

  const updateUserHandler = (val: updateUserInterface) => {
    axiosService
      .put(`/auth/edit/agents/${record?.resData?._id}`, {
        name: {
          first_name: val.name,
          last_name: record?.resData?.name?.last_name,
        },
        email: val.email,
        password: val.password,
      })
      .then(() => {
        setIsEditing(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="container">
      <FormModal
        buttonText="Save"
        form={form}
        handleCancel={handleCancel}
        handleForm={updateUserHandler}
        isModalOpen={isEditing}
        loading={isLoading}
        title="Edit User Name & Password"
        width={500}
      >
        <InputBox
          label="Name"
          name="name"
          className="mb-4"
          rules={[{ required: true, message: "Please input your Name!" }]}
        />
        <InputBox
          label="Email"
          name="email"
          className="mb-4"
          rules={[
            {
              required: true,
              message: "Please input your email!",
              type: "email",
            },
          ]}
        />
        <InputBox
          label="Password"
          name="password"
          className="mb-4"
          rules={[{ required: true, message: "Please input your password!" }]}
          type="password"
        />
      </FormModal>
      <div className="flex items-center">
        <Button
          type="text"
          onClick={() => setIsEditing(true)}
          icon={<EditOutlined />}
        ></Button>
      </div>
    </div>
  );
}; 

//BalanceView Component
const BalanceView = ({
  text,
  id,
  isLoading,
}: {
  text: string;
  id: string;
  isLoading: boolean;
}) => {
  const axiosService = new AxiosService();
  const [modalShow, setModalShow] = useState(false);
  const [form] = Form.useForm();

  const handleCancel = () => {
    setModalShow(false);
    form.resetFields() 
  };

  const addAmountToWallet = (val: AddBalanceInterface) => {
    axiosService
      .post("/auth/add/balance", {
        userId: id,
        amount: val.balance,
        note: val.notes,
      })
      .then(() => {
        form.resetFields();
        setModalShow(false);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  return (
    <div className="container">
      <FormModal
        buttonText="Add"
        form={form}
        handleCancel={handleCancel}
        handleForm={addAmountToWallet}
        isModalOpen={modalShow}
        loading={isLoading}
        title="Add Balance"
        width={397}
      >
        <p className="font-semibold mb-5" style={{ color: "#071437", fontSize: 16 }}>
          Total Balance : ₹ {`${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </p>
        <Form.Item
            name="balance"
            label="Add Balance"
            rules={[{ required: true, message: "Please input Salary amount!" }]}
          >
            <InputNumber
              formatter={(value) =>
                `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              min={0}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <InputBox 
            name="notes" 
            label="Notes (Description)"
            placeholder="Enter Notes"
            type="textarea"
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
      </FormModal>
      <div className="flex items-center">
        <Button
          type="text"
          size="small"
          onClick={() => setModalShow(true)}
          icon={<PlusOutlined />}
        ></Button>&nbsp;
        <span className="text-sm block mr-2">{text}</span>
      </div>
    </div>
  );
};

//Agent Component
const Agent = ({ isLoading , user , adminPermission }: { isLoading: boolean , user:UserInterface , adminPermission:any }) => {
  const axiosService = new AxiosService();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [form] = Form.useForm()
  const [searchString, setSearchString] = useState("");
  const navigate = useNavigate()
  const [size , setSize] = useState<number>()
  const [tableParams, setTableParams] = useState<any>({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
  const [filter , setFilter] = useState<any>()
  const checkPermissionForTableRow = (key:string) => {
    const per = adminPermission?.AGENT?.find((data:any) => data.key === key)
    return (user?.role === ROLE.subAdmin && per?.allow) || user?.role === ROLE.admin
}

  const handleRowExpand = (expanded: boolean, record: recordInterface) => {
    const keys = expanded ? [record.key] : [];
    setExpandedRowKeys(keys);
  };
  useEffect(() => {
    document.body.classList.add("body-bg");
    fetchUser({status:filter?.status , agtType:filter?.type , page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize});
  }, [tableParams , filter]);
  const fetchUser = ({
    status,
    agtType,
    page,
    pageSize
  }: {
    status?: string,
    agtType?: string,
    page:number,
    pageSize:number
  }) => {
    axiosService
      .get("/auth/get/agents",{
        status,
        agtType,
        page:page,
        pageSize:pageSize
      })
      .then((res: any) => {
        const agtList = res.data.map((rec: any) =>{
          const salt:string = decryptSalt(rec.salt);
          const password:string = decryptPassword(rec.hashed_password, salt);    
          return {
            key: rec._id,
            profile: {
              name: `${rec?.name?.first_name} ${rec?.name?.last_name}`,
              userImage: user1,
            },
            email: rec.email,
            password: password,
            status: rec.status,
            type: rec.agent_type,
            balance: rec.balance,
            subAgents: rec.agentsId,
            resData: rec
          }})
        setAgentList(agtList);
        setSize(res.size)
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  const columns: ColumnsType<any> = [
    checkPermissionForTableRow("NAME") && {
      title: "Name",
      dataIndex: "profile",
      key: "profile",
      render: (e:any , rec:any) => (
        <>
          <div style={{cursor:"pointer"}} onClick={()=>{
               
                navigate(`/update-user?user=${rec.key}`)
              }} className="flex items-center">
            <img  className="avatar" src={e.userImage} />
            <span className="text-sm block" style={{ color: "#5F617A" }}>
              {e.name} {rec.subAgents.length > 0 && `(${rec.subAgents.length})`}
            </span>
          </div>
        </>
      ),
    },
    checkPermissionForTableRow("EMAIL") && {
      title: "Email",
      dataIndex: "email",
      key: "username",
    },
    checkPermissionForTableRow("PASSWORD") && {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (e:any) => <ToggleView text={e} />
    },
    checkPermissionForTableRow("STATUS") && {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (e:any, rec:any) => (
        <Select
          className="status-dropdown"
          style={{ width: 120 }}
          value={e}
          onChange={(val) => statusChangeHandle(val, rec)}
        >
          <Select.Option value="ACTIVE"><Flex align="center"><span className="dot active"/> <span>Active</span></Flex></Select.Option>
          <Select.Option value="DEACTIVE"><Flex align="center"><span className="dot deactive"/> <span>Deactivate</span></Flex></Select.Option>
        </Select>
      ),
    },
    checkPermissionForTableRow("TYPE") && {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (e:any, rec:any) => (
        <Select
          className="status-dropdown"
          style={{ width: 120 }}
          value={e}
          options={[
            { value: "B2B", label: "B2B" },
            { value: "B2B2B", label: "B2B2B" },
            { value: "B2C", label: "B2C" },
          ]}
          onChange={(e) => {
            changeTypeHandler(rec.key, e);
          }}
        />
      ),
    },
    checkPermissionForTableRow("AGENT_BALANCE") && {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (e:any, rec:any) => (
        <BalanceView
          isLoading={isLoading}
          text={e}
          id={rec.key}
        />
      ),
    },
    checkPermissionForTableRow("ACTION") && {
      title: "Action",
      key: "Edit",
      render: (rec:any) => (
        <EditView
          isLoading={isLoading}
          record={rec}
        />
      ),
    },
  ].filter(Boolean);
  

  const changeTypeHandler = (id: string, val: any) => {
    axiosService
      .post(`/agent/change-type/${id}`, {
        type: val,
      })
      .then(() => {
        fetchUser({page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize});
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const expandedRowRender = (rec: any) => {
    const columns: TableColumnsType<ExpandedDataType> = [
      checkPermissionForTableRow("NAME") && {
        title: "Name",
        dataIndex: "profile",
        key: "profile",
        render: (e:any) => (
          <>
            <div className="flex items-center">
              <img className="avatar" src={e.userImage} />
              <span className="text-sm block" style={{ color: "#5F617A" }}>
                {e.name}
              </span>
            </div>
          </>
        ),
      },
      checkPermissionForTableRow("EMAIL") && {
        title: "Email",
        key: "email",
        dataIndex: "email",
      },
      checkPermissionForTableRow("PASSWORD") && {
        title: "Password",
        dataIndex: "password",
        key: "password",
        render: (e:any) => <ToggleView text={e} />,
      },
      checkPermissionForTableRow("STATUS") && {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (e:any, rec:any) => (
          <Select
            className="status-dropdown"
            style={{ width: 120 }}
            value={e}
            onChange={(val) => statusChangeHandle(val, rec)}            
          >
            <Select.Option value="ACTIVE"><Flex align="center"><span className="dot active"/> <span>Active</span></Flex></Select.Option>
            <Select.Option value="DEACTIVE"><Flex align="center"><span className="dot deactive"/> <span>Deactivate</span></Flex></Select.Option>
          </Select>
        ),
      },
      checkPermissionForTableRow("PERMISSION") && {
        title: "Permission",
        dataIndex: "permission",
        key: "permission",
        render: (_:any) => (
          <Button
            icon={<ExportOutlined />}
            className="max-sm-mb-2 max-sm-w-full"
            onClick={() => navigate(`/agent-permission?user=${_}`)}
          >
            Show
          </Button>
        ),
      },
      checkPermissionForTableRow("ACTION") && {
        title: "Action",
        key: "Edit",
        render: (rec:any) => (
          <EditView
            isLoading={isLoading}
            record={rec}
          />
        ),
      },
    ].filter(Boolean);

    const data: any[] = [];
    for (let i = 0; i < rec.subAgents.length; ++i) {
      const sbAgt = rec.subAgents[i];
      const salt = decryptSalt(sbAgt.salt);
      const password:string = decryptPassword(sbAgt.hashed_password, salt); 
      data.push({
        srNo: (i + 1).toString(),
        key: sbAgt._id,
        profile: {
          name: `${sbAgt?.name?.first_name} ${sbAgt?.name?.last_name}`,
          userImage: user1,
        },
        email: sbAgt.email,
        password: password,
        status: sbAgt.status,
        permission: sbAgt._id,
        resData: sbAgt,
      });
    }
    return (
      
      <Table
        className="mt-2 mb-2 border-around"
        columns={columns.filter(Boolean)}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const statusChangeHandle = (data: any, rec: any) => {
    axiosService
      .post(`/user/change-status/${rec.key}`, {
        status: data,
      })
      .then(() => {
        fetchUser({page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize});
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const filterHandler = (val:agentTypeInterface) => {
    setFilter(val)
  }

  const resetFilter = () => {
      form.resetFields()
      fetchUser({page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize})
  }
  const onSearchHandler = (e:any) => {
    let searchVal = e.target.value
    setSearchString(searchVal)
  }

  const filterData = (key:string) => {
    return agentList.filter((agt:any) => agt.email.toLowerCase().includes(key.toLowerCase()) ||
    agt.profile.name.toLowerCase().includes(key.toLowerCase()) || 
    agt.resData.balance == key)
  }

  return (
    <div className="container">
      <div className="flex justify-between items-center mb-4 max-md-block">
        <div className="flex max-md-mb-3 max-sm-block">
          <p className="page-title relative pr-4 mr-4 font-medium mb-2">
            Agents
          </p>
          <Breadcrumb
            items={[
              {
                title: "Home",
              },
              {
                title: "Agents",
              },
            ]}
          />
        </div>
        <div>
          <Space size={"middle"} className="max-sm-block">
            <Input placeholder="Search..." prefix={<SearchOutlined />} onChange={onSearchHandler} />
            <Button
              icon={<FilterOutlined />}
              className="max-sm-mb-2 max-sm-w-full"
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            >
              Filter
            </Button>
          </Space>
        </div>
      </div>
      {
        isDrawerOpen ? (
          <Card
            style={{marginBottom:"1em"}}
          >
            <Form onFinish={filterHandler} layout="vertical" form={form}>
              <Row align={'middle'} gutter={16}>
                <Col lg={4}>
                  <Form.Item style={{width:"100%"}} label="Status" name="status">
                    <Select
                      allowClear
                      placeholder="select Status"
                      options={[{label: "Active", value: "ACTIVE"}, {label: "Deactive", value: "DEACTIVE"}]}
                    />
                  </Form.Item>
                </Col>
                <Col lg={4}>
                  <Form.Item style={{width:"100%"}} label="Type" name="type">
                    <Select
                      allowClear
                      placeholder="select Type"
                      options={[{label: "B2B", value: "B2B"}, {label: "B2B2B", value: "B2B2B"}, {label: "B2C", value: "B2C"}]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                <Space>
                <Button style={{marginTop:".5em"}}  className="max-sm-w-full" type="primary" htmlType="submit">
                  Filter
                </Button>
                <Button style={{marginTop:".5em"}} className="max-sm-w-full" type="default" onClick={resetFilter}>
                  Reset Filter
                </Button>
              </Space>

                </Col>

              </Row>

            </Form>
          </Card>
        ):(
          null
        )
      }
          {
                checkPermissionForTableRow("NAME") ||
                checkPermissionForTableRow("PASSWORD") ||
                checkPermissionForTableRow("STATUS") ||
                checkPermissionForTableRow("TYPE") ||
                checkPermissionForTableRow("BALANCE") ||
                checkPermissionForTableRow("ACTION")  ? (
      <Table
        className='border-around'
        pagination={{showSizeChanger:true,
          onChange(page, pageSize) {
              setTableParams({
                  pagination: {
                    current: page,
                    pageSize: pageSize,
                  },
                })
          },
          total:size
        }}
        columns={columns.filter(Boolean)}
        expandable={{ 
          expandedRowRender, 
          onExpand: handleRowExpand,
          rowExpandable: (rec) => rec.subAgents.length > 0}}
        dataSource={searchString ? filterData(searchString) : agentList}
        scroll={{ x: 1219 }}
        loading={isLoading}
      />):(
        <Alert message="You Don't Have Enough Permissions" type="error" />
      )
        }
    </div>
  );
};
const mapStateToProps = ({ loading , login }: RootState) => {
  const {user , adminPermission} = login 
  const { isLoading } = loading;
  return { isLoading , user , adminPermission };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Agent);
