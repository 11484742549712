import React, { useEffect, useState } from 'react'
import FormModal from './FormModal'
import { Checkbox, Form, Input, InputNumber, Radio, Select } from 'antd'
import useTypeList from '../hooks/useTypeList';
import { VISA_TYPE } from '../consts';
import { getCountryNameByCountryCode } from '../country';
import countryList from "../country/data.json"
import { useNavigate } from 'react-router-dom';

function VisaRequestModal({user, setVisaParams, ...rest}:any) {
    const [selectedtype, setSelectedtype] = useState<any>(null);
    const [selectdTypeList, setSelectdTypeList] = useState<any>([])
    const [selectedChildType, setSelectedChildType] = useState<any>(null);
    const [addChildCheckbox, setAddChildCheckbox] = useState<boolean>(false)
    const {typeList} = useTypeList()
    const [totalAmount, setTotalAmount] = useState<number>(0)
    const [noOfChildrens, setNoOfChildrens] = useState<number>(1);
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const typeListBaseOnTraveling = (val:string) => {
        form.setFieldValue("visaType", "")
        setSelectedtype(null)
        const types = [...typeList]
        const filterData = types.filter((type:any) => type?.data?.applied_for === val)
        setSelectdTypeList(filterData)
    }
    function getUniqueObjects(array:any[]) {
        return array.filter((obj, index, self) =>
            index === self.findIndex((t) => (
                t.data.applied_for === obj.data.applied_for
            ))
        );
    }
    const priceChangeHandler = (val:any, type:string) =>{
        const types = [...typeList]
        const selectedVal = types.find((dt) => dt.value === val).data
        setTotalAmount(selectedVal[user.agent_type.toLowerCase()])
        if(type === "Main"){
            setSelectedtype(selectedVal)
        }else{
            setSelectedChildType(selectedVal)
        }
    }
    const checkBoxHandleChange = (e:any) => {
        setAddChildCheckbox(e.target.checked)
        if(!e.target.checked){
            form.setFieldValue("childVisaType", "")
            setNoOfChildrens(0)
            setSelectedChildType(null)
        }else{
            setNoOfChildrens(1)
            form.setFieldValue("noOfChildrens", 1)
        }
    }
    const calculateTotalAmount = () => {
        let total = 0;
        if (selectedtype) {
            total += selectedtype[user.agent_type.toLowerCase()];
        }
        if (addChildCheckbox && selectedChildType) {
            total += selectedChildType[user.agent_type.toLowerCase()] * noOfChildrens;
        }
        setTotalAmount(total);
    };
    useEffect(() => {
        calculateTotalAmount();
    }, [selectedtype, selectedChildType, noOfChildrens]);
    useEffect(() => {
        form.setFieldValue("nationality", "IN")
      }, [])
    const modalFormSubmit = (e:any) =>{
        setVisaParams(e)
        if(e.noOfChildrens){
            navigate("/create-application")
        }else{
            navigate("/form-detail")
        }
    }
    return (
        <FormModal
            {...rest}
            form={form}
            handleForm={modalFormSubmit}
        >
            {/* <Form.Item name={"type"} rules={[
                {
                  required: true,
                  message: "Please select one option!",
                },
              ]}>
                <Radio.Group>
                    <Radio value={VISA_TYPE.SINGLE}> Single Pax </Radio>
                    <Radio value={VISA_TYPE.GROUP}> Family (More than 1 Pax) </Radio>
                </Radio.Group>
            </Form.Item> */}
            <Form.Item name={"travelling"} label="Travelling" rules={[{ required: true, message: 'Please add Travelling!' }]}>
                <Select
                    showSearch
                    onSelect={typeListBaseOnTraveling}
                    filterOption={(input:any, option:any) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    options={
                        getUniqueObjects(typeList)
                        .map((ctr:any) => ({
                            value: ctr.data.applied_for, label: getCountryNameByCountryCode(ctr.data.applied_for)
                        }))}
                />
            </Form.Item>
            <Form.Item name={"nationality"} label="Nationality" rules={[{ required: true, message: 'Please add Nationality!' }]}>
                <Select
                    showSearch
                    filterOption={(input:any, option:any) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={
                        countryList.map((ctr:any) => ({value: ctr.code, label: ctr.name}))
                    }
                />
            </Form.Item>
            <Form.Item name={"visaType"} label="Visa Type" rules={[{ required: true, message: 'Please add Visa Type!' }]}>
                <Select
                    options={selectdTypeList}
                    onChange={(e:any) => priceChangeHandler(e, "Main")}
                />
            </Form.Item>
            <Form.Item name={"isChildrens"} >
                <Checkbox className='mb-4' onChange={checkBoxHandleChange} value={addChildCheckbox}>Add Childrens</Checkbox>
            </Form.Item>
            {addChildCheckbox && <>
                <Form.Item label={"No. of Childrens"} name={"noOfChildrens"}>
                    <InputNumber min={1} defaultValue={1} onChange={(val:any)  => setNoOfChildrens(val)} style={{width: "100%"}} />
                </Form.Item>
                <Form.Item name={"childVisaType"} label="Visa Type For Children" rules={[{ required: true, message: 'Please add Visa Type!' }]}>
                    <Select
                        options={selectdTypeList}
                        onChange={(e:any) => priceChangeHandler(e, "Child")}
                    />
                </Form.Item>
            </>}
            {selectedtype && (
                <div className="p-4"
                    style={{
                        background: "#1A73E80D",
                        borderRadius: "6px",
                        borderColor: "#DFE1E5"
                    }}    
                >
                <p className="font-bold mb-3">{totalAmount}</p>
                {selectedtype?.documents?.length > 0 && (
                    <div>
                        <p>Documents</p>
                        <ul className="text-xs flex flex-wrap form-list max-sm-block" style={{color: "#5F617A"}}>
                            {selectedtype?.documents.map((doc:string) => <li className="w-full pl-4 mb-1 relative" style={{maxWidth: "50%"}}>{doc}</li>)}
                        </ul>
                    </div>
                )}
                <p>Note: {selectedtype.note}</p>
            </div>
            )}
        </FormModal>
    )
}

export default VisaRequestModal
