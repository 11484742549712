import { Button, Col, DatePicker, Divider, Flex, Form, Input, InputNumber, Radio, Row, Select, Spin, Typography, Upload, UploadProps } from 'antd'
import React, { useEffect, useState } from 'react'
import FileUpload from '../../component/FileUpload'
import dayjs from 'dayjs'
import { PlusOutlined } from '@ant-design/icons'
import uploadSvg from '../../assets/svg/upload-svg.svg';
import countries from '../../country/data.json'
import profassion from "../../data/profession.json";
import CountryFlag from 'react-country-flag';
import AxiosService from '../../utils/APIService'
import { ContactInput, InputBox } from '../../component/FormElements'

function VisaForm({ 
    form,
    reqParams, 
    removeExtraDoc, 
    index 
}: any) {
    const axiosService = new AxiosService()
    const [frontFileList, setFrontFileList] = useState<any[]>([]);
    const [backFileList, setBackFileList] = useState<any[]>([]);
    const [photoList, setPhotoList] = useState<any[]>([]);
    const [idList, setIdList] = useState<any[]>([]);
    const [supportiveDocList, setSupportiveDoc] = useState<any[]>([]);
    const [maritalStatus, setMaritalStatus] = useState("")
    const [passLoading, setPassLoading] = useState({
        front: false,
        back: false
    })
    const supportiveDocOnChange: UploadProps['onChange'] = ({ fileList: newFileList } :any) => {
        setSupportiveDoc(newFileList);
    };
    useEffect(() => {
        form.setFieldValue([index,"birth_country"], "IN")
        form.setFieldValue([index,"passport_type"], "NORMAL PASSPORT")
        form.setFieldValue([index,"passport_country"], "IN")
        form.setFieldValue([index,"language"], "ENGLISH")
        form.setFieldValue([index,"nationality"], reqParams.nationality)
        form.setFieldValue([index,"marital_status"], "SINGLE")
        form.setFieldValue([index,"religion"], "HINDU")
    }, []);

    const maritalOpt = ["Single", "Married", "Divorced", "Widow", "Deceased", "Unspecific", "Child"]
    const castOpt = ["Muslim", "Christian", "Hindhu", "Budist", "Sikh", "Kadiani", "Bahaei", "Jewish"]
    const langOpt = ["Arabic", "English", "French", "Hindi", "Urdu", "German", "Italian", "Swahili", "Spanish", "Russian", "Indonesian", "Malaysian", "Malayan", "Balochi", "Turkish", "Japanese", "Chinese", "Thai", "Tagalog", "Farsi", "Sinhalese", "Bangla", "Telugu", "Unknown", "Others"]
    const allowStatus = ["MARRIED", "WIDOW"]

    const filePlaceholder = (<div className="flex flex-col items-center">
        <img className="mb-7" src={uploadSvg} style={{ width: "200px" }} />
        <div>
            <p style={{ color: "#1A73E8", fontSize: "14px" }}>Drag an Image here or click to upload</p>
            <span style={{ color: "#99A1BB", fontSize: "14px" }}>Upload an image in either a PNG or JPEG format. Maximum size: 2MB.</span>
        </div>
    </div>)
    const custReq = ({ onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok")
        }, 0)
    }
    const frontPassportDataFetch = (file:any) => {
        if(file){
            setPassLoading((prev) => ({...prev, front: true}))
            const formData = new FormData()
            formData.append("image", file)
            axiosService.post(`${process.env.REACT_APP_PASSPORT_OCR}/upload-paasport-front`, formData, false, false)
            .then((res:any) => {
                const data:any = res.Response
                if(data){
                    form.setFieldValue([index, "dob"], dayjs(data.birth_date, 'DD-MM-YYYY'))
                    form.setFieldValue([index, "passport_exp_date"], dayjs(data.expiry_date, 'DD-MM-YYYY'))
                    form.setFieldValue([index, "issueDate"], dayjs(data.issue_date, 'DD/MM/YYYY'))
                    form.setFieldValue([index, "first_name"], data.name)
                    form.setFieldValue([index, "passport_number"], data.passport_no)
                    form.setFieldValue([index, "birth_place"], data.place_of_birth)
                    form.setFieldValue([index, "passport_issue_city"], data.place_of_issue)
                    form.setFieldValue([index, "gender"], data.sex == "M" ? "MALE" : "FEMALE")
                    form.setFieldValue([index, "last_name"], data.surname)
                }
            })
            .catch(e => console.log(e))
            .finally(() => setPassLoading((prev) => ({...prev, front: false})))
        }
    }

    const backPassportDataFetch = (file:any) => {
        if(file){
            setPassLoading((prev) => ({...prev, back: true}))
            const formData = new FormData()
            formData.append("image", file)
            axiosService.post(`${process.env.REACT_APP_PASSPORT_OCR}/upload-paasport-back`, formData, false, false)
            .then((res:any) => {
                const data:any = res.Response
                if(data){
                    form.setFieldValue([index,"address"], data.address)
                    form.setFieldValue([index,"city"], data.place_of_issue)
                    form.setFieldValue([index,"mother_name"], data.name_of_mother)
                    form.setFieldValue([index,"father_name"], data.legal_guardian)
                    if(data.name_of_spouse){
                        form.setFieldValue([index,"spouse_name"], data.name_of_spouse)
                        form.setFieldValue([index,"marital_status"], "MARRIED")
                        setMaritalStatus("MARRIED")
                    }
                }
            })
            .catch(e => console.log(e))
            .finally(() => setPassLoading((prev) => ({...prev, back: false})))
        }
    }
    return (<>
        <Row gutter={16}>
            <Col span={24}>
                <Flex align="center" className="mb-3">
                    <span className="section-counter rounded-full border flex items-center justify-center font-bold mr-5">01</span>
                    <Typography.Text style={{ fontSize: "18px", fontWeight: 500 }}>Passport Front Details</Typography.Text>
                </Flex>
            </Col>
            <Col span={11}>
                <Form.Item 
                    style={{ position: "sticky", top: "0", zIndex: "11", background: "white" }} 
                    className="mb-0 passport-dropbox" 
                    label="Upload Passport Front Side" 
                    name={[index, "passportFront"]} 
                    rules={[{ required: true, message: 'Please Upload Passport Front Image!' }]}>
                    <FileUpload onRemove={() => {
                        form.resetFields([[index,"passportFront"]])
                    }} accept=".png, .jpeg, .jpg" required placeholder={filePlaceholder} setFile={(val: any) => {
                        frontPassportDataFetch(val[0]?.originFileObj)
                        setFrontFileList(val)
                    }} fileData={frontFileList} />
                </Form.Item>
            </Col>
            <Col span={13}>
                <Spin tip="Loading..." spinning={passLoading.front}>
                    <Row gutter={10}>
                        <Col span={12}>
                            <Form.Item
                                label="Passport Type"
                                name={[index, "passport_type"]}
                                rules={[{ required: true, message: 'Please Select Passport Type!' }]}
                            >
                                <Select
                                    placeholder="- Select Passport Type -"
                                    allowClear
                                    size="large"
                                >
                                    <Select.Option value="NORMAL PASSPORT">Normal Passport</Select.Option>
                                    <Select.Option value="DIPLOMATIC PASSPORT">Diplomatic Passport</Select.Option>
                                    <Select.Option value="TRAVEL DOCUMENT">Travel Document</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <InputBox 
                                label="Passport Number"
                                name={[index, "passport_number"]}
                                rules={[{ required: true, message: 'Please Insert Passport Number!' }]}
                                isUppercase={true}
                            />
                        </Col>
                        <Col span={24}>
                            <InputBox
                                label="Last Name"
                                name={[index,"last_name"]}
                                isUppercase={true} 
                            />
                        </Col>
                        <Col span={12}>
                            <InputBox
                                label="First Name"
                                name={[index,"first_name"]}
                                rules={[{ required: true, message: 'Please Enter First Name!' }]}
                                isUppercase={true} 
                            />
                        </Col>
                        <Col span={12}>
                            <InputBox
                                label="Middle Name"
                                name={[index,"middle_name"]}
                                isUppercase={true} 
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Birth Date"
                                name={[index,"dob"]}
                                rules={[{ required: true, message: 'Please Insert Date of Birth!' }]}
                            >
                                <DatePicker className="w-full" format={"DD/MM/YYYY"} placeholder="DD/MM/YYYY" size="large" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Gender"
                                name={[index,"gender"]}
                                rules={[{ required: true, message: 'Please Select Gender!' }]}
                            >
                                <Radio.Group>
                                    <Radio value={'MALE'}>Male</Radio>
                                    <Radio value={'FEMALE'}>Female</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <InputBox
                                label="Birth Place"
                                name={[index,"birth_place"]}
                                rules={[{ required: true, message: 'Please Insert Birth Place!' }]}
                                isUppercase={true}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Birth Country"
                                name={[index,"birth_country"]}
                                rules={[{ required: true, message: 'Please Select Birth Country!' }]}
                            >
                                <Select
                                    placeholder="- Select Birth Country -"
                                    allowClear
                                    size="large"
                                    showSearch
                                    filterOption={(input: any, option: any) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    options={countries.map((cntr: any) => ({
                                        value: cntr.code,
                                        label: cntr.name
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <InputBox
                                label="Passport Issue City"
                                name={[index,"passport_issue_city"]}
                                rules={[{ required: true, message: 'Please Insert Passport Issue City!' }]}
                                isUppercase={true}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Passport Country"
                                name={[index,"passport_country"]}
                                rules={[{ required: true, message: 'Please Insert Passport Country!' }]}
                            >
                                <Select
                                    placeholder="- Select Passport Country -"
                                    allowClear
                                    size="large"
                                    showSearch
                                    filterOption={(input: any, option: any) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    options={countries.map((cntr: any) => ({
                                        label: cntr.name,
                                        value: cntr.code
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Passport Issue Date"
                                name={[index,"issueDate"]}
                                rules={[
                                    { required: true, message: 'Please Insert Passport Issue Date!' },
                                    {
                                        validator(_, value) {
                                            if (value && dayjs(value.toString()).isAfter(dayjs())) {
                                                return Promise.reject(new Error('Invalid Issue Date'));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <DatePicker className="w-full" format={"DD/MM/YYYY"} placeholder="DD/MM/YYYY" size="large" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Passport Expiry Date"
                                name={[index,"passport_exp_date"]}
                                rules={[
                                    { required: true, message: 'Please Select Passport Expiry Date' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const issueDate = getFieldValue('issueDate');
                                            if (!issueDate || !value) {
                                                return Promise.resolve();
                                            }

                                            if (dayjs(value).isSame(issueDate, 'day')) {
                                                return Promise.reject(new Error('Issue Date should not be the same as Expiry Date'));
                                            }
                                            if (dayjs(value).isBefore(dayjs())) {
                                                return Promise.reject(new Error('Invalid Expiry Date'));
                                            }
                                            if (dayjs(value).isBefore(dayjs().add(6, 'months'))) {
                                                return Promise.reject(new Error('Expiry Date should be more than 6 months from now'));
                                            }

                                            return Promise.resolve();
                                        },
                                    })
                                ]}
                            >
                                <DatePicker className="w-full" format={"DD/MM/YYYY"} placeholder="DD/MM/YYYY" size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
            <Col span={24}>
                <Flex align="center" className="mb-3">
                    <span className="section-counter rounded-full border flex items-center justify-center font-bold mr-5">02</span>
                    <Typography.Text style={{ fontSize: "18px", fontWeight: 500 }}>Passport Back Details</Typography.Text>
                </Flex>
            </Col>
            <Col span={11}>
                <Form.Item style={{ position: "sticky", top: "0", zIndex: "11", background: "white" }} className="mb-0 passport-dropbox" label="Upload Passport Back Side" name={[index,"passportBack"]} rules={[{ required: true, message: 'Please Upload Passport Back Image!' }]} >
                    <FileUpload onRemove={() => {
                        form.resetFields([[index,"passportBack"]])
                    }} accept=".png, .jpeg, .jpg" placeholder={filePlaceholder} setFile={(val:any) =>{
                        backPassportDataFetch(val[0]?.originFileObj)
                        setBackFileList(val)
                        form.setFieldValue([index,"passportBack"], val)
                    }} fileData={backFileList} />
                </Form.Item>
            </Col>
            <Col span={13}>
                <Spin tip="Loading..." spinning={passLoading.back}>
                    <Row gutter={10}>
                        <Col span={24}>
                            <InputBox
                                label="Father Full Name"
                                name={[index,"father_name"]}
                                rules={[{ required: true, message: 'Please Enter Father Name!' }]}
                                isUppercase={true}
                            />
                        </Col>
                        <Col span={24}>
                            <InputBox
                                label="Mother Full Name"
                                name={[index,"mother_name"]}
                                rules={[{ required: true, message: 'Please Insert Mother Name!' }]}
                                isUppercase={true}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Marital Status"
                                name={[index,"marital_status"]}
                                rules={[{ required: true, message: 'Please Select Marital Status!' }]}
                            >
                                <Select
                                    placeholder="- Select Marital Status -"
                                    allowClear
                                    size="large"
                                    onChange={(val) => setMaritalStatus(val)}
                                >
                                    {maritalOpt.map(mrt => <Select.Option key={mrt} value={mrt.toUpperCase()}>{mrt}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {allowStatus.includes(maritalStatus) &&
                                <InputBox
                                    label="Spouse Name"
                                    name={[index,"spouse_name"]}
                                    rules={[{ required: true, message: 'Please Insert Spouse Name!' }]}
                                    isUppercase={true}
                                />}
                        </Col>
                        <Col span={24}>
                            <InputBox
                                label="Address"
                                name={[index,"address"]}
                                rules={[{ required: true, message: 'Please Insert Address!' }]}
                                isUppercase={true}
                            />
                        </Col>
                        <Col span={12}>
                            <InputBox
                                label="City"
                                name={[index,"city"]}
                                isUppercase={true}
                                rules={[{ required: true, message: 'Please Insert City!' }]}
                            />
                        </Col>
                        <Col span={12}>
                            <InputBox
                                label="Nationality"
                                name={[index,"nationality"]}
                                disabled={true}
                            />
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Language"
                                name={[index,"language"]}
                                rules={[{ required: true, message: 'Please Insert Language!' }]}
                            >
                                <Select
                                    placeholder="- Select Language -"
                                    allowClear
                                    size="large"
                                    showSearch
                                    filterOption={(input: any, option: any) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    options={langOpt.map(lang => ({
                                        value: lang.toUpperCase(),
                                        label: lang
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
            <Col span={24}>
                <Flex align="center" className="mb-3">
                    <span className="section-counter rounded-full border flex items-center justify-center font-bold mr-5">03</span>
                    <Typography.Text style={{ fontSize: "18px", fontWeight: 500 }}>Other Details</Typography.Text>
                </Flex>
            </Col>
            <Col span={8}>
                <Form.Item
                    label="Religion"
                    name={[index,"religion"]}
                    rules={[{ required: true, message: 'Please Select Religion!' }]}
                >
                    <Select
                        placeholder="- Select Religion -"
                        allowClear
                        size="large"
                        showSearch
                    >
                        {castOpt.map(cst => <Select.Option key={cst} value={cst.toUpperCase()}>{cst}</Select.Option>)}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    label="Profession"
                    name={[index,"profession"]}
                    rules={[{ required: true, message: 'Please Select Profession!' }]}
                >
                    <Select
                        placeholder="- Select Profession -"
                        allowClear
                        size="large"
                        showSearch
                    >
                        {index === 0 && profassion.map(prf => <Select.Option key={prf} value={prf.toUpperCase()}>{prf}</Select.Option>)}
                        {index !== 0 && <>
                            <Select.Option value={"CHILD"}>Child</Select.Option>
                            <Select.Option value={"STUDENT"}>Student</Select.Option>
                        </> }
                    </Select>
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    label="Group Membership"
                    name={[index,"group_membership"]}
                    rules={[{ required: true, message: 'Please Select Group Membership!' }]}
                >
                    <Select
                        placeholder="- Select Group Membership -"
                        allowClear
                        size="large"
                    >
                        {index === 0 && <Select.Option value="Main/Only Person in a Group">Main/Only Person in a Group</Select.Option>}
                        {index !== 0 && <Select.Option value="Son of Main Person">Son of Main Person</Select.Option>}
                        {index !== 0 && <Select.Option value="Daughter of Main Person">Daughter of Main Person</Select.Option>}
                    </Select>
                </Form.Item>
            </Col>
            {index === 0 && <Col span={12}>
                <ContactInput form={form} name={[index,"phone_number"]} label='Phone Number'  />
            </Col>}
            {index === 0 && <Col span={12}>
                <InputBox
                    label="Email Id"
                    name={[index,"email"]}
                    rules={[{ required: true, message: 'Please Insert Email Id!' }]}
                />
            </Col>}
        </Row>
        <Divider />
        <Row gutter={16} className="w-full">
            <Col lg={12} xs={24}>
                <Form.Item className="mb-0" label="Upload Traveller's photo" name={[index,"travellerPhoto"]} rules={[{ required: true, message: "Please Upload Traveller's Photo!" }]}>
                    <FileUpload onRemove={() => {
                        form.resetFields(["travellerPhoto"])
                    }} accept=".png, .jpeg, .jpg" placeholder={filePlaceholder} setFile={setPhotoList} fileData={photoList} />
                </Form.Item>
            </Col>
            {index === 0 && <Col lg={12} xs={24}>
                <Form.Item className="mb-0" name={[index,"birthId"]} label="PAN Card" rules={[{ required: true, message: "Please Upload Traveller's PAN Card!" }]}>
                    <FileUpload onRemove={() => {
                        form.resetFields(["birthId"])
                    }} accept=".png, .jpeg, .jpg" placeholder={filePlaceholder} setFile={setIdList} fileData={idList} />
                </Form.Item>
            </Col>}
        </Row>

        <Form.Item className="mb-0" label="Extra Document" name={[index,"extraDoc"]}>
            <Upload
                listType="picture-card"
                onChange={(e: any) => { supportiveDocOnChange(e) }}
                customRequest={custReq}
                multiple
                fileList={supportiveDocList}
                onRemove={removeExtraDoc}
            >
                <button style={{ border: 0, background: 'none' }} type="button">
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                </button>
            </Upload>
        </Form.Item>
        <Divider />
        {/* <Flex gap={5} justify="right" className="text-right">
            <Button type="default" onClick={navigatetoVisaPage}>Cancel</Button>
            <Button className="max-sm-w-full" name="save" type="default" htmlType="submit" loading={isLoading}>
                {isEditing ? "Update" : "Save as Draft"}
            </Button>
            <Button onClick={saveAndSubmit} className="max-sm-w-full" name="saveandsubmit" type="primary" htmlType="submit" loading={isLoading}>
                {isEditing ? "Update & Submit" : "Save & Submit"}
            </Button>
        </Flex> */}
    </>)
}

export default VisaForm