import { Avatar, Col, Descriptions, Drawer, Row, Table, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AxiosService from '../utils/APIService';
import { AntDesignOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';

function CRMTasks() {
    const [tasks, setTasks] = useState<any>();
    const axiosService = new AxiosService()
    const [drawerOpen, setDrawerOpen] = useState(false)
    useEffect(() => {
        fetchTasks()
    }, [])
    const [taskDetail, setTaskDetail] = useState<any>(null)
    
    const fetchTasks = () => {
        axiosService.get('/crm/tasks')
        .then((res:any) => {
            const data = res.data

            const taskList = {
                Pending: data.filter((dt:any) => dt.working_status === "PENDING"),
                "In Progress": data.filter((dt:any) => dt.working_status === "INPROGRESS"),
                Complete: data.filter((dt:any) => dt.working_status === "COMPLETED")
            }
            setTasks(taskList)

        })
        .catch(e => console.log(e))
    }

    const onDragEnd = (result:any) => {
        const { source, destination, draggableId } = result;        
    
        if (!destination) {
          return;
        }
    
        if (source.droppableId === destination.droppableId && source.index === destination.index) {
          return;
        }
    
        const sourceColumn = tasks[source.droppableId];
        const destColumn = tasks[destination.droppableId];
        const [removed] = sourceColumn.splice(source.index, 1);
        destColumn.splice(destination.index, 0, removed);
        
        setTasks({
            ...tasks,
            [source.droppableId]: sourceColumn,
            [destination.droppableId]: destColumn
        });
        if(destination.droppableId !== source.droppableId){
            changeTaskStatus(destination.droppableId, draggableId)
        }
    };
    const changeTaskStatus = (status:string, id:string) => {
        axiosService.put(`/crm/task-status/${id}`,{
            status: status
        }, false, false)
        .catch(e => console.log(e))
    }
    const closeDrawerHandle = () => {
        setDrawerOpen(false)
    }
    const openTask = (val:any) => {
        setTaskDetail(val)
        setDrawerOpen(true)
    }
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name'
        },
      ];
    return (<>
        <div className='container'>
            <Drawer 
                title={taskDetail && taskDetail.title} 
                onClose={closeDrawerHandle} 
                open={drawerOpen}
                width={800}
            >
                <Descriptions bordered style={{marginBottom: "30px"}}>
                    <Descriptions.Item span={3} label="Action">
                        {taskDetail?.action_type}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Note">
                        {taskDetail?.note}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Status">
                        {taskDetail?.working_status}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Due Date">
                        {moment(taskDetail?.due_date).format("DD-MM-YYYY")}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Created On">
                        {moment(taskDetail?.created_at).format("DD-MM-YYYY")}
                    </Descriptions.Item>
                </Descriptions>
                
            </Drawer>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="task-manager">
                    {tasks && Object.entries(tasks).map(([columnId, columnTasks]:any) => (
                    <Droppable droppableId={columnId} key={columnId}>
                        {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={`task-column ${columnId}`}
                        >
                            <div className='task-tag'>
                                <Typography.Title level={3}>{columnId}</Typography.Title>
                            </div>
                            {columnTasks.map((task:any, index:any) => (
                            <Draggable key={task._id} draggableId={task._id} index={index}>
                                {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="task-card"
                                    onClick={() => openTask(task)}
                                >
                                    <span style={{display: "block", marginBottom: "20px"}}>
                                        {task.title}
                                    </span>

                                    <Avatar.Group>
                                        {task.assigned_to.map((d:any) => {
                                            return <Tooltip title={d.name.first_name} placement="top">
                                                {d.profile_image ? <Avatar src={`${process.env.REACT_APP_API_URL}/user/profile-image/${d._id}`} /> : <Avatar>{d.name.first_name.toUpperCase()[0]}</Avatar>}
                                            </Tooltip>
                                        })}
                                    </Avatar.Group>
                                </div>
                                )}
                            </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                        )}
                    </Droppable>
                    ))}
                </div>
            </DragDropContext>
        </div>
    </>)
}

export default CRMTasks
