const downloadDocument = (docUrl:string, fileName:string) => {

  if(!docUrl){
    new Error("Document URL not found")
  }
  const a:any = document.createElement('a');
  const url = `data:application/octet-stream;base64,${docUrl}`
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export default downloadDocument;