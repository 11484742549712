import React, { useEffect, useState } from 'react'
import AxiosService from '../utils/APIService'

export default function useAdminAgents() {
    const axiosService = new AxiosService()
    const [agentList, setagentList] = useState([]);

    useEffect(() => {
        fetchAgentDetail()
    }, []);

    const fetchAgentDetail = () =>{
        axiosService.get('/agent/get')
            .then((res:any) => {
                setagentList(res.data)
            })
            .catch((e:any) => {
                console.log(e);                
            })
    }

    return {
        agentList,
        fetchAgentDetail
    }
}
