import { DownloadOutlined, EyeOutlined, FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, DatePicker, Dropdown, Flex, Form, Input, Row, Select, Space, Switch, Typography, UploadProps, message } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { useEffect } from 'react';
import uploadSvg from '../assets/svg/upload.svg';
import Dragger from "antd/es/upload/Dragger";
import { connect } from "react-redux";
import { RootState } from "../reducer";
import AxiosService from "../utils/APIService";
import { setSwitchVal } from "../action";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import useAdminAgents from "../hooks/useAdminAgents";
import FormModal from "../component/FormModal";
import { UserInterface } from "../interface";
import { APPLICATION_STATUS, ROLE } from "../consts";
import Alert from "antd/es/alert/Alert";
import Export from "../component/Export";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
const { RangePicker } = DatePicker;


const VisaRequest = ({switchVal, setSwitchVal, isLoading , user , adminPermission}: {switchVal:boolean, setSwitchVal:any, isLoading:boolean ,  user:UserInterface , adminPermission:any}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {agentList} = useAdminAgents()
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [file, setFile] = useState<any>(null)
    const [selectedForUpload, setSelectedForUpload] = useState<any>(null);
    const [applicationList, setApplicationList] = useState([]);
    const [typeList, setTypeList] = useState<any>([])
    const [searchText, setSearchText] = useState("")
    const navigate = useNavigate()
    const [form]:any = Form.useForm()
    const [form2]:any = Form.useForm()
    const checkPermissionForTableRow = (key:string) => {
        const per = adminPermission?.VISA?.find((data:any) => data.key === key)
        
        return (user?.role === ROLE.subAdmin && per?.allow) || user?.role === ROLE.admin
    }
    const props: UploadProps = {
        listType: "picture",
        onChange(info:any) {
          setFile(info)
        },
        customRequest({onSuccess}:any) {
            setTimeout(() => {
                onSuccess("ok")
            }, 0)
        },
        multiple: false
      };
    const axiosService = new AxiosService()
    const columns: ColumnsType<any> = [
        checkPermissionForTableRow("COMPANY") && {
          title: 'Company',
          dataIndex: 'agent',
          key: 'agent',
        },
        checkPermissionForTableRow("REQUEST_ID") && {
          title: 'Request Date/ID',
          dataIndex: 'reqDateId',
          key: 'reqDateId',
          render: (e:any) => (
            <>
              <span className="text-sm block mb-1" style={{ color: "#5F617A" }}>
                {moment(e.reqdate).format("DD-MM-YYYY")}
              </span>
              <span className="text-sm block" style={{ color: "#1A73E8" }}>
                ID#: {e.id}
              </span>
            </>
          ),
          width: "9%",
        },
        checkPermissionForTableRow("PASSANGER") && {
          title: 'Passenger',
          dataIndex: 'passangerDetail',
          key: 'passangerDetail',
          render: (e:any) => (
            <div>
              <span className="block font-semibold text-sm" style={{ color: "#000" }}>
                {e.name.first_name} {e.name.middle_name} {e.name.last_name}
              </span>
              <span className="block text-sm" style={{ color: "#5F617A" }}>
                {e.country} - {e.passportNumber}
              </span>
            </div>
          ),
        },
        checkPermissionForTableRow("VISA_TYPE") && {
          title: 'Visa Type',
          dataIndex: 'type',
          key: 'type',
          render: (e:any) => (
            <span className="block text-sm" style={{ color: "#5F617A", maxWidth: "180px" }}>
              {e}
            </span>
          ),
        },
        checkPermissionForTableRow("REQUEST_STATUS") && {
          title: 'Request Status',
          dataIndex: 'status',
          key: 'status',
          render: (e:any, rec:any) => (
            <Select
                className="status-dropdown"
                style={{ width: 120 }}
                value={e}
                options={Object.keys(APPLICATION_STATUS).map((ky:any) => ({
                    label: APPLICATION_STATUS[ky],
                    value: ky
                }))}
                onChange={(val:string) => {
                    statusChangeHandle(val, rec.key)
                }}
            />
          ),
        },
        checkPermissionForTableRow("LAST_DAY") && {
          title: 'Last Day',
          dataIndex: 'lastDay',
          key: 'lastDay',
        },
        checkPermissionForTableRow("RATE") && {
          title: 'Rate',
          dataIndex: 'pricing',
          key: 'pricing',
          render: (e:any) => (
            <>
              <span className="text-sm" style={{ color: "#1A73E8" }}>
                {e.amount}
              </span><br />
              <span className="text-sm" style={{ color: "#5F617A" }}>
                {e.currency}
              </span>
            </>
          ),
        },
        checkPermissionForTableRow("ACTION") && {
          title: 'Action',
          dataIndex: '',
          key: 'x',
          render: (e:any, rec:any) => (
            <> 
                <Space>
                    {e.resData.submit_by_admin ? (
                        <Space>
                        <Button shape="round" size={"small"} style={{color: "#5F617A"}} className="text-sm">Download</Button>
                        <Dropdown menu={{ 
                            items: [
                            { key: '1', label: (
                                <Flex align="center">{rec?.resData?.response_attatchment?.visa && <span className="dot active"></span> }Visa Upload</Flex>
                            ) }, 
                            { key: '2', label: (
                                <Flex align="center">{rec?.resData?.response_attatchment?.insurance && <span className="dot active"></span>} Insurance Upload</Flex>
                            ) }
                            ], 
                            onClick: (e:any) => onDocUpload(e, rec) 
                        }}>
                            <Button shape="round" size={"small"} style={{color: "#5F617A"}} className={`text-sm ${rec?.resData?.response_attatchment?.visa && rec?.resData?.response_attatchment?.insurance && 'success'}`}>Upload</Button>
                        </Dropdown>
                        </Space>
                    ) : (
                        <Space>
                        <Button shape="round" size={"small"} style={{color: "#5F617A"}} className="text-sm" onClick={() => updateApplicant(rec.key)}>Edit</Button>
                        <Button shape="round" size={"small"} style={{color: "#5F617A"}} className="text-sm" onClick={() => handleApplicationSubmit(rec.key)}>Submit</Button>
                        </Space>
                    )}
                    <Button shape="round" size={"small"} className="text-sm" style={{color: "#5F617A"}} onClick={() => navigate(`/application-details?id=${rec.key}`)}><EyeOutlined /></Button>
                </Space>
            </>
          ),
        },
      ].filter(Boolean);
    const handleApplicationSubmit = (id: string) => {
        axiosService.post(`/app/submit-by-admin/${id}`, {})
        .then(() => {
            fetchApplicants({})
        })
        .catch((e:any) => {
            console.log(e.message)
        })
    }
    const onDocUpload:any = (e:any, rec:any) => {
        let fileType = "insurance"
        
        switch (e.key) {
            case '1':
                fileType = "visa"
            break;
            case '2':
                fileType = "insurance"
            break;
        }
        
        setIsModalOpen(true)
        setSelectedForUpload({...rec, fileType: fileType})
    };
    const updateApplicant:any = (id:string) => {
        navigate(`/update-aplication?edit=${id}`)
    }
    useEffect(() => {
        document.body.classList.add('body-bg');
        fetchApplicants({})
        fetchVisaType()
        // eslint-disable-next-line
    }, []);
    const fetchVisaType = () => {
        axiosService.get('/price/get')
        .then((res:any) => {
            let visaType = res.data.map((data:any) => ({
                value: data._id,
                label: data.title,
                data: data
            }))
            setTypeList(visaType)
        })
        .catch((e:any) => {
            console.log(e)
        })
    }
    const statusChangeHandle = (val:string, id:string) => {
        axiosService.put(`/app/change-statue/${id}`, {
            status: val
        }).then(() => {
            fetchApplicants({})
        })
    }
    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedForUpload(null)
        setFile(null)
        form.resetFields()
    };
    const swichChangeHandle = (val:any) => {
        axiosService.post('/switch/change',{val: val}, false, false)
        .then((res:any) => {
            setSwitchVal(res.value)
            message.success(`Auto Submit ${res.value ? "On" : "Off"}`);
        })
        .catch((e:any) => {
            message.error(e.message)
        })
    }

    const fetchApplicants = ({country, startDate, endDate, search, status, visaType, agents}: 
        {country?:string | null, startDate?:any, endDate?:any, search?: string, status?:string[], visaType?:string, agents?:any[]}) => {
        axiosService.get('/app/admin-list', {
            country: country,
            startDate: startDate,
            endDate: endDate,
            search,
            status,
            visaType,
            agents
        })
            .then((res:any) => {
                const appList = res.data.map((app:any) => ({
                    key: app._id,
                    agent: `${app.applied_by_agent.name.first_name} ${app.applied_by_agent.name.last_name}`,
                    reqDateId: {
                        id: app.app_id,
                        reqdate: app.created_at
                    },
                    passangerDetail: {
                        name: app.passanger_detail.name,
                        country: app.passport_detail.passport_country,
                        passportNumber: app.passport_detail.passport_number,
                    },
                    type: app.pricing_detail.title,
                    status: app.request_status,
                    pricing: {
                        amount: app?.rate,
                        currency: "INR"
                    },
                    lastDay: "-",
                    resData: app
                }))
                setApplicationList(appList)
            })
            .catch((e:any)=> {
                console.log(e);
            })
    }
    const formUploadHandle = () => {
        if(file && selectedForUpload){
            const formData = new FormData()
            formData.append('doc', file?.file?.originFileObj)
            axiosService.post(`/app/add-doc/${selectedForUpload.key}/${selectedForUpload.fileType}`,formData)
            .then(() => {
                setIsModalOpen(false)
                setFile(null)
                form.resetFields()
                fetchApplicants({})
            })
            .catch((e) => {
                console.log(e);
            })
        }else{
            message.error("Please Select the file");
        }
    }
    const filterHandler = (val:any) => {
        const startDate = val?.reqDateRange?.length > 0 ? moment(val?.reqDateRange[0].toString()).format("DD-MM-YYYY") : undefined
        const endDate = val?.reqDateRange?.length > 0 ? moment(val?.reqDateRange[1].toString()).format("DD-MM-YYYY") : undefined
        fetchApplicants({
            startDate: startDate, 
            endDate: endDate,
            search: val.search, 
            status: val.status,
            visaType: val.visaType,
            agents: val.agent
        })
    }
    const filterReset = () => {        
        form2.resetFields()
        fetchApplicants({})
    }
    
    const filterData = (key:string) => {
        return applicationList.filter((app:any) => {
            let name = `${app.passangerDetail.name.first_name} ${app.passangerDetail.name.middle_name} ${app.passangerDetail.name.last_name}`
            return name.toLowerCase().includes(key.toLowerCase()) ||
                app.reqDateId.id.toLowerCase().includes(key.toLowerCase()) || 
                app.passangerDetail.passportNumber.toLowerCase().includes(key.toLowerCase()) 
    }   )
    }
    const exportToCSV = () => {          
        const header = ["Company", "Reuest Date", "Passenger",   "Visa Type", "Request Status", "Last Day", "Rate" , "Action"];
        const csvRows = [];
        csvRows.push(header.join(','));
        applicationList.forEach((item:any) => {
            const row = [
                item.agent,
                `${moment(item.reqDateId.reqdate).format("DD-MM-YYYY HH:mm")} - ID#: ${item.reqDateId.id}`,
                `${item.passangerDetail.name?.first_name} ${item.passangerDetail.name?.middle_name} ${item.passangerDetail.name?.last_name}`,
                item.type,
                item.status,
                item.lastDay,
                `${item.resData.rate}`
            ];
            csvRows.push(row.join(','));
        });
        const csvData = csvRows.join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "visarequest.csv");
        link.click();
    };
    const exportToPDF = () => {     
        const header = ["Company", "Reuest Date", "Passenger",   "Visa Type", "Request Status", "Last Day", "Rate" , "Action"];
        let transactionListArray = applicationList.map((item:any) => [
            item.agent,
            `${moment(item.reqDateId.reqdate).format("DD-MM-YYYY HH:mm")} - ID#: ${item.reqDateId.id}`,
            `${item.passangerDetail.name?.first_name} ${item.passangerDetail.name?.middle_name} ${item.passangerDetail.name?.last_name}`,
            item.type,
            item.status,
            item.lastDay,
            `${item.resData.rate}`
            // Add additional columns if needed
        ]);
        const doc = new jsPDF();
        doc.setFontSize(9);
        autoTable(doc, {
            head: [header],
            body: transactionListArray,
          });
        doc.save('visarequest.pdf');
    };
    
    return (
        <div className="container">
            <FormModal
                title="Upload Document" 
                buttonText="Upload"
                width={800}
                form={form}
                handleCancel={handleCancel}
                handleForm={formUploadHandle}
                isModalOpen={isModalOpen}
                loading={isLoading}
            >
                <Form.Item name={"files"}
                >
                    <Dragger
                        maxCount={1}
                        {...props}
                        className="modal-dropbox"
                    >
                        <div className="flex flex-col items-center">

                            <img className="mb-7" style={{width: "40px"}} src={uploadSvg} alt="imgs" />
                            <div style={{width: "315px"}}>
                                <p style={{color: "#1A73E8", fontSize: "14px"}}>Drag an Image here or click to upload</p>
                                <span style={{color: "#99A1BB", fontSize: "14px"}}>Upload an image in either a PNG or JPEG format. Maximum size: 2MB.</span>
                            </div>
                        </div>
                    </Dragger>
                </Form.Item>
            </FormModal>
            <div className="flex justify-between items-center mb-4 max-md-block">
                <div className="flex max-md-mb-3 max-sm-block">
                    <p className="page-title relative pr-4 mr-4 font-medium mb-2">Visa</p>
                    <Breadcrumb
                        items={[
                            {
                                title: 'Home'
                            },
                            {
                                title: 'Visa',
                            },
                        ]}
                        itemRender={(data:any) => {
                            if(data.path){
                                return <Link to={data.path}>{data.title}</Link>
                            }
                            return data.title
                        }}
                    />
                </div>
                <div>
                    <Space size={"middle"} className="max-sm-block">
                        {checkPermissionForTableRow("AUTO_SUBMIT") && <Space>
                            <Typography.Text>Auto Submit</Typography.Text>
                            <Switch value={switchVal} onChange={swichChangeHandle} />
                        </Space>}
                        <Input placeholder="Search..."onChange={(e) => setSearchText(e.target.value)} prefix={<SearchOutlined />} />
                        <Export 
                        exportToCsv={exportToCSV}
                        exportToPdf={exportToPDF}
                        />
                        <Button icon={<FilterOutlined />} className="max-sm-mb-2 max-sm-w-full" onClick={() => setIsDrawerOpen(! isDrawerOpen)}>Filter</Button>
                    </Space>
                </div>
            </div>
            {isDrawerOpen && <Card style={{ width: "100%", marginBottom: "15px" }}>
                <Form form={form2} onFinish={filterHandler} layout="vertical">
                    <Row gutter={16} align={'middle'}>
                        <Col lg={5}>
                            <Form.Item label="Request Date" name="reqDateRange">
                                <RangePicker />
                            </Form.Item>
                        </Col>
                        <Col lg={5}>
                            <Form.Item label="Status" name="status">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="Select Status"
                                    options={[
                                        { label: "Draft", value: "DRAFT" },
                                        { label: "Pending", value: "PENDING" },
                                        { label: "Processing", value: "PROCESSING" },
                                        { label: "Complete", value: "COMPLETE" },
                                        { label: "Rejected", value: "REJECTED" }
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={5}>
                            <Form.Item label="Visa Type" name="visaType">
                                <Select
                                    allowClear
                                    placeholder="Select Type"
                                    options={typeList}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={5}>
                            <Form.Item label="Agent" name="agent">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="select agent"
                                    showSearch
                                    filterOption={(input:any, option:any) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    options={agentList.map((agt:any) => ({
                                        label: `${agt?.name?.first_name} ${agt?.name?.last_name}`,
                                        value: agt._id
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={4}>
                            <Space>
                                <Button style={{marginTop:".5em"}} className="max-sm-w-full" type="primary" htmlType="submit">
                                    Apply Filter
                                </Button>
                                <Button style={{marginTop:".5em"}} className="max-sm-w-full" type="default" onClick={filterReset}>
                                    Reset Filter
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>}
            {
                checkPermissionForTableRow("COMPANY") ||
                checkPermissionForTableRow("REQUEST_ID") ||
                checkPermissionForTableRow("PASSANGER") ||
                checkPermissionForTableRow("VISA_TYPE") ||
                checkPermissionForTableRow("REQUEST_STATUS") ||
                checkPermissionForTableRow("LAST_DAY") ||
                checkPermissionForTableRow("RATE") ?(
                <Table
                className='border-around'
                columns={columns.filter(Boolean)}
                dataSource={searchText ? filterData(searchText) : applicationList}
                scroll={{ x: 1219 }}
                loading={isLoading}
                />):(<Alert message="You Don't Have Enough Permissions" type="error" />)
            }
           
        </div>
    )
}
const mapStateToProps = ({switchState, loading , login}: RootState) => {
    const { user  , adminPermission } = login
    const {switchVal} = switchState
    const {isLoading} = loading
    return {switchVal, isLoading , login , adminPermission , user}
}
const mapDispatchToProps = {
    setSwitchVal
}
export default connect(mapStateToProps, mapDispatchToProps) (VisaRequest);