import React, { useState } from "react";
import {
  Form,
  Collapse,
  Input,
  Divider,
  Flex,
  Button,
  message,
  Row,
  Col,
  Checkbox,
  Typography,
  Card
} from "antd";
import TextArea from "antd/es/input/TextArea";
import AxiosService from "../utils/APIService";
import { useNavigate } from "react-router-dom";
import { ContactInput, InputBox } from "../component/FormElements";


const { Panel } = Collapse;
const AddClient = () => {
  const axiosService = new AxiosService()
  const [form] = Form.useForm()
  const navigator = useNavigate()
  const onFinish = (val: any) => {
    const payload = {
      personal_details_main: {
        name: {
          first_name: val.firstname,
          last_name: val.lastname,
        },
        email: val.email,
        phone_no: val.phoneNumber,
        whatsapp_no: val.wpnumber,
      },
      personal_details_alt: {
        name: {
          first_name: val.firstname_alt,
          last_name: val.lastname_alt,
        },
        email: val.email_alt,
        phone_no: val.phoneNumber_alt,
        whatsapp_no: val.wpnumber_alt,
      },
      company_detail: {
        business_name: val.buisnessname,
        phone_no: val.companyphonenumber,
        land_line_number: val.landlinenumber,
        email: val.companyEmail,
        website: val.website,
        address: {
          full_address: val.fullAddress,
          area: val.area,
          pincode: val.pincode,
          city: val.city,
          state: val.state
        }
      },
      note: val.notes
    }
    axiosService.post('/crm/create',payload)
      .then(() => {
        navigator('/crm/client-list')
      })
  }

  return (
    <div className="container">
      <Card>
        <Form
          onFinish={onFinish}
          className="registration-form"
          layout="vertical"
          initialValues={{ tnc: false, promotion: true }}
          form={form}
        >
          <div className="companey-details">
            <Collapse
              className="collaps"
              style={{ background: "#F8F9FB" }}
              defaultActiveKey={["1"]}
            >
              <Panel header={(<Typography.Text style={{ fontSize: "18px", fontWeight: "500"}}>Person Details</Typography.Text>)} key="1">

                <Flex justify="space-between" className="form-part">
                  <div>
                    <Row gutter={16}>
                      <Col span={12}>
                        <InputBox
                          style={{ width: "100%" }}
                          label="First Name"
                          name="firstname"
                          rules={[
                            {
                              required: true,
                              message: "Please enter first name!",
                            },
                          ]}
                        />
                      </Col>
                      <Col span={12}>
                        <InputBox
                          style={{ width: "100%" }}
                          label="Last Name"
                          name="lastname"
                        />
                      </Col>
                    </Row>
                    <InputBox
                      label="Email"
                      name="email"
                      style={{ width: "100%" }}
                    />
                    <Row gutter={16}>
                      <Col span={12}>
                        <ContactInput form={form} name="phoneNumber" label="Phone Number" />
                      </Col>
                      <Col span={12}>
                        <InputBox
                          label="WhatsApp Number"
                          name="wpnumber"
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <span style={{ border: "1px solid #DFE1E5" }}></span>
                  <div>
                    <Row gutter={16}>
                      <Col span={12}>
                        <InputBox
                          style={{ width: "100%" }}
                          label="First Name"
                          name="firstname_alt"
                        />
                      </Col>
                      <Col span={12}>
                        <InputBox
                          style={{ width: "100%" }}
                          label="Last Name"
                          name="lastname_alt"
                        />
                      </Col>
                    </Row>
                    <InputBox
                      label="Email"
                      name="email_alt"
                      style={{ width: "100%" }}
                      rules={[{  type: "email", message: 'Please input valid email!' }]}
                    />
                    <Row gutter={16}>
                      <Col span={12}>
                        <InputBox
                          style={{ width: "100%" }}
                          label="Phone Number"
                          name="phoneNumber_alt"
                        />
                      </Col>
                      <Col span={12}>
                        <InputBox
                          label="WhatsApp Number"
                          name="wpnumber_alt"
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Flex>
              </Panel>
            </Collapse>
          </div>

          <Divider />

          <div className="Owners-Authentication-Details Company Details">
            <Collapse
              className="collaps"
              style={{ background: "#F8F9FB" }}
              defaultActiveKey={["1"]}
            >
              <Panel header={(<Typography.Text style={{ fontSize: "18px", fontWeight: "500"}}>Company Details</Typography.Text>)} key="1">
                <Flex justify="space-between" className="form-part">
                  <div>
                    <Flex gap={"middle"}>
                      <InputBox
                        label="Business Name"
                        name="buisnessname"
                        style={{ width: "100%" }}
                      />
                    </Flex>

                    <Flex gap={"middle"}>
                      <InputBox
                        label="Company Phone Number"
                        name="companyphonenumber"
                        style={{ width: "100%" }}
                      />
                      <InputBox
                        label="Land Line Number"
                        name="landlinenumber"
                        style={{ width: "100%" }}
                      />
                    </Flex>


                    <Flex gap={"middle"}>
                      <InputBox
                        label="Email"
                        name="companyEmail"
                        style={{ width: "100%" }}
                      />
                      <InputBox
                        label="Website"
                        name="website"
                        style={{ width: "100%" }}
                      />
                    </Flex>
                  </div>
                  <span style={{ border: "1px solid #DFE1E5" }}></span>
                  <div>
                    <InputBox
                      label="Full Address"
                      name="fullAddress"
                    />
                    <Flex gap={"middle"}>
                      <InputBox
                        label="Area"
                        name="area"
                        style={{ width: "100%" }}
                      />
                      <InputBox
                        label="Pincode"
                        name="pincode"
                        style={{ width: "100%" }}
                      />
                    </Flex>

                    <Flex gap={"middle"}>
                      <InputBox
                        label="City"
                        name="city"
                        style={{ width: "100%" }}
                      />
                      <InputBox
                        label="State"
                        name="state"
                        style={{ width: "100%" }}
                      />
                    </Flex>
                  </div>
                </Flex>
              </Panel>
            </Collapse>
          </div>

          <Divider />

          <div className="Upload-Doc Extra Details">
            <Collapse
              className="collaps"
              style={{ background: "#F8F9FB" }}
              defaultActiveKey={["1"]}
            >
              <Panel header={(<Typography.Text style={{ fontSize: "18px", fontWeight: "500"}}>Extra Details</Typography.Text>)} key="1">
                <Form.Item
                  label="Notes"
                  name="notes"
                >
                  <TextArea
                    placeholder="Enter your notes"
                    autoSize={{ minRows: 5, maxRows: 5 }}
                  />

                </Form.Item>
              </Panel>
            </Collapse>

          </div>

          <Flex className="mt-5" justify="flex-end">
            <Button htmlType="submit" size="large" type="primary">
              Submit
            </Button>
          </Flex>
        </Form>
      </Card>

    </div>
  );
};
export default AddClient;
