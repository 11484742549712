import { Dropdown, Space, Tabs, TabsProps, MenuProps } from "antd";
import headerLogo from "../assets/svg/main-logo.svg";
import { DollarOutlined, 
    DownOutlined, 
    FileTextOutlined, 
    HomeOutlined, 
    IdcardOutlined, 
    LogoutOutlined, 
    TeamOutlined, 
    SolutionOutlined,
    UserOutlined } from "@ant-design/icons";
import avtar from "../assets/img/avtar.png";
import mLogo from "../assets/svg/m-logo.svg"
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "../reducer";
import { LocalStorageService } from "../utils/LocalStorage";
import {isLogin, onLogin} from "../action"
import { UserInterface } from "../interface";
import AdminPermission from "../pages/AdminPermission";
import { ROLE } from "../consts";
const AdminLayout = ({ children, user, isLogin,adminPermission, onLogin }: {
    children?:any,
    user: UserInterface,
    isLogin(isLogin:boolean):void,
    adminPermission:any
    onLogin(onLogin:UserInterface | null):void;
}) => {
 
    const navigate = useNavigate()
    const location = useLocation() 
    const tabitems: any['items'] = [
        {
            key: 'HOME',
            label: (<div>
                <HomeOutlined className="max-sm-hidden" /> Home
            </div>),
        },
        {
            key: 'VISA',
            label: (<div>
                <IdcardOutlined className="max-sm-hidden" /> Visa
            </div>),
        },
        {
            key: 'PRICING',
            label: (<div>
                <DollarOutlined className="max-sm-hidden" /> Pricing
            </div>),
        },
        {
            key: 'AGENTS',
            label: (<div>
                <UserOutlined className="max-sm-hidden" /> Agents
            </div>),
        },
        {
            key: 'TRANS',
            label: (<div>
                <FileTextOutlined  className="max-sm-hidden" /> Transaction
            </div>),
        },
        ( user?.role === ROLE.admin || adminPermission?.TEAM?.allow ? {
            key: 'TEAM',
            label: (<div>
                <TeamOutlined  className="max-sm-hidden" /> Team
            </div>),
        }:null),
        {
            key: 'CRM',
            label: (<div>
                <SolutionOutlined  className="max-sm-hidden" /> CRM
            </div>),
        }
    ].filter(Boolean)
    const items: MenuProps['items'] = [
        {
            label: 'My Profile',
            key: '0',
            icon: <UserOutlined style={{fontSize: "16px"}} />
        },
        {
            label: 'Logout',
            key: '4',
            icon: <LogoutOutlined style={{ fontSize: "16px" }} />,
        },
    ];
    const tabChangeHandle = (val:any) => {

        switch (val) {
            case "VISA":
                navigate('/visa')
                break;
            case "PRICING":
                navigate('/pricing')
                break;
            case "AGENTS":
                navigate('/agent')
                break;
            case "TRANS":
                navigate('/transaction')
                break;
            case "TEAM":
                navigate('/team')
                break;
            case "CRM":
                navigate('/crm')
                break;
        
            default:
                break;
        }
        
    }
    const defaultActivate:any = () => {
       
        const {pathname} = location

        switch (pathname) {
            case "/visa":
                return "VISA";
            case "/pricing":
                return "PRICING";
            case "/agent":
                return 'AGENTS';
            case "/transaction":
                return "TRANS"
            case "/team":
                return "TEAM"
            case "/crm/client-list":
                return "CRM"
            default:
                return '1'
        }
    }
    const handleDropdownItemClick = (rec:any) => {
        switch (rec.key) {
            case '0':
                navigate('/adminprofile')
                break;
            case '4':
                logoutHandle()
                break;
        
            default:
                break;
        }
    }
    const logoutHandle = () => {
        LocalStorageService.removeItem('userToken')
        LocalStorageService.removeItem("userData")
        isLogin(false)
        onLogin(null)
        navigate('/login')
    }
    return (
        <div>
            <div className="header-main mb-5">
                <div className="container">
                    <div className="header-wrapper flex justify-between">
                        <img src={headerLogo} alt="header logo" className="max-sm-hidden" />
                        <img src={mLogo} alt="header logo"  className="hidden max-sm-block"/>
                        <Tabs onChange={tabChangeHandle} className="header-tab" size="large" defaultActiveKey={defaultActivate} items={tabitems} />
                        <div className="flex items-center">
                            <Dropdown menu={{ items, onClick: handleDropdownItemClick }} trigger={['click']} className="ml-6 ">
                                <a className="text-black cursor-pointer relative" onClick={(e) => e.preventDefault()}>
                                    <Space className="flex">
                                        <img height={37} width={37} src={avtar} />
                                        <span className="max-md-hidden">{user?.name?.first_name} {user?.name?.last_name}</span>
                                        <DownOutlined className="max-sm-hidden" />
                                    </Space>
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </div>
    )
}
const mapStateToProps = ({login}: RootState) => {
    const {user , adminPermission} = login
    return {user ,  adminPermission}
}
const mapDispatchToProps = {
    onLogin,
    isLogin,
   
}
export default connect (mapStateToProps, mapDispatchToProps) (AdminLayout);
