import { Button, Dropdown, MenuProps } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const Export = ({exportToCsv , exportToPdf}:{exportToCsv:()=>void , exportToPdf:()=>void}) => {
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <p>PDF</p>,
      onClick: () => {
        exportToPdf()
      },
    },
    {
      key: "2",
      label: <p>CSV</p>,
      onClick: () => {
        exportToCsv()
      },
    },
  ];
  return (
    <Dropdown menu={{ items }} placement="bottom">
      <Button icon={<DownloadOutlined />} className="max-sm-mb-2 max-sm-w-full">
        Export
      </Button>
    </Dropdown>
  );
};

export default Export;
