import {SET_WALLET_AMOUNT, WALLET_CHANGE_TOGGLE} from "../action/walletAction"

export interface WalletState {
    balance: number,
    isChanged:boolean;
}
const initialState:WalletState = {
    balance: 0,
    isChanged: false
}

const walletReducer = (state = initialState, action:any) =>{
    switch (action.type) {
        case SET_WALLET_AMOUNT:
            return {
                ...state,
                balance: action.payload
            }
        
        case WALLET_CHANGE_TOGGLE:
            return {
                ...state,
                isChanged: !state.isChanged
            }

        default:
            return state;
    }
}

export default walletReducer