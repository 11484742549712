import {SET_ROUTE} from "../action/routerAction"

export interface RouterState {
    currentRoute: string;
}
const initialState:RouterState = {
    currentRoute: ""
}

const routerReducer = (state = initialState, action:any) =>{
    switch (action.type) {
        case SET_ROUTE:
            return {
                ...state,
                currentRoute: action.payload
            }

        default:
            return state;
    }
}

export default routerReducer