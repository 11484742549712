import react from "react";
import { message, Form, Button, Input, Dropdown } from "antd";
import type { MenuProps } from "antd";
import Logo from "../assets/svg/logo.svg";
import arrow from "../assets/svg/dropdwon-arrow.svg";
import enIcon from "../assets/svg/en_flag.svg";
import { Link } from "react-router-dom";
import AxiosService from "../utils/APIService";
import { useLocation } from "react-router-dom";
import { InputBox } from "../component/FormElements";
const ResetPassword = () => {
  const axiosService = new AxiosService();
  const location = useLocation();
  const [form] = Form.useForm()
  

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <Link to="/home">Home</Link>,
    },
  ];
  const token = new URLSearchParams(location.search).get("token");
  const changePassword = async (value: any) => {
    if (!token) {
      message.error("Invalid URL");
      return;
    }

    axiosService
      .put("/auth/reset-password", {
        token: token,
        password: value.password,
        confirmPassword: value.confirmpassword,
      })
      .then(() => {
        form.resetFields()
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div style={{ height: "100vh" }}>
      <div
        className="login-header flex items-center justify-end m-auto"
        style={{ paddingTop: "26px", paddingBottom: "26px", width: "95%" }}
      >
        <Dropdown menu={{ items }} className="lang-dropdown">
          <a onClick={(e) => e.preventDefault()}>
            <div className="dropdown-align inline-flex items-center">
              <img src={enIcon} alt="flag" style={{ marginRight: "10px" }} />
              <span style={{ marginRight: "10px" }}>English</span>
              <img src={arrow} alt="down-arrow" />
            </div>
          </a>
        </Dropdown>
        <p className="header-text relative" style={{ paddingLeft: "15px" }}>
          <Link to={"/"}>Login</Link>
        </p>
      </div>
      <div
        className="flex items-center justify-center"
        style={{ height: "calc(100vh - 81px)" }}
      >
        <div
          className="flex flex-col justify-between login-content w-full"
          style={{
            maxWidth: "400px",
            margin: "auto",
          }}
        >
          <div>
            <img
              src={Logo}
              alt="logo"
              className="block m-auto"
              style={{ marginBottom: "43px" }}
            />
            <Form
              form={form}
              name="changePassword"
              onFinish={changePassword}
              autoComplete="off"
              style={{ marginBottom: "20px" }}
            >
              <InputBox
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                placeholder="Enter password"
                type="password"
              />

              <InputBox
                name="confirmpassword"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }:any) => ({
                    validator(_:any, value:any) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
                placeholder="Confirm Password"
                type="password"
              />
              <Button block className="signin-btn" htmlType="submit">
               Reset Password
              </Button>
            </Form>
          </div>

          <div
            className="text-sm flex justify-between ml-auto mr-auto footer-text"
            style={{
              color: "#5F617A",
              width: "380px",
            }}
          >
            <span>Got a question?</span>
            <a href="#" className="underline">
              contact@company.com
            </a>
            <span className="resp-hide">|</span>
            <a href="#">+91 9426773719</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
