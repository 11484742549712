export const IS_LOGIN = "loginReducer/IS_LOGIN"
export const ON_LOGIN = "loginReducer/ON_LOGIN"
export const SET_PERMISSION = "loginReducer/SET_PERMISSION"
export const SET_ADMINPERMISSION = "loginReducer/SET_ADMINPERMISSION"

export const isLogin = (val:any) => (disptch:any) =>{
    return disptch({
        type: IS_LOGIN,
        payload: val
    });
}

export const onLogin = (val:any) => (disptch:any) =>{
  return disptch({
    type: ON_LOGIN,
    payload: val
  })
}

export const setPermission = (val:any) => (disptch:any) => {
  return disptch({
    type: SET_PERMISSION,
    payload: val
  })
}

export const setAdminPermission = (val:any) => (disptch:any) => {
  return disptch({
    type: SET_ADMINPERMISSION,
    payload: val
  })
}

