import { EyeInvisibleOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, Flex, Form, Input, Row, Select, Space, Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import user1 from "../assets/img/user1.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import AxiosService from "../utils/APIService";
import FormModal from "../component/FormModal";
import { decryptPassword, decryptSalt } from "../utils/passwordDecs";
import { connect } from "react-redux";
import { RootState } from "../reducer";
import { UserInterface } from "../interface";
import { ContactInput, InputBox } from "../component/FormElements";
const ToggleView = ({text}: {text:string}) => {
    const [isVisible, setisVisible] = useState(false);

    return (
        <>
            {isVisible ? <div className="flex items-center">
                <span className="text-sm block mr-2">{text}</span>
                <Button type="text" onClick={() => setisVisible(false)} icon={<EyeInvisibleOutlined />}></Button>
            </div> : <div className="flex items-center">
                <span className="text-sm block mr-2">*********</span>
                <Button type="text" onClick={() => setisVisible(true)} icon={<EyeOutlined />}></Button>
            </div>}
            
        </>
    )
}
const UserManage = ({isLoading, user}: {
    isLoading: boolean,
    user: UserInterface
}) => {
    const [form] = Form.useForm()
    const axiosService = new AxiosService()
    useEffect(() => {
        document.body.classList.add('body-bg');
    }, []);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const columns: ColumnsType<any> = [
        {
            title: 'Username',
            dataIndex: 'profile',
            key: 'profile',
            render: (e) => (<>
                <div className="flex items-center">
                    <img className="avatar" src={e.userImage} />
                    <span className="text-sm block" style={{color: "#5F617A"}}>{e.username}</span>
                </div>
            </>)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
            render: (e) => (<ToggleView text={e} />),
            width: '30%'

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (e:any) => (
                <Select
                    className={`status-dropdown`}
                    style={{ width: 120 }}
                    value={e.text}
                    onChange={(val) => {
                        statusChangeHandle(val, e.id)
                    }}
                >
                    <Select.Option value="ACTIVE"><Flex align="center"><span className="dot active"/> <span>Active</span></Flex></Select.Option>
                    <Select.Option value="DEACTIVE"><Flex align="center"><span className="dot deactive"/> <span>Deactivate</span></Flex></Select.Option>
                </Select>
            )
        },
        {
            title: 'Permission',
            dataIndex: 'permission',
            key: 'permission',
            render: (_) => {
                return (<Button onClick={() => {navigate(`/permission?user=${_}`)}} shape="round" size={"small"} className="text-sm" style={{color: "#5F617A"}}>Edit</Button>)
            },
            width: '8%',
            align: "center"
        }
    ];

    const [userList, setUserList] = useState([])

    useEffect(() => {
        getSubAgents();
    },[])
    const showModal = () => {
        setIsModalOpen(true);
    };
    const statusChangeHandle = (data:any, id:any) => {
        axiosService.post(`/user/change-status/${id}`, {
            status: data
        }).then(() => {
            getSubAgents()
        })
        .catch((e:any) => {
            console.log(e);
        })
        
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields()
    };
    const addUserHandler = (val:any) => {
        axiosService.post('/user/sub-agent', {
            firstName: val.firstname, 
            lastName: val.lastname, 
            email: val.email, 
            password: val.password,
            contactNo: val.phone_no
        })
        .then(() => {
            setIsModalOpen(false)
            form.resetFields()
            getSubAgents()
        }).catch((e) => {
            console.log(e);
        })
    }
    const getSubAgents = () => {
        axiosService.get('/user/sub-agent-list')
            .then((res:any) => {
                const usrData = res.map((data:any) => {
                    const salt:string = decryptSalt(data.salt);
                    const password:string = decryptPassword(data.hashed_password, salt);    
                    return{
                        profile: {
                            username: data.name.first_name,
                            userImage: user1
                        },
                        email: data.email,
                        password: password,
                        status: {
                            text: data.status,
                            id: data._id
                        },
                        permission: data._id
                    }
                })
                setUserList(usrData)
            })
            .catch((e:any) => {
                console.log(e);
            })
    }
    const validateNoSpaces = (_:any, value:any) => {
        if (value && /\s/.test(value)) {
            return Promise.reject(new Error('Spaces not allowed!'));
        }
        return Promise.resolve();
    };
    return (
        <div className="container">
            <FormModal 
                title="Add New User" 
                buttonText="Send Request"
                width={500}
                form={form}
                handleCancel={handleCancel}
                handleForm={addUserHandler}
                isModalOpen={isModalOpen}
                loading={isLoading}
                >
                    <Row gutter={8} className="mb-4">
                        <Col span={12}>
                            <InputBox
                                label="First Name"
                                name="firstname"
                                rules={[{ required: true, message: 'Please input First Name!' }, { validator: validateNoSpaces }]}
                            />
                        </Col>
                        <Col span={12}>
                            <InputBox
                                label="Last Name"
                                name="lastname"   
                                rules={[{ validator: validateNoSpaces }]}
                                />
                        </Col>
                    </Row>
                    <InputBox
                        label="Mail id"
                        name="email"
                        className="mb-4"
                        rules={[{ required: true, message: 'Please input your email!', type: "email" }]}
                    />
                    <ContactInput form={form} name="phone_no" label="Phone Number" />
                    <InputBox
                        label="Password"
                        name="password"
                        className="mb-4"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                        type="password"  
                    />
                </FormModal>
            <div className="flex justify-between items-center mb-4 max-md-block">
                <div className="flex max-md-mb-3 max-sm-block">
                    <p className="page-title relative pr-4 mr-4 font-medium mb-2">Manage Users</p>
                    <Breadcrumb
                        items={[
                            {
                                title: 'Home',
                            },
                            {
                                title: 'User List',
                            },
                        ]}
                    />
                </div>
                <div>
                    <Space size={"middle"} className="max-sm-block">
                        <Button onClick={showModal} type="primary" disabled={!user?.is_profile_updated} className="max-sm-w-full" icon={<PlusOutlined />}>Add New User</Button>
                    </Space>
                </div>
            </div>
            <Table
                className='border-around'
                columns={columns}
                dataSource={userList}
                scroll={{ x: 1219 }}
            />
        </div>
    )
}
const mapStateToProps = ({loading, login}:RootState) => {
    const {isLoading} = loading
    const {user} = login
    return {
        isLoading,
        user
    }
}
export default connect(mapStateToProps, {}) (UserManage)