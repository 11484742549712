import { ON_LOADING } from "../action/loadingAction";

export interface LoadingState {
  isLoading: boolean;
}
const initialState:LoadingState = {
  isLoading: false,
};

const loadingReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case ON_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    default:
      return state;
  }
};

export default loadingReducer;
