import React, { useEffect, useState } from "react";
import {
    Form,
    Input,
    Divider,
    Flex,
    Button,
    Breadcrumb,
    message,
    Col,
    Row,
} from "antd";

import type { UploadFile} from 'antd';
import {
    UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AxiosService from "../utils/APIService";
import { UserInterface } from "../interface";
import { RootState } from "../reducer";
import { connect } from "react-redux";
import FileUpload from "../component/FileUpload";
import { ContactInput, InputBox } from "../component/FormElements";
import FormModal from "../component/FormModal";
import { decryptPassword, decryptSalt } from "../utils/passwordDecs";

const EditProfile = ({ user, isLoading }: { user?: UserInterface, isLoading:boolean }) => {
    const [form] = Form.useForm()
    const [form1] = Form.useForm()
    let userId = user?._id
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [passwordChange, setPasswordChange] = useState(false)
    const [password, setPassword] = useState("")

    useEffect(() => {
        form.setFieldValue("firstName", user?.name?.first_name)
        form.setFieldValue("lastName", user?.name?.last_name)
        form.setFieldValue("ownerPhoneNumber", user?.contact_no)
        form.setFieldValue("ownerEmail", user?.email)
        const salt:string = decryptSalt(user?.salt);
        const password:string = decryptPassword(user?.hashed_password as string, salt);
        setPassword(password)
        if(user?.profile_image){
            const profileImg:any = [
                {
                  uid: '-1',
                  name: 'image.png',
                  status: 'done',
                  url: `${process.env.REACT_APP_API_URL}/user/profile-image/${userId}`,
                }
              ]
              setFileList(profileImg)
        }
    }, [user]);

    const onChange = (file: any) => {
        if (file) {
            const formData = new FormData();
            const image: any | undefined = file;
            formData.append("image", image);
            axiosService.post(`/user/change-profile-image/${userId}`, formData)
                .catch((err: any) => {
                    console.log(err.message)
                })
        }
    };
    const axiosService = new AxiosService()
    const onFinish = (values: any) => {
        const payload = {
            first_name: values.firstName,
            last_name: values.lastName,
            phone_number: values.ownerPhoneNumber,
            password: values.password,
            email: values.ownerEmail,
            confirmPassword: values.confirmPassword
        }
        axiosService
            .put('/agent/profile-update', payload)
            .then(() => {
                form.resetFields(["password", "confirmPassword"])
            })
            .catch((error: any) => {
                console.log(error);
            });

    }

    const resetPasswordHandle = (val:any) => {
        axiosService.put(`/agent/password-change/${user?._id}`, {
          password: val.password, 
          confirmPasword: val.confirmPassword
        })
        .then(() => {
          form1.resetFields()
          setPasswordChange(false)
          window.location.reload()
        })
        .catch(e => {
          console.log(e);
        })
      
      }

    return (

        <div className="registration update-user">
            <div className="flex max-md-mb-3 max-sm-block">
                <p className="page-title relative pr-4 mr-4 font-medium mb-2">
                    Edit Profile
                </p>
                <Breadcrumb
                    items={[
                        {
                            title: "Home",
                        },
                        {
                            title: "My Profile",
                        },
                    ]}
                />
            </div>
            <FormModal 
                buttonText="Save" 
                form={form1} 
                isModalOpen={passwordChange}
                title="Reset Password"
                loading={isLoading}
                handleCancel={() => setPasswordChange(false)}
                handleForm={resetPasswordHandle}
                width={400}
                >
                <InputBox name={'password'} rules={[
                    {
                    required: true,
                    message: "Please enter password!",
                    },
                ]} 
                placeholder="Enter Password"
                type="password"
                />
                <InputBox name={'confirmPassword'} rules={[
                {
                    required: true,
                    message: "Please enter confirm password!",
                },
                ({ getFieldValue }:any) => ({
                    validator(_:any, value:any) {
                    if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        new Error(
                        "The two passwords that you entered do not match!"
                        )
                    );
                    },
                }),
                ]}
                placeholder="Re-Enter Password"
                type="password"
                />
            </FormModal>

            <Form onFinish={onFinish} className="registration-form" layout="vertical" form={form}>
                <div className="upload-profile-image">
                    <Flex justify="space-between" className="form-part">
                        <div>
                            <Form.Item name="upload-profile">
                                <FileUpload onChange={onChange} placeholder={<UserOutlined
                                    style={{ fontSize: "3rem", opacity: "0.5" }}
                                />} setFile={setFileList} fileData={fileList} />
                            </Form.Item>
                            <p>Maximum file size should be 1 MB</p>
                        </div>
                    </Flex>
                </div>

                <Divider />

                <div className="Owners-Authentication-Details">
                    <Flex justify="space-between" className="form-part">
                        <div>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <InputBox
                                        label="First Name"
                                        name="firstName"
                                        style={{ width: "100%" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter first name!",
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputBox
                                        label="Last Name"
                                        name="lastName"
                                        style={{ width: "100%" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter last name!",
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <ContactInput form={form} name="ownerPhoneNumber" label="Phone Number" />
                                </Col>
                                <Col span={12}>
                                    <InputBox
                                        label="Email"
                                        name="ownerEmail"
                                        style={{ width: "100%" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter email address!",
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <span style={{ border: "1px solid #DFE1E5" }}></span>
                        <div>
                            <Flex gap={"middle"}>
                            <Form.Item
                                style={{ width: "100%" }}
                                label="Current Password"
                            >
                                <Input.Password
                                    size="large"
                                    value={password}
                                    className="mb-1"
                                    />
                                <Flex justify="end">
                                    <Button style={{padding: 0}} type="link" onClick={() => setPasswordChange(true)}>Reset Password</Button>
                                </Flex>
                                </Form.Item>
                            </Flex>
                        </div>
                    </Flex>
                </div>

                <Flex className="mt-5" justify="flex-end">
                    <Button htmlType="submit" type="primary">
                        Submit
                    </Button>
                </Flex>
            </Form>
        </div>
    );
};
const mapStateToProps = ({ login, loading }: RootState) => {
    const { user } = login;
    const {isLoading} =loading

    return { user, isLoading }
}
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
