import './App.css';
// import routes from './routes';
import "./css/index.scss";
import Layout from "./component/Layout";
import AdminLayout from "./component/AdminLayout";
import ManageRequest from "./pages/ManageRequest";
import { Routes, Route, Navigate, useLocation, Link} from "react-router-dom"
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import ProtectedRoute from './security/ProtectedRoute';
import Home from './pages/Home';
import { LocalStorageService } from './utils/LocalStorage';
import { useEffect, useState } from 'react';
import AxiosService from './utils/APIService';
import { connect } from 'react-redux';
import {isLogin, onLogin, setWalletAmount, setSwitchVal, setPermission, setAdminPermission} from "./action"
import Pricing from './pages/Pricing';
import UpdateUser from './pages/Updateuser';
import VisaRequest from './pages/VisaRequest';
import Transaction from './pages/Transaction';
// import Registration from './pages/Registration';
import Agent from './pages/Agent';
import Permission from './pages/Permission';
import UserManage from './pages/UserManage';
import FormDetail from './pages/FormDetail';
import AdminPermission from './pages/AdminPermission';
import TransactionDetail from './pages/TransactionDetail';
import AddClient from './pages/AddClient';
import { RootState } from './reducer';
import ClientList from './pages/ClientList';
import { AppPropsInterface } from './interface';
import { setCurrentRoute } from './action/routerAction';
import { Modal, Spin, Typography } from 'antd';
import SubAdmin from './pages/SubAdmin';
import { ROLE } from './consts';
import Component from './component/FileUpload';
import Profile from './pages/Profile';
import EditProfile from './pages/EditProfile';
import NotFound from './pages/NotFound';
import ApplicationDetail from './pages/ApplicationDetail';
import CustomerRegistration from './pages/CustomerRegistration';
import CrmLayout from './component/CrmLayout';
import CRMTasks from './pages/CRMTasks';
import ApplicationForm from './pages/ApplicationForm';

function App({
  isLogin,
  onLogin,
  setSwitchVal,
  setWalletAmount,
  setPermission,
  setAdminPermission,
  user
}:AppPropsInterface) {
  const [appLoading, setappLoading] = useState(false);
  const [profileModal, setProfileModal] = useState(false)
  const location = useLocation();
  const navigationTo = () => {
    const userData = LocalStorageService.getItem("userData");
    const token = LocalStorageService.getItem("userToken")
    if(userData && token){
      if(userData.role === ROLE.admin){
        return "/agent"
      }
      return "/home"
    }
    return "/login"
  }
  
  const axiosService:any = new AxiosService();
  useEffect(() => {
    const token = LocalStorageService.getItem("userToken")
    if (token) {
      checkAuthentication()
    } else {
      isLogin(false)
      onLogin(null)
    }
  }, []);
  const checkAuthentication = async () => {
    setappLoading(true)
    axiosService.get("/auth/me", {}, false).then((res:any) => {
      setappLoading(false)
      isLogin(true)
      onLogin(res.user)
      setWalletAmount(res.mainBalance);
      setSwitchVal(res.autoSubmit)
      if(res.user){
        LocalStorageService.setItem("userData", res.user)
        if(res.user.role === ROLE.subAgent){
          setPermission(res.permission)
        }
        if(res.user.role === ROLE.subAdmin){
          setAdminPermission(res.permission)
        }
      }
      
    })
    .catch(() => {
      isLogin(false)
      onLogin(null)
      LocalStorageService.removeItem("userToken")
      LocalStorageService.removeItem("userData")
      setappLoading(false)
    })
  }

  useEffect(() => {
    let urlList = ['/home', '/visa-request', '/transaction-detail', '/form-detail', '/manage-user', '/permission', '/profile']
    let isUpdate = user?.is_profile_updated == undefined ? true : user?.is_profile_updated
    
    if(!isUpdate && urlList.includes(location.pathname) && user?.role === "AGENT"){
      setProfileModal(true)
    }else{
      setProfileModal(false)
    }
    setCurrentRoute(location.pathname)
    
  }, [location, user]);

  return (
    <>{appLoading && <div className='loader'><Spin /></div>}
        <Modal closable={false} footer={[]} title="Update Your Profile" visible={profileModal}>
          <Typography.Paragraph>To continue using our services, please update your profile. Without updating your profile, you will not be able to create new applications, add balance, or perform any other actions on this platform.</Typography.Paragraph>
          <Typography.Paragraph>For Update Profile, <Link to="/update-profile">Click Here</Link></Typography.Paragraph>
        
        </Modal>
      <Routes>

        {/* open routes */}
        <Route path='/registration'  Component={CustomerRegistration} />
        {/* <Route path='/registration-old'  Component={Registration} /> */}
        <Route path='/resetpassword' Component={ResetPassword} />
        <Route path='/login' Component={Login} />
        <Route path='/compo' element={<Component />} />

        {/* agent Route */}
        <Route path='/home' element={<ProtectedRoute layout={Layout} component={Home} role='agent'/>}  />  
        <Route path='/visa-request' element={<ProtectedRoute layout={Layout} component={ManageRequest} role='agent'/>}  />
        <Route path='/transaction-detail'  element={<ProtectedRoute layout={Layout} component={TransactionDetail} role='agent'/>}  />
        <Route path='/form-detail'  element={<ProtectedRoute layout={Layout} component={FormDetail} role='agent'/>}  />
        <Route path='/manage-user'  element={<ProtectedRoute layout={Layout} component={UserManage} role='agent'/>}  />
        <Route path='/permission'  element={<ProtectedRoute layout={Layout} component={Permission} role='agent'/>}  />
        <Route path='/update-profile' element={<ProtectedRoute layout={Layout} component={UpdateUser} role='agent'/>}  />
        <Route path='/profile' element={<ProtectedRoute layout={Layout} component={Profile} role='agent'/>} />
        <Route path='/profile-edit' element={<ProtectedRoute layout={Layout} component={EditProfile} role='agent'/>} />
        <Route path='/create-application' element={<ProtectedRoute layout={Layout} component={ApplicationForm} role='agent'/>} />
        
        {/* Admin Routs */}
        <Route path='/pricing'  element={<ProtectedRoute layout={AdminLayout} component={Pricing} role='adnin'/>}  />
        <Route path='/agent'  element={<ProtectedRoute layout={AdminLayout} component={Agent} role='admin'/>}  />
        <Route path='/agent-permission'  element={<ProtectedRoute layout={AdminLayout} component={Permission} role='admin'/>}  />
        <Route path='/update-user' element={<ProtectedRoute layout={AdminLayout} component={UpdateUser} role='admin'/>}  />
        <Route path='/admin-permission'  element={<ProtectedRoute layout={AdminLayout} component={AdminPermission} role='admin'/>}  />
        <Route path='/transaction'  element={<ProtectedRoute layout={AdminLayout} component={Transaction} role='admin'/>}  />
        <Route path='/update-aplication'  element={<ProtectedRoute layout={AdminLayout} component={FormDetail} role='admin'/>}  />
        <Route path='/visa' element={<ProtectedRoute layout={AdminLayout} component={VisaRequest} role='admin' />} />
        <Route path='/team' element={<ProtectedRoute layout={AdminLayout} component={SubAdmin} role='admin' />} />
        <Route path='/adminprofile' element={<ProtectedRoute layout={AdminLayout} component={Profile} role='admin'/>} />
        <Route path='/edit-adminprofile' element={<ProtectedRoute layout={AdminLayout} component={EditProfile} role='admin'/>} />
        <Route path='/application-details' element={<ProtectedRoute layout={AdminLayout} component={ApplicationDetail} role="admin" />} />
        
        <Route path='/crm/*' element={<ProtectedRoute layout={AdminLayout} component={CrmLayout} role='admin'/>}>
          <Route index element={<Navigate to={'client-list'} />}  />
          <Route path='add-client'  element={<AddClient/>}  />
          <Route path='client-list'  element={<ClientList />}  />
          <Route path='task-list' element={<CRMTasks />} />
        </Route>
        
        <Route path='/' element={ <Navigate to={navigationTo()} />} />
        <Route path='*' element={ <NotFound />} />
      </Routes>
    </>
  );
}
const mapStateToProps = ({loading, login, currentRouteState}: RootState) => {
  const {
    isLoading
  } = loading
  const {user} = login
  const {currentRoute} = currentRouteState
  return {
    isLoading,
    user,
    currentRoute
  }
}
const mapDispatchToProps = {
  onLogin,
  isLogin,
  setWalletAmount,
  setSwitchVal,
  setCurrentRoute,
  setPermission,
  setAdminPermission
}
export default connect(mapStateToProps, mapDispatchToProps)(App);