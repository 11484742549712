import countries from "./data.json"
export const getCountryNameByCountryCode = (code:string) => {
    return countries.find(ctr => ctr.code === code)?.name
}

export const getCountryCodeByCountryName = (name:string) => {
    return countries.find(ctr => ctr.name === name)?.code
}

export interface countryInterface {
    name: String,
    code: String,
    unicode: String,
    dial_code: String,
    image: String
}