import React from "react";
import { Button, Modal, Form } from "antd";

interface FormModalInterface{
  title:string;
  width:number;
  form:any;
  children:any;
  footerElement?:any;
  buttonText:string;
  isModalOpen:boolean;
  handleCancel():void;
  handleForm(val:any):void;
  loading: boolean
}

const FormModal = ({
  title,
  isModalOpen,
  handleCancel,
  width,
  handleForm,
  form,
  children,
  footerElement,
  buttonText,
  loading
}: FormModalInterface) => {

  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[]}
        className="customodal"
        width={width}
      >
        <Form
          onFinish={handleForm}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <div className="form-body">
            {children}
          </div>

          <div className="form-footer">
            <div>
              {footerElement}
            </div>
            <Button
              type="primary"
              size="large"
              style={{ minWidth: "100px" }}
              htmlType="submit"
              loading={loading ? loading : false}
            >
              {buttonText}
            </Button>

          </div>
        </Form>
      </Modal>
    </>
  );
};

export default FormModal;
