import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import AxiosService from '../utils/APIService'
import { Breadcrumb, Button, Card, Col, Descriptions, Flex, Image, Row, Space, Typography } from 'antd'
import getAppDocLink from '../utils/getAppDocLink'
import moment from 'moment'
import { ArrowLeftOutlined } from '@ant-design/icons'
import downloadDocument from '../utils/docDownloader'

function ApplicationDetail() {
    const [searchParams] = useSearchParams()
    const appId = searchParams.get("id")
    const [appData, setAppData] = useState<any>(null)
    const axiosService = new AxiosService()
    const navigate = useNavigate()

    useEffect(() => {
        document.body.classList.add("body-bg");
        if(appId) fetchApplicationData(appId)
    }, []);
    const fetchApplicationData = (id:string) => {
        axiosService.get(`/app/get-by-id/${id}`)
        .then(async (res:any) => {
            const data = res.data

            data.passport_detail.front_image_url = await getAppDocLink(data?.app_id, data.passport_detail.front_image)
            data.passport_detail.back_image_url = await getAppDocLink(data?.app_id, data.passport_detail.back_image)
            data.traveller_img_url = await getAppDocLink(data?.app_id, data.traveller_img)
            
            if(data.id_img){
                data.id_img_url = await getAppDocLink(data?.app_id, data.id_img)
            }
            if(data.extra_doc.length > 0){
                let urlList = []
                for (let i = 0; i < data.extra_doc.length; i++) {
                    const element = await getAppDocLink(data?.app_id, data.extra_doc[i]);
                    urlList.push(element)
                }
                data.extra_doc_url = urlList
            }
            
            setAppData(data)
        })
        .catch((e:any) => {
            console.log(e);
        })
    }

    const downloadDocs = (docName:string, id:string) => {
        axiosService.get(`/app/get-doc/${id}/${docName}`)
            .then((res:any) => {
                downloadDocument(res, docName)
            })
            .catch((error) => {
                console.error('Error downloading file:', error);
            });
    }
    return (
        <div className="container">
            <div className="flex max-md-mb-3 max-sm-block">
                    <p className="page-title relative pr-4 mr-4 font-medium mb-2">Visa Details</p>
                    <Breadcrumb
                        items={[
                            {
                                title: 'Visa',
                                path: "/visa"
                            },
                            {
                                title: 'Visa Details',
                            },
                        ]}
                        itemRender={(data:any) => {
                            if(data.path){
                                return <Link to={data.path}>{data.title}</Link>
                            }
                            return data.title
                        }}
                    />
            </div>
            <Card>
                <div className='mb-6'>
                    <Link to={"/visa"}><ArrowLeftOutlined /> Go Back</Link>
                </div>
                <Row gutter={16} className='mb-6'>
                    <Col span={12}>
                        <Descriptions title="Visa Information" bordered>
                            <Descriptions.Item span={3} label={"Passport no."} labelStyle={{
                                width: "200px"
                            }}>
                                <Typography.Text copyable={{ text: appData?.passport_detail?.passport_number }} /> {appData?.passport_detail?.passport_number}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Date Of Birth"}>
                            <Typography.Text copyable={{ text: moment(appData?.passanger_detail?.date_of_birth).format("DD-MM-YYYY") }} /> {moment(appData?.passanger_detail?.date_of_birth).format("DD-MM-YYYY")}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Gender"}>
                                <Typography.Text copyable={{ text: appData?.passanger_detail?.gender }} /> &nbsp;
                                {appData?.passanger_detail?.gender}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Marital status"}>
                                <Typography.Text copyable={{ text: appData?.passanger_detail?.marital_status }} /> &nbsp;
                                {appData?.passanger_detail?.marital_status}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Passport Issue Date"}>
                                <Typography.Text copyable={{ text: moment(appData?.passport_detail?.issue_date).format("DD-MM-YYYY") }} /> &nbsp;
                                {moment(appData?.passport_detail?.issue_date).format("DD-MM-YYYY")}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Passport Expiry Date"}>
                                <Typography.Text copyable={{ text: moment(appData?.passport_detail?.expire_date).format("DD-MM-YYYY") }} /> &nbsp;
                                {moment(appData?.passport_detail?.expire_date).format("DD-MM-YYYY")}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Birth place"}>
                                <Typography.Text copyable={{ text:appData?.passanger_detail?.birth_place }} /> &nbsp;
                                {appData?.passanger_detail?.birth_place}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"First name"}>
                                <Typography.Text copyable={{ text: `${appData?.passanger_detail?.name?.first_name} ${appData?.passanger_detail?.name?.middle_name && appData?.passanger_detail?.name?.middle_name} ${appData?.passanger_detail?.name?.last_name && appData?.passanger_detail?.name?.last_name}` }} /> &nbsp;
                                {appData?.passanger_detail?.name?.first_name} {appData?.passanger_detail?.name?.middle_name && appData?.passanger_detail?.name?.middle_name} {appData?.passanger_detail?.name?.last_name && appData?.passanger_detail?.name?.last_name}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Last name"}>
                                <Typography.Text copyable={{ text: appData?.family_detail?.father_name }} /> &nbsp;
                                {appData?.family_detail?.father_name}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Passport Issue Place"}>
                                <Typography.Text copyable={{ text: appData?.passport_detail?.issue_city }} /> &nbsp;
                                {appData?.passport_detail?.issue_city}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Father Name"}>
                                <Typography.Text copyable={{ text: `Mr.` }} /> &nbsp;
                                Mr.
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Mother Name"}>
                                <Typography.Text copyable={{ text: `Mrs.` }} /> &nbsp;
                                Mrs.
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Address"}>
                                <Typography.Text copyable={{ text: appData?.address_detail?.city }} /> &nbsp;
                                {appData?.address_detail?.city}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Language"}>
                                <Typography.Text copyable={{ text: appData?.passport_detail?.language }} /> &nbsp;
                                {appData?.passport_detail?.language}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Religion"}>
                                <Typography.Text copyable={{ text: appData?.passanger_detail?.religion }} /> &nbsp;
                                {appData?.passanger_detail?.religion}
                            </Descriptions.Item>
                            <Descriptions.Item span={3} label={"Profession"}>
                                <Typography.Text copyable={{ text: appData?.passanger_detail?.profession }} /> &nbsp;
                                {appData?.passanger_detail?.profession}
                            </Descriptions.Item>
                            {appData?.passanger_detail?.gender === 'FEMALE' && appData?.passanger_detail?.marital_status === "MARRIED" && 
                                <Descriptions.Item span={3} label={"Spouse Name"}>
                                    <Typography.Text copyable={{ text: `Mrs. ${appData?.family_detail?.spouse_name}` }} /> &nbsp;
                                    Mr. {appData?.family_detail?.spouse_name}
                                </Descriptions.Item>
                            }
                        </Descriptions>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[12, 12]} className='mb-3'>
                            <Col span={24}>
                                <Typography.Title level={5}>Passport Front</Typography.Title>
                                <Image preview={false} onClick={() => downloadDocs(appData?.passport_detail?.front_image, appData.app_id)} className='prev-app-img' src={appData?.passport_detail?.front_image_url} width={"100%"} />
                            </Col>
                            <Col span={24}>
                                <Typography.Title level={5}>Passport Back</Typography.Title>
                                <Image preview={false} onClick={() => downloadDocs(appData?.passport_detail?.back_image, appData.app_id)} className='prev-app-img' src={appData?.passport_detail?.back_image_url} width={"100%"} />
                            </Col>
                            <Col span={12}>
                                <Typography.Title level={5}>Traveller Image</Typography.Title>
                                <Image preview={false} onClick={() => downloadDocs(appData?.traveller_img, appData.app_id)} className='prev-app-img' src={appData?.traveller_img_url} width={"100%"} />
                            </Col>
                            {console.log("appData?.id_img_url", appData?.id_img_url)}
                            {appData?.id_img && <Col span={12}>
                                <Typography.Title level={5}>ID Image</Typography.Title>
                                <Image preview={false} onClick={() => downloadDocs(appData?.id_img, appData.app_id)} className='prev-app-img' src={appData?.id_img_url} width={"100%"} />
                            </Col>}
                        </Row>
                        {appData?.extra_doc?.length > 0 && <>
                            <Typography.Title level={5}>Extra Documents</Typography.Title>
                            <Space>
                                {appData?.extra_doc_url?.map((u:string, i:number)=> <Image preview={false} onClick={() => downloadDocs(appData?.extra_doc[i], appData.app_id)} src={u} width={100} />)}
                            </Space>
                        </>}
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default ApplicationDetail
