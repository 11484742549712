import React, { useEffect, useState } from 'react'
import AxiosService from '../utils/APIService'
import { UserInterface } from '../interface'

function useUserData(id:string | null) {
    
    const [userData, setUserData] = useState<UserInterface | null>(null)
    const axiosService = new AxiosService()
    useEffect(() => {
        if(id){
            fetchUserData(id)
        }
    }, [id])
    

    const fetchUserData = (userId:string) => {
        axiosService.get(`/user/user/${userId}`)
            .then((res:any) => {
                setUserData(res.data)
            })
            .catch(e => console.log(e))
    }
    return {
        userData,
        fetchUserData
    }
}

export default useUserData
