import React, { useEffect, useState } from 'react'
import AxiosService from '../utils/APIService'

function useTeam() {
    const [memberList, setMemberList] = useState([])
    const axiosService = new AxiosService()
    
    useEffect(() => {
        fetchTeamMember()
    }, [])

    const fetchTeamMember = () => {
        axiosService.get('/sub-admin/list')
            .then((res:any) => {
                setMemberList(res.data)
            })
            .catch((e:any) => console.log(e))
    }
    

    return {memberList}
}

export default useTeam
