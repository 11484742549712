import { SET_FORM_DATA } from "../action/multipleFormData";

export interface FormDataState {
    formData: any;
}
const initialState:FormDataState = {
    formData: null,
};

const formDataReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };

    default:
      return state;
  }
};

export default formDataReducer;
