import React, { useEffect, useState } from 'react'
import AxiosService from '../utils/APIService'

export default function useTypeList() {
    const axiosService = new AxiosService()
    const [typeList, setTypeList] = useState<any>([])

    useEffect(() => {
        fetchTypes()
    }, []);

    const fetchTypes = () =>{
        axiosService.get('/price/get')
        .then((res:any) => {
            let visaType = res.data.map((data:any) => ({
                value: data._id,
                label: data.title,
                data: data
            }))
            setTypeList(visaType)
        })
        .catch((e:any) => {
            console.log(e)
        })
    }

    return {
        typeList,
        fetchTypes
    }
}
