import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "flag-icons/css/flag-icons.min.css";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import rootReducer from './reducer';
import {thunk} from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { ConfigProvider } from 'antd';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const antdTheme = {
  components: {
    Segmented: {
      itemSelectedBg: "#F8F9FB",
      itemSelectedColor: "#1A73E8",
      itemHoverBg: "transparent",
      itemHoverColor: "#1A73E8",
      trackBg: "transparent",
      itemActiveBg: "#F8F9FB"
    },
  },
}
export const store = createStore(rootReducer, applyMiddleware(thunk));
root.render(
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider
          theme={antdTheme}
        >
          <App />
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
