import { Segmented } from "antd";
import { Outlet, useNavigate } from "react-router-dom";


const CrmLayout = ()=>{
    const navigate = useNavigate()
    return(
        <>
            <div style={{
                backgroundColor: "#fff",
                padding: "12px 0px",
                marginTop: "-1.25rem",
                marginBottom: "1.25rem",
                borderBottom: "1px solid #DFE1E5"
            }}>
                <div className="container">
                    <Segmented
                        className="custome-segment"
                        size="large"
                        options={[
                            {
                                value: "/",
                                label: "Home"
                            }, {
                                value: "/crm/client-list",
                                label: "Clients List"
                            }, {
                                value: "/crm/task-list",
                                label: "Task List"
                            }]}
                        onChange={(value:any) => {
                            navigate(value)
                        }}
                    />
                </div>
            </div>
            <Outlet />
        </>
    );
}

export default CrmLayout;