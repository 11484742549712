import {SET_VISA_PARAMS} from "../action/visaReqAction"

export interface ReqParamsState {
    reqParams: any; // Define a proper type for the user property
}
const initialState:ReqParamsState = {
    reqParams: null
}

const visaReqReducer = (state = initialState, action:any) =>{
    switch (action.type) {
        case SET_VISA_PARAMS:
            return {
                ...state,
                reqParams: action.payload
            }

        default:
            return state;
    }
}

export default visaReqReducer
