import {SET_SWITCH_VAL} from "../action/autoSubmitAction"

export interface SwitchState {
    switchVal: boolean;
}
const initialState:SwitchState = {
    switchVal: false
}

const autoSubmitReducer = (state = initialState, action:any) =>{
    switch (action.type) {
        case SET_SWITCH_VAL:
            return {
                ...state,
                switchVal: action.payload
            }

        default:
            return state;
    }
}

export default autoSubmitReducer