import { Form, Input, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
interface PropsInterface {
    style?: React.CSSProperties
    placeholder?: string;
    isUppercase?: boolean;
    label?: string;
    name: any;
    rules?: any;
    type?: "number" | "text" | "password" | "textarea";
    className?: string;
    autoSize?:object;
    dependencies?:any;
    disabled?:boolean;
}
const InputBox = ({
    placeholder,
    isUppercase,
    type,
    autoSize,
    disabled,
    ...rest
}: PropsInterface) => {
    
    const toUppercase = (e: any) => {
        return e.target.value = e.target.value.toUpperCase()
    }
    return (
        <>
            <Form.Item {...rest}>
                {type == "number" ? <InputNumber disabled={disabled} placeholder={placeholder ? placeholder : `Enter ${rest.label}`} size='large' />:
                type == "password" ? <Input.Password disabled={disabled} placeholder={placeholder ? placeholder : `Enter ${rest.label}`} size='large' />:
                type == "textarea" ? <Input.TextArea disabled={disabled} placeholder={placeholder ? placeholder : `Enter ${rest.label}`} autoSize={autoSize} size='large' />:
                    <Input placeholder={placeholder ? placeholder : `Enter ${rest.label}`} onInput={(e: any) => {
                        return isUppercase ? toUppercase(e) : e.target.value
                    }} size="large"disabled={disabled} />
                }
            </Form.Item>
        </>
    )
}

export default InputBox