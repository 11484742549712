import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Form, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AxiosService from "../utils/APIService";
import { getCountryNameByCountryCode } from "../country";
import CountryFlag from 'react-country-flag';
import { RootState } from "../reducer";
import { setVisaParams } from "../action";
import { connect } from "react-redux";   
import Export from "../component/Export";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";

const Home = () => {
    
    const axiosService:any = new AxiosService();
    const navigate = useNavigate()
    const [dashboardData, setDashboardData] = useState([]);
    useEffect(() => {
        document.body.classList.remove('body-bg');
        fetchDashboard();
    }, []);

    const fetchDashboard = () => {
        axiosService.get('/dashboard/get')
            .then((res:any) => { 
                setDashboardData(res);
            })
            .catch(() => {
                setDashboardData([]);
            });
    }
    const redirecList = (country:string) => {
        navigate(`/visa-request?contry=${country}`)
    }
    const exportToCSV = () => {   
        const header = ["Country", "No. of Application", "Done","Pending", "Processing", "Rejected"];
        const csvRows = [];
        csvRows.push(header.join(','));
        dashboardData.forEach((item:any) => {
            const row = [
                getCountryNameByCountryCode(item.country),
                item.totalReq,
                item.complete,
                item.pending,
                item.processing,
                item.rejected
            ];
            csvRows.push(row.join(','));
        });
        const csvData = csvRows.join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "countrydata.csv");
        link.click();
    };
    const exportToPDF = () => {
        const header = ["Country", "No. of Application", "Done",   "Pending", "Processing", "Rejected"];
        let dashboardDataListArray = dashboardData.map((item:any) => [
            getCountryNameByCountryCode(item.country),
                item.totalReq,
                item.complete,
                item.pending,
                item.processing,
                item.rejected
        ]);
        const doc = new jsPDF();
        doc.setFontSize(10);
        autoTable(doc, {
            head: [header],
            body: dashboardDataListArray,
          });
        doc.save('countrydata.pdf');
    };
    return (
    <>
        <div className="container">
            <div className="flex justify-between items-center mb-4 page-head">
                <div className="flex max-md-mb-3 page-head-wrapper">
                    <p className="page-title relative pr-4 mr-4 font-medium mb-2">Country Wise Data</p>
                    <Breadcrumb
                        items={[
                            {
                                title: 'Home',
                            },
                            {
                                title: 'Visa',
                                path: "/visa-request"
                            },
                            {
                                title: 'Country Wise Data',
                            },
                        ]}
                        itemRender={(data:any) => {
                            if(data.path){
                                return <Link to={data.path}>{data.title}</Link>
                            }
                            return data.title
                        }}
                    />
                </div>
                <div>
                    <Space size={"middle"} className="btn-space">
                        <Button icon={<FilterOutlined />} className="max-sm-mb-2 max-sm-w-full">Filter</Button>
                        <Export
                            exportToCsv={exportToCSV}
                            exportToPdf={exportToPDF}
                        />
                    </Space>
                </div>
            </div>
            <ul>
                {dashboardData.map((d:any, index) => {
                    return (<li className="mb-4" key={index}>
                    <div
                        className="pt-7 max-sm-pt-4 pl-4 pr-4 pb-7 grid gap-4 overflow-auto justify-between"
                        style={{
                            backgroundColor: "#F8F9FB",
                            borderRadius: "12px",
                            gridTemplateColumns: "minmax(250px,370px) minmax(121px,121px) minmax(60px,60px) minmax(60px,60px) minmax(60px,60px) minmax(60px,60px) minmax(90px,90px)"

                        }}
                    >
                        <div className="flex items-center">
                            <div 
                                className="rounded-full mr-5"
                                style={{
                                    height: "45px",
                                    width: "45px"
                                }}
                            >
                                <CountryFlag style={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '50%',
                                    objectFit: "cover"
                                }} countryCode={d.country} svg />
                            </div>
                            <h2 className="text-xl line-clamp-1 font-medium max-md-text-base">{getCountryNameByCountryCode(d.country)}</h2>
                        </div>
                        <div className="text-center">
                            <span className="font-medium text-lg">{d.totalReq}</span>
                            <p className="text-sm" style={{
                                color: "#5F617A"
                            }}>No.of Application</p>
                        </div>
                        <div className="text-center">
                            <span className="font-medium text-lg" style={{color: "#24C141"}}>{d.complete}</span>
                            <p className="text-sm" style={{color: "#5F617A"}}>Done</p>
                        </div>
                        <div className="text-center">
                            <span className="font-medium text-lg" style={{color: "#F98500"}}>{d.pending}</span>
                            <p className="text-sm" style={{color:"#5F617A"}}>Pending</p>
                        </div>
                        <div className="text-center">
                            <span className="font-medium text-lg" style={{color: "#F98500"}}>{d.processing}</span>
                            <p className="text-sm" style={{color:"#5F617A"}}>Processing</p>
                        </div>
                        <div className="text-center">
                            <span className="font-medium text-lg" style={{color:"#FF4D49"}}>{d.rejected}</span>
                            <p className="text-sm" style={{color:"#5F617A"}}>Rejected</p>
                        </div>
                        <div className="flex items-center justify-center">
                            <Button onClick={() => redirecList(d.country)} type="primary" style={{background: "#1A73E81A", color: "#1A73E8"}}>View</Button>
                        </div>
                    </div>
                </li>)
                })}
            </ul>
        </div>
    </>
    )
}
const mapStateToProps = ({loading, login}:RootState) => {
    const {isLoading} = loading
    const {user, permission} = login
    return {isLoading, user, permission}
}
const mapDispatchToProps = {
    setVisaParams,
}
export default connect(mapStateToProps, mapDispatchToProps) (Home);