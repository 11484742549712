const cryptoJs = require("crypto-js");
export const decryptPassword = (hashedPassword:string, salt:string) => {
    if (!hashedPassword) return "";
    try {
        const pswrd:string =  cryptoJs.AES.decrypt(hashedPassword, salt).toString(cryptoJs.enc.Utf8);

        return pswrd;
    } catch (error) {
        return ""
    }
}

export const decryptSalt = (salt:any) => {
    try {
        const pswrd:string =  cryptoJs.AES.decrypt(salt, process.env.REACT_APP_PRIVATE_KEY).toString(cryptoJs.enc.Utf8);
        return pswrd;
    } catch (error) {
        return ""
    }
}