    import { FilterOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
    import { Breadcrumb, Button, Col, DatePicker, Card, Form, InputNumber, Row, Space, message, Alert, Input, Select, Dropdown, MenuProps } from "antd";
    import Table from "antd/es/table";
    import { useState } from "react";
    import { useEffect } from 'react';
    import AxiosService from "../utils/APIService";
    import { connect } from "react-redux";
    import { setWalletAmount } from "../action";
    import { RootState } from "../reducer";
    import moment from "moment";
    import FormModal from "../component/FormModal";
    import Column from "antd/es/table/Column";
    import { ROLE } from "../consts";
    import jsPDF from 'jspdf';
    import autoTable from 'jspdf-autotable';
import Export from "../component/Export";
import { InputBox } from "../component/FormElements";
    const { RangePicker } = DatePicker;

    const TransactionDetail = ({ balance, setWalletAmount, isLoading, user, permission, isChanged }: {
        balance?: number,
        setWalletAmount?: any,
        isLoading: boolean,
        user:any,
        permission:any,
        isChanged:boolean
    }) => {
        const axiosService = new AxiosService()
        const [transactionList, setTransactionList] = useState([])
        const [amount, setAmount] = useState(0)
        const [filter , setFilter] = useState<any>()
        const [isDrawerOpen, setIsDrawerOpen] = useState(false);
        const [searchString, setSearchString] = useState("");
        const [form] = Form.useForm()
        const [size , setSize] = useState<number>()
        const [tableParams, setTableParams] = useState<any>({
            pagination: {
              current: 1,
              pageSize: 10,
            },
          });
        useEffect(() => {
            document.body.classList.add('body-bg');
        }, []);
        const [isModalOpen, setIsModalOpen] = useState(false);

        const checkPermissionForTableRow = (key:string) => {
            const per = permission?.BALANCE?.find((data:any) => data.key === key)
            return (user?.role === ROLE.subAgent && per?.allow) ||  user?.role !== ROLE.subAgent
        }

        const showModal = () => {
            setIsModalOpen(true);
        };

        const handleCancel = () => {
            setIsModalOpen(false);
        };

        const handleWallet = (val: any) => {
            axiosService.post('/user/add-amount', {
                amount: val.balance
            })
                .then((res: any) => {
                    setWalletAmount(res.amount)
                    setIsModalOpen(false)
                    fetchTransactionDetail({page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize})
                    form.resetFields()
                })
                .catch((e) => {
                    console.log(e);
                })
        }

        useEffect(() => {
            fetchTransactionDetail({startDate:filter?.startDate, endDate:filter?.endDate , desc:filter?.desc , search:filter?.search,page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize})
        }, [tableParams , filter, isChanged])

        const fetchTransactionDetail = ({ startDate, endDate , desc , search, page, pageSize }: {
            startDate?: string,
            endDate?: string,
            desc?:string,
            search?:string,
            page:number,
            pageSize:number
        }) => {
            axiosService.get('/transaction/agent', {
                startDate: startDate,
                endDate: endDate,
                desc: desc,
                search: search,
                page:page,
                pageSize:pageSize
            })
                .then((res: any) => {
                    let role = ["ADMIN", "SUB_ADMIN"]
                    const data = res.data.map((d: any) => ({
                        transactionsID: d.transaction_id,
                        date: d.created_at,
                        description: d.description || "",
                        debit: `${(d.debit_amount  || 0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        credit: `${(d.credit_amount|| 0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        balance: `${(d.main_balance|| 0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        name: `${d?.user?.name?.first_name} ${d?.user?.name?.last_name}`,
                        by: role.includes(d.by.role) ? d?.by?.role : d?.by?.name?.first_name
                    }))
                    setTransactionList(data)
                    setSize(res.size)
                })
                .catch((e: any) => {
                    console.log(e);
                })
        }
        const filterHandler = (val: any) => {
            const startDate = val.dateRange?.length > 0 ? moment(val.dateRange[0].toString()).format("DD-MM-YYYY") : undefined
            const endDate = val.dateRange?.length > 0 ? moment(val.dateRange[1].toString()).format("DD-MM-YYYY") : undefined
            val.startDate = startDate;
            val.endDate = endDate
            setFilter(val)
            
        }
        const resetFilter = () => {
            form.resetFields()
            fetchTransactionDetail({page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize})
        
        }
        const onSearchHandler = (e:any) => {
            let searchVal = e.target.value
            setSearchString(searchVal)
        }
        const filterData = (key:string) => {
            const newData = transactionList.filter((agt:any) => agt.transactionsID.toLowerCase().includes(key.toLowerCase()) ||
            agt.transactionsID.toLowerCase().includes(key.toLowerCase()))    
            return newData
        }
  
        const exportToCSV = () => {         
            const header = ["Date", "Name", "Transactions ID",   "Description", "Debit", "Credit", "Balance"];
            const csvRows = [];
            csvRows.push(header.join(','));
            transactionList.forEach((item:any) => {
                const row = [
                    moment(item.date).format("DD-MM-YYYY HH:mm"),
                    item.name,
                    item.transactionsID,
                    item.description,
                    item.debit ? item.debit.replace(/,/g, '') : '',
                    item.credit ? item.credit.replace(/,/g, '') : '',
                    item.balance ? item.balance.replace(/,/g, '') : ''
                ];
                csvRows.push(row.join(','));
            });
            const csvData = csvRows.join('\n');
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "transactions.csv");
            link.click();
        };
        const exportToPDF = () => {     
            const header = ["Date", "Name", "Transactions ID",   "Description", "Debit", "Credit", "Balance"];
            let transactionListArray = transactionList.map((transaction:any) => [
                moment(transaction.date).format("DD-MM-YYYY HH:mm"),
                transaction.name,
                transaction.transactionsID,
                transaction.description,
                transaction.debit,
                transaction.credit,
                transaction.balance
            ]);
            const doc = new jsPDF();
            doc.setFontSize(10);
            autoTable(doc, {
                head: [header],
                body: transactionListArray,
              });
            doc.save('transactions.pdf');
        };
        return (
            <div className="container">
                <FormModal 
                    title="Wallet" 
                    buttonText="Pay"
                    width={397}
                    form={form}
                    handleCancel={handleCancel}
                    handleForm={handleWallet}
                    isModalOpen={isModalOpen}
                    footerElement={
                        <p className="font-medium" style={{ color: "#071437" }}>
                            Pay Amount :&nbsp;
                            <span style={{ color: "#1A73E8" }}>
                            ₹ {`${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                        </p>}
                    loading={isLoading}
                    >
                    <p className="font-semibold mb-5" style={{ color: "#071437", fontSize: 16 }}>
                        Total Balance : ₹{" "}
                        {`${balance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                    <Form.Item
                        name="balance"
                        label="Add Balance"
                        rules={[{ required: true, message: "Please add balance!" }]}
                        >
                        <InputNumber
                            formatter={(value) =>
                            `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{
                            width: "100%",
                            }}
                            size="large"
                            onChange={(val: any) => setAmount(val || 0)}
                        />
                        </Form.Item>
                    </FormModal>
                <div className="flex justify-between items-center mb-4 max-md-block">
                    <div className="flex max-md-mb-3 max-sm-block">
                        <p className="page-title relative pr-4 mr-4 font-medium mb-2">Transactions</p>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: 'Transactions',
                                },
                            ]}
                        />
                    </div>
                    <div>
                        <Space size={"middle"} className="max-sm-block">
                        <Input placeholder="Search..." prefix={<SearchOutlined />} onChange={onSearchHandler} />
                            <Button onClick={() =>  setIsDrawerOpen(!isDrawerOpen)} icon={<FilterOutlined />} className="max-sm-mb-2 max-sm-w-full">Filter</Button>
                        
                            <Export
                                exportToCsv={exportToCSV}
                                exportToPdf={exportToPDF}
                            />
                            
                            {(user?.role === ROLE.subAgent && permission?.ADD_BALANCE.allow) ||  user?.role !== ROLE.subAgent ? <Button onClick={showModal} disabled={!user?.is_profile_updated} type="primary" className="max-sm-w-full" icon={<PlusOutlined />}>Add Balance</Button> : null}
                        </Space>
                    </div>
                </div>
                {
                    isDrawerOpen ?(
                    <Card
                    style={{marginBottom:"1em"}}
                >
                    <Form onFinish={filterHandler} layout="vertical" form={form}>
                        <Row align={'middle'} gutter={16}>
                            <Col lg={5}>
                                <Form.Item label="Request Date" name="dateRange">
                                    <RangePicker />
                                </Form.Item>
                                </Col>
                            
                        
                            <Col lg={5}>    
                                <Form.Item style={{width:"100%"}} label="Description" name="desc">
                                    <Select
                                        allowClear
                                        placeholder="Description"
                                        options={[
                                            {value: "Visa Application", label: "Visa Application"},
                                            {value: "Amount credit to wallet", label: "Amount credit to wallet"},
                                            {value: "Amount Add By Admin", label: "Amount Add By Admin"}
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={5}>    
                                <InputBox style={{width:"100%"}} label="Transaction ID" name="transId" />
                            </Col>
                            
                            <Col >
                            
                            <Button style={{marginTop:".5em" , marginRight:".5em"}} className="max-sm-w-full" type="primary" htmlType="submit">
                                Filter
                            </Button>
                            <Button style={{marginTop:".5em"}} className="max-sm-w-full" type="default" onClick={resetFilter}>
                                Reset Filter
                            </Button>
                            </Col>
                        </Row>

                        <Space>
                        
                        </Space>
                    </Form>
                </Card>
                    ):(null)
                }
                {checkPermissionForTableRow("DATE") ||
                checkPermissionForTableRow("TRANSACTION_ID") ||
                checkPermissionForTableRow("DESCRIPTION") ||
                checkPermissionForTableRow("DEBIT") ||
                checkPermissionForTableRow("CREDIT") ||
                checkPermissionForTableRow("BALANCE") ? (
                    <Table
                        pagination={{
                            showSizeChanger:true,
                            onChange(page, pageSize) {
                                setTableParams({
                                    pagination: {
                                    current: page,
                                    pageSize: pageSize,
                                    },
                                })
                            },
                            total:size
                        }}
                        scroll={{ x: 1219 }}
                        className='border-around'
                        dataSource={searchString ? filterData(searchString) : transactionList}
                        loading={isLoading}
                    >
                        {checkPermissionForTableRow("DATE") && <Column
                            render={(e:any) => (<>
                                <span className="text-sm block mb-1" style={{ color: "#5F617A" }}>{moment(e).format("DD-MM-YYYY HH:mm")}</span>
                            </>)}
                            title="Date"
                            dataIndex="date"
                            key="date"
                        />}
                        <Column
                            title="Name"
                            dataIndex="name"
                            key="name"
                        />
                        {checkPermissionForTableRow("TRANSACTION_ID") && <Column
                            render={(e:any) => (<>
                                <span className="text-sm block mb-1" style={{ color: "#1A73E8" }}>{e}</span>
                            </>)}
                            title="Transactions ID"
                            dataIndex="transactionsID"
                            key="transactionsID"
                        />}
                        {checkPermissionForTableRow("DESCRIPTION") && <Column
                            render={(e) => (<p className="items-center" style={{ color: "#5F617A" }}> {e}</p>)}
                            title="Description"
                            dataIndex="description"
                            key="description"
                        />}
                        <Column
                            render={(e) => (<p className="items-center" style={{ color: "#5F617A" }}> {e}</p>)}
                            title="By"
                            dataIndex="by"
                            key="by"
                        />
                        {checkPermissionForTableRow("DEBIT") && <Column
                            render={(e) => (<p className="items-center" style={{ color: "#FF4D49" , fontWeight:`${e <= 0 ? '700' : 'normal'}` }}> {e <= 0 ? "-" : e}</p>)}
                            title="Debit"
                            dataIndex="debit"
                            key="debit"
                        />}
                        {checkPermissionForTableRow("CREDIT") && <Column
                            render={(e) => (<p className="items-center" style={{ color: "#24C141" , fontWeight:`${e <= 0 ? '700' : 'normal'}` }}> {e <= 0 ? "-" : e}</p>)}
                            title="Credit"
                            dataIndex="credit"
                            key="credit"
                        />}
                        {checkPermissionForTableRow("BALANCE") && <Column
                            render={(e) => (<p className="items-center font-medium" style={{ color: "#1A2A3D" }}> {e}</p>)}
                            title="Balance"
                            dataIndex="balance"
                            key="balance"
                        />}
                </Table>
                ) : <Alert message="You Don't Have Enough Permissions" type="error" />}
                
            </div>
        )
    }
    const mapStateToProps = ({ wallet, loading, login }: RootState) => {
        const { balance, isChanged } = wallet
        const { isLoading } = loading
        const {user, permission} = login
        return { balance, isLoading, user, permission, isChanged }
    }
    const mapDispatchToProps = {
        setWalletAmount
    }
    export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetail);