export const SET_WALLET_AMOUNT = "walletReducer/SET_WALLET_AMOUNT"
export const WALLET_CHANGE_TOGGLE = "walletReducer/WALLET_CHANGE_TOGGLE"

export const setWalletAmount = (val:any) => (disptch:any) =>{
    return disptch({
        type: SET_WALLET_AMOUNT,
        payload: val
    });
}

export const walletChangeEffect = () => (disptch:any) => {
    return disptch({
        type: WALLET_CHANGE_TOGGLE
    })
}