export const PERMISSION_KEY:any = {
    REQUEST_ID: "Request Id",
    PASSENGER: "Passenger",
    VISA_TYPE: "Visa Type",
    REQUEST_STATUS: "Request Status",
    LAST_DAY: "Last Day",
    RATE: "Rate",
    EDIT: "Edit",
    SUBMIT: "Submit",
    NEW_VISA_REQUEST: "New Visa Request",
    DATE: "Date",
    TRANSACTION_ID: "Transaction id",
    COUNTRY: "Country",
    DESCRIPTION: "Description",
    DEBIT: "Debit",
    CREDIT: "Credit",
    BALANCE: "Transaction",
    ADD_BALANCE: "Add Balance",
    VISA_LIST:"Visa List",
    WALLET: "Wallate",
    COMPANY: "Company",
    ACTION: "Action",
    NAME: "Name",
    EMAIL: "Email",
    PASSWORD: "Password",
    STATUS: "Status",
    TYPE: "Type",
    DEBIT_CREDIT: "Debit/Credit",
    VISA:"Visa",
    AGENT:"Agent",
    TRANSACTION:"Transaction",
    PASSANGER:"Passanger",
    AUTO_SUBMIT:"Auto Submit",
    PERMISSION: "Permission",
    AGENT_BALANCE: "Balance",
    TRANSACTION_BALANCE: "Balance",
    TEAM:'Team'
}

export const ROLE:any = {
    admin: "ADMIN",
    subAdmin: "SUB_ADMIN",
    agent: "AGENT",
    subAgent: "SUB_AGENT",
}

export const VISA_TYPE = {
    SINGLE: "Single",
    GROUP: "Group"
}

export const APPLICATION_STATUS:any = {
    TYPING: "Typing",
    TYPED: "Typed",
    INVALID_ENTRIES: "Invalid Entries",
    REVOKED: "Revoked",
    APPLIED: "Applied",
    PROCESSING: "Processing",
    APPROVED: "Approved",
    REJECTED: "Rejected",
    CANCELED: "Canceled",
    EXPIRED: "Expired",
    MOD_REQUIRED: "Modification Required",
    DOC_REQUIRED: "Documents Required",
    ENTERED: "Enterd",
    LEFT: "Left",
}