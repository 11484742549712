import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Space,
  Tooltip,
  message,
} from "antd";
import Table from "antd/es/table";
import Column from "antd/es/table/Column";
import ColumnGroup from "antd/es/table/ColumnGroup";
import { useState } from "react";
import { useEffect } from "react";
import AxiosService from "../utils/APIService";
import TextArea from "antd/es/input/TextArea";
import { connect } from "react-redux";
import { RootState } from "../reducer";
import countryList from "../country/data.json"
import { getCountryNameByCountryCode } from "../country";
import FormModal from "../component/FormModal";
import { UserInterface } from "../interface";
import { ROLE } from "../consts";
import { text } from "stream/consumers";
import { title } from "process";
import { InputBox } from "../component/FormElements";

const EditableItem = ({
  hoveredRow,
  record,
  data,
  dataKey,
  handleSuccess,
}: any) => {
  const apiService = new AxiosService();
  const [editing, setEditing] = useState(false);
  const [inputVal, setinputVal] = useState(data);


  const handleUpdate: any = () => {
    const payload = {
      ...(dataKey === "inr" && { net_rate: { aed: record.aed, inr: inputVal } }),
      ...(dataKey === "aed" && { net_rate: { aed: inputVal, inr: record.inr } }),
      ...(dataKey !== "inr" && dataKey !== "aed" && { [dataKey]: inputVal })
    };
    apiService.put(`/type/update-price/${record.key}`, payload)
      .then((res: any) => {
        setEditing(false)
        handleSuccess(res)
      })
      .catch((e: any) => {
        console.log(e);
      })
  }

  const cancelUpdate = () => {
    setEditing(false)
    setinputVal(data)
  }

  return (
    <>
      {editing ? (
        <>
          <InputNumber onChange={(val) => setinputVal(val)} value={inputVal} className="mr-2" style={{width: "70px"}} />
          <Button
            type="default"
            onClick={handleUpdate}
            icon={<CheckOutlined />}
            size={"small"}
            className="mr-1" />
          <Button
            type="default"
            onClick={cancelUpdate}
            icon={<CloseOutlined />}
            size={"small"} danger />
        </>
      ) : (
        <div className="cell-wrapper">
          {data}
          <Space size="small" className="edit-wrapper">
            {hoveredRow === record.key && (
              <Tooltip title="Edit">
                <Button
                  size="small"
                  className="action-btn mt-1"
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => setEditing(true)}
                />
              </Tooltip>
            )}
          </Space>
        </div>)}
    </>
  )
}

const Pricing = ({ isLoading }: {
  isLoading: boolean;
  adminPermission:any
}) => {
  const apiService = new AxiosService();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);
  const [dataForUpdate, setDataForUpdate] = useState<any>(null);
  const [form] = Form.useForm()
  const [form2] = Form.useForm()

  const [pricingList, setPricingList] = useState([]);
  const selectOption = [
    {
      label: "Adhar Card",
      value: "Adhar Card"
    },
    {
      label: "PAN Card",
      value: "PAN Card"
    },
    {
      label: "Election Card",
      value: "Election Card"
    }]
  useEffect(() => {
    document.body.classList.add("body-bg");
    form.setFieldValue("appliedFromCountry", "IN")
    fetchList()
  }, []);



  const fetchList = () => {
    apiService.get('/type/get').then((res: any) => {
      const priceList = res.map((d: any) => ({
        key: d.country,
        country: d.country,
        data: d.data
      }))
      
      setPricingList(priceList)
      
    })
      .catch(e => {
        console.log("e", e);
      })
  }

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleCancel = () => setIsModalOpen(false)
  const handleRowHover = (record: any) => {

    setHoveredRow(record.key);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  const handleTypeForm = (val: any) => {
    apiService.post('/type/add', {
      title: val.title,
      documents: val.documents,
      note: val.note,
      appliedFrom: val.appliedFromCountry,
      appliedFor: val.appliedForCountry
    })
      .then(() => {
        setIsTypeModalOpen(false)
        form.resetFields();
        fetchList()
      })
      .catch((e) => {
        console.log(e);
        
      })
  }
  const priceDeleteHandle = (row: any) => {
    apiService.delete(`/type/delete/${row.key}`)
      .then(() => {
        fetchList()
      })
      .catch((e: any) => {
        console.log(e)
      })
  }

  const handleEditModel = (rec: any) => {
    setIsModalOpen(true)
    setDataForUpdate(rec)

    form2.setFieldValue('visaType', rec.visaType)
    form2.setFieldValue('agents', rec.allowAgentList)
    form2.setFieldValue('appliedForCountry', rec.appliedForCountry)
    form2.setFieldValue('appliedFromCountry', rec.appliedFromCountry)
    form2.setFieldValue('price', rec.inr)
  }
  const updateFormHandle = (val: any) => {
    if (dataForUpdate && dataForUpdate.key) {
      apiService.put(`/type/update/${dataForUpdate.key}`, {
        appliedFrom: val.appliedFromCountry,
        appliedFor: val.appliedForCountry,
        title: val.visaType,
      })
        .then(() => {
          fetchList()
          setIsModalOpen(false)
        })
        .catch((e: any) => {
          console.log(e);
        })

    }
  }
  const handleSuccess = () => {
    fetchList()
  }
  const expandedRowRender = (rec: any) => {
    const recDoc = rec.data
    const data = [];
    for (let i = 0; i < recDoc.length; ++i) {
      data.push({
        key: recDoc[i]._id,
        visaType: recDoc[i].title,
        aed: recDoc[i].net_rate.aed,
        inr: recDoc[i].net_rate.inr,
        b2b2b: recDoc[i].b2b2b,
        b2b: recDoc[i].b2b,
        b2c: recDoc[i].b2c,
        allowAgentList: recDoc[i].allowAgentList,
        appliedForCountry: recDoc[i].applied_for,
        appliedFromCountry: recDoc[i].applied_from
      });
    }
    return (
      <Table
        dataSource={data}
        pagination={false}
        onRow={(record) => ({
          onMouseEnter: () => handleRowHover(record),
          onMouseLeave: handleRowLeave,
        })}
        className="mb-3 mt-3 mr-3 border-around">
        <Column
          title="Visa Type"
          width={"25%"}
          dataIndex="visaType"
          key="visaType"
          render={(_, record: any) => {
            return (
              <>
                <div className="cell-wrapper">
                  {_}
                  <Space size="small" className="edit-wrapper">
                    {hoveredRow === record.key && (
                      <>
                        <Tooltip title="Edit">
                          <Button
                            size="small"
                            className="action-btn"
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => handleEditModel(record)}
                          />
                        </Tooltip>
                        <Popconfirm
                          title="Are you sure you want to delete this row?"
                          okText="Yes"
                          onConfirm={() => priceDeleteHandle(record)}>
                          
                          <Tooltip title="Delete">
                            <Button
                              size="small"
                              className="action-btn"
                              type="link"
                              icon={<DeleteOutlined />}
                            />
                          </Tooltip>
                        </Popconfirm>
                      </>
                    )}
                  </Space>
                </div>
              </>
            );
          }}
        />
        <ColumnGroup title="Net Rate">
          <Column
            title="AED"
            dataIndex="aed"
            key="aed"
            render={(_, record: any) => {
              return (
                <>
                  <EditableItem
                    hoveredRow={hoveredRow}
                    record={record}
                    data={_}
                    dataKey="aed"
                    handleSuccess={handleSuccess} />
                </>
              );
            }}
          />
          <Column
            title="INR"
            dataIndex="inr"
            key="inr"
            render={(_, record: any) => {
              return (
                <>
                  <EditableItem
                    hoveredRow={hoveredRow}
                    record={record}
                    data={_}
                    dataKey="inr"
                    handleSuccess={handleSuccess}  />
                </>
              );
            }}
          />
        </ColumnGroup>
        <Column
          title="B2B2B"
          dataIndex="b2b2b"
          key="b2b2b"
          render={(_, record: any) => {
            return (
              <>
                <EditableItem
                  hoveredRow={hoveredRow}
                  record={record}
                  data={_}
                  dataKey="b2b2b"
                  handleSuccess={handleSuccess} />
              </>
            );
          }}
        />
        <Column
          title="B2B"
          dataIndex="b2b"
          key="b2b"
          render={(_, record: any) => {
            return (
              <>
                <EditableItem
                  hoveredRow={hoveredRow}
                  record={record}
                  data={_}
                  dataKey="b2b"
                  handleSuccess={handleSuccess} />
              </>
            );
          }}
        />
        <Column
          title="B2C"
          dataIndex="b2c"
          key="b2c"
          render={(_, record: any) => {
            return (
              <>
                <EditableItem
                  hoveredRow={hoveredRow}
                  record={record}
                  data={_}
                  dataKey="b2c"
                  handleSuccess={handleSuccess} />
              </>
            );
          }}
        />
      </Table>
    );
  };
  return (
    <div className="container">
      <FormModal
        buttonText="Submit"
        form={form2}
        handleCancel={handleCancel}
        handleForm={updateFormHandle}
        isModalOpen={isModalOpen}
        loading={isLoading}
        title="Set Pricing"
        width={500}
      >
        <Form.Item 
          name={"appliedForCountry"} 
          label="Applied for Country"
          rules={[{ required: true, message: 'Please Select Country!' }]}
        >
          <Select
            showSearch
            options={
              countryList.map((ctr: any) => ({
                value: ctr.code, label: ctr.name
              }))}
              filterOption={(input:any, option:any) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />
        </Form.Item>
        {/* <Form.Item 
          name={"appliedFromCountry"} 
          label="Applied from Country"
          rules={[{ required: true, message: 'Please Select Country!' }]}
        >
          <Select
            showSearch
            options={
              countryList.map((ctr: any) => ({
                value: ctr.code,
                label: ctr.name
              }))}
              filterOption={(input:any, option:any) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />
        </Form.Item> */}
        <InputBox 
          name={"visaType"} 
          label="Visa Type"
          rules={[{ required: true, message: 'Please Add Title!' }]}
        />
      </FormModal>
      <FormModal
        buttonText="Save"
        form={form}
        handleCancel={() => {
          setIsTypeModalOpen(false)
          form.resetFields()
        }}
        handleForm={handleTypeForm}
        isModalOpen={isTypeModalOpen}
        loading={isLoading}
        title="Visa Type"
        width={500}
      >
          <Form.Item 
            name={"appliedForCountry"} 
            label="Country"
            rules={[{ required: true, message: 'Please Select Country!' }]}
          >
            <Select
              showSearch
              placeholder="Select country"
              options={
                countryList.map((ctr: any) => ({
                  value: ctr.code, label: ctr.name
                }))}
                filterOption={(input:any, option:any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            />
          </Form.Item>
          <InputBox 
            name={"title"} 
            label="Visa Type"
            rules={[{ required: true, message: 'Please Add Title!' }]}
          />
          {/* <Form.Item 
            name={"appliedFromCountry"} 
            label="Applied from Country"
            rules={[{ required: true, message: 'Please Select Country!' }]}
          >
            <Select
              showSearch
              options={
                countryList.map((ctr: any) => ({
                  value: ctr.code,
                  label: ctr.name
                }))}
                filterOption={(input:any, option:any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            />
          </Form.Item> */}
          <Form.Item name={"documents"} label="Documents">
            <Select
              mode="tags"
              allowClear
              style={{ width: '100%' }}
              placeholder="Please select documents"
              options={selectOption}
            />
          </Form.Item>
          <Form.Item name={"note"} label="Notes (Description)">
            <TextArea rows={3} />
          </Form.Item>
      </FormModal>
      <div className="flex justify-between items-center mb-4 max-md-block">
        <div className="flex max-md-mb-3 max-sm-block">
          <p className="page-title relative pr-4 mr-4 font-medium mb-2">
            Pricing
          </p>
          <Breadcrumb
            items={[
              {
                title: "Home",
              },
              {
                title: "Pricing",
              },
            ]}
          />
        </div>
        <div>
          <Space size={"middle"} className="max-sm-block">
            <Button
              icon={<FilterOutlined />}
              className="max-sm-mb-2 max-sm-w-full"
            >
              Filter
            </Button>
            <Button
              onClick={() => setIsTypeModalOpen(true)}
              type="primary"
              className="max-sm-w-full"
              style={{
                background: "#1A73E8"
              }}
              icon={<PlusOutlined />}
            >
              Visa Type
            </Button>
          </Space>
        </div>
      </div>
      <Table
        className="priceTable border-around"
        scroll={{ x: 1219 }}
        dataSource={pricingList}
        expandable={{ expandedRowRender,  expandRowByClick: true,  }}
        loading={isLoading}
      >
        <Column
          render={(d) => <b>{getCountryNameByCountryCode(d)}</b>}
          title="Country"
          dataIndex="country"
          key="country"
        />

      </Table>
    </div>
  );
};

const mapStateToProps = ({ loading , login }: RootState) => {
  const { user , adminPermission } = login
  const { isLoading } = loading
  return { isLoading , adminPermission , user }
}

export default connect(mapStateToProps, {})(Pricing);
