import {
    EditOutlined,
    UserOutlined,
  } from "@ant-design/icons";
  import { Breadcrumb, Divider, Flex, Button, Avatar } from "antd";
   import { useNavigate } from "react-router-dom";
  import { UserInterface } from "../interface";
  import { RootState } from "../reducer";
  import { connect } from "react-redux";
  
  
  const Profile = ({user}:{user:UserInterface}) => {
    const navigate = useNavigate()

  
    return (
      <>
        <div className="container">
          <div className="flex justify-between items-center mb-4 max-md-block">
            <div className="flex max-md-mb-3 page-head-wrapper">
              <p className="page-title relative pr-4 mr-4 font-medium mb-2">
                Edit Profile
              </p>
              <Breadcrumb
                items={[
                  {
                    title: "Home",
                  },
                  {
                    title: "Profile Overview",
                  },
                ]}
              />
            </div>
          </div>
          <div className="update-profile">
            <Flex align="center" justify="space-between" className="profile-header">
                  <div className="image-wrapper">
                    {user?.profile_image ? <img src={`${process.env.REACT_APP_API_URL}/user/profile-image/${user._id}`}  alt="user-profile"/> :  <Avatar shape="square" size={64} icon={<UserOutlined />} />  }
                  </div>
                <Button type="primary" icon={<EditOutlined /> } onClick={()=> { 
                  navigate(`${["ADMIN", "SUB_ADMIN"].includes(user?.role) ? "/edit-adminprofile" : "/profile-edit" }`)} } >
                  Edit Profile
                </Button>
              
            </Flex>
  
            <Divider />
  
            <div className="profile-body-wrapper">
              <p className="section-header">Profile</p>
              <Flex gap={100} className="profile-body body-1">
                <Flex className="left" gap={20} vertical>
                  <Flex className="feild">
                    <p className="title">First Name :</p>
                    <p className="data">{user?.name?.first_name}</p>
                  </Flex>
                  <Flex className="feild">
                    <p className="title">Last Name :</p>
                    <p className="data">{user?.name?.last_name}</p>
                  </Flex>
                  <Flex className="feild">
                    <p className="title">Phone Number :</p>
                    <p className="data">{user?.contact_no}</p>
                  </Flex>
                  <Flex className="feild">
                    <p className="title">Email :</p>
                    <p className="data">{user?.email}</p>
                  </Flex>
                </Flex>
              </Flex>
            </div>
          </div>
        </div>
      </>
    );
  };
  const mapStateToProps = ({ login }: RootState) => {
    const {user} = login;
   
    return {user}
  }
  const mapDispatchToProps ={}
  export default connect(mapStateToProps , mapDispatchToProps)(Profile);
  