import { Button, Dropdown, Space, Tabs, TabsProps, MenuProps , Form, message, InputNumber, Avatar, Alert, Modal, Typography } from "antd";
import headerLogo from "../assets/svg/main-logo.svg";
import { DownOutlined, HomeOutlined, IdcardOutlined, LogoutOutlined, TransactionOutlined, UserOutlined, UserSwitchOutlined } from "@ant-design/icons";
import wallet from "../assets/svg/wallet.svg";
import mLogo from "../assets/svg/m-logo.svg"
import { connect } from "react-redux";
import { RootState } from "../reducer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LocalStorageService } from "../utils/LocalStorage";
import { isLogin, onLogin, walletChangeEffect } from "../action";
import { useEffect, useState } from "react";
import AxiosService from "../utils/APIService";
import { setWalletAmount } from "../action";
import FormModal from "./FormModal";
import { UserInterface } from "../interface";
import { ROLE } from "../consts";
const Layout = ({ children, user, balance,setWalletAmount, isLoading , permission, walletChangeEffect }: {
    children?:any,
    user?: UserInterface,
    balance:number,
    setWalletAmount(balance:number):void,
    isLoading: boolean,
    permission:any,
    walletChangeEffect():void
}) => {
    const navigate = useNavigate()
    const location = useLocation() 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const axiosService = new AxiosService()
    const [amount, setAmount] = useState(0)
    const [form] = Form.useForm()
    
    const tabitems: TabsProps["items"] = [
      {
        key: "HOME",
        label: (
          <div>
            <HomeOutlined className="max-sm-hidden" /> Home
          </div>
        ),
      },
      {
        key: "VISA",
        label: (
          <div>
            <IdcardOutlined className="max-sm-hidden" /> Visa
          </div>
        ),
      },
      {
        key: "TRANSACTION",
        label: (
          <div>
            <TransactionOutlined className="max-sm-hidden" /> Transaction
          </div>
        ),
      },
      {
        key: "MANAGEUSER",
        label: (
          <div>
            <UserSwitchOutlined className="max-sm-hidden" /> Users
          </div>
        ),
      },
    ];
    const items: MenuProps['items'] = [
        {
            label: 'My Profile',
            key: '0',
            icon: <UserOutlined style={{fontSize: "16px"}} />
        },
        {
            label: 'Logout',
            key: '4',
            icon: <LogoutOutlined style={{ fontSize: "16px" }} />,
        },
    ];
    const defaultActivate:any = () => {
       
        const {pathname} = location

        switch (pathname) {
            case "/home":
                return "HOME";
            case "/visa-request":
                return "VISA";
            case "/transaction-detail":
                return "TRANSACTION";
            case "/manage-user":
                return "MANAGEUSER";
            default:
                return '1'
        }
    }

    const tabChanage = (key:any) => {
        switch (key) {
            case "HOME":
                navigate('/home')
                break;
            
            case "VISA":
                navigate('/visa-request')
                break;
            case "TRANSACTION":
                navigate('/transaction-detail')
                break;
            case "MANAGEUSER":
                navigate('/manage-user')
                break;
                
            default:
                break;
        }
        
    }

    const handleDropdownItemClick = (rec:any) => {
        switch (rec.key) {
            case '0':
              if(user?.role === "AGENT"){
                navigate('/update-profile')
              }else{
                navigate('/profile')
              }
              break;
            case '4':
              logoutHandle()
              break;
        
            default:
                break;
        }
    }
    const logoutHandle = () => {
        LocalStorageService.removeItem('userToken')
        LocalStorageService.removeItem('userData')
        isLogin(false)
        onLogin(null)
        navigate('/login')
    }
    const showModal = () => {
      if((user?.role === ROLE.subAgent && permission?.ADD_BALANCE.allow) ||  user?.role !== ROLE.subAgent){
        setIsModalOpen(true);
      }
    };

    const handleCancel = () => {
      setIsModalOpen(false);
      form.resetFields()
    };
    const handleWallet = (val: any) => {
      axiosService
        .post("/user/add-amount", {
          amount: val.balance,
        })
        .then((res: any) => {
          setWalletAmount(res.amount);
          setIsModalOpen(false);
          form.resetFields();
          setAmount(0)
          walletChangeEffect()
        })
        .catch((e) => {
          console.log(e);
        });
    };
    return (
      <div>
        <FormModal 
          title="Wallet" 
          buttonText="Pay"
          width={397}
          form={form}
          handleCancel={handleCancel}
          handleForm={handleWallet}
          isModalOpen={isModalOpen}
          footerElement={
            <p className="font-medium" style={{ color: "#071437" }}>
                Pay Amount :&nbsp;
                <span style={{ color: "#1A73E8" }}>
                  ₹ {`${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              </p>}
          loading={isLoading}
        >
          <p className="font-semibold mb-5" style={{ color: "#071437", fontSize: 16 }}>
            Total Balance : ₹{" "}
            {`${balance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
          <Form.Item
              name="balance"
              label="Add Balance"
              rules={[{ required: true, message: "Please add balance!" }]}
            >
              <InputNumber
                formatter={(value) =>
                  `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{
                  width: "100%",
                }}
                size="large"
                onChange={(val: any) => setAmount(val || 0)}
              />
            </Form.Item>
        </FormModal>
        <div className="header-main mb-5">
          <div className="container">
            <div className="header-wrapper flex justify-between">
              <img
                src={headerLogo}
                alt="header logo"
                className="max-sm-hidden"
              />
              <img
                src={mLogo}
                alt="header logo"
                className="hidden max-sm-block"
              />
              <Tabs
                onChange={tabChanage}
                className="header-tab"
                size="large"
                defaultActiveKey={defaultActivate}
                items={tabitems.filter((itm:any) => {
                  if(user?.role === ROLE.subAgent){
                    return itm.key !== "MANAGEUSER"
                  }
                  return true
                })}
              />
              <div className="flex items-center">
                {permission?.WALLET?.allow || user?.role !== ROLE.subAgent ? <Button
                  className="header-btn max-sm-hidden"
                  onClick={showModal}
                  disabled={!user?.is_profile_updated}
                >
                  <img src={wallet} className="mr-2 max-md-mr-0" />
                  <span className="wallet-btn-txt">
                    {balance?.toLocaleString("hi-IN")}
                  </span>
                </Button> : null}
                <Dropdown
                  menu={{ items, onClick: handleDropdownItemClick }}
                  trigger={["click"]}
                  className="ml-6"
                >
                  <a
                    className="cursor-pointer dropdown-line relative"
                    onClick={(e) => e.preventDefault()}
                    style={{ color: "#000" }}
                  >
                    <Space className="flex">
                      {user?.profile_image ? 
                        <img height={37} width={37} style={{borderRadius:"50%" , objectFit:"cover"}} src={`${process.env.REACT_APP_API_URL}/user/profile-image/${user?._id}`} /> : 
                        <Avatar size={35} icon={<UserOutlined />} />}
                      <span className="max-md-hidden">
                        {user?.name?.first_name} {user?.name?.last_name}
                      </span>
                      <DownOutlined className="max-sm-hidden" />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
    );
}
const mapStateToProps = ({wallet, login , loading}: RootState) => {
    const {user, permission} = login;
    const {balance} = wallet;
    const { isLoading } = loading;
    return {user, balance , isLoading, permission}
}
const mapDispatchToProps = {setWalletAmount, walletChangeEffect}
export default connect(mapStateToProps, mapDispatchToProps) (Layout);
