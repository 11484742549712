import { Reducer, combineReducers } from "redux";
import loginReducer, { LoginState } from "./loginReducer";
import loadingReducer, { LoadingState } from "./loadingReducer";
import visaReqReducer, { ReqParamsState } from "./visaReqReducer";
import walletReducer, { WalletState } from "./walletReducer";
import autoSubmitReducer, { SwitchState } from "./autoSubmitReducer";
import routerReducer, { RouterState } from "./routerReducer";
import multiformDataReducer, { FormDataState } from "./multipleFormData";

export interface RootState {
  login: LoginState; // Assuming LoginState is the type for loginReducer state
  loading: LoadingState; // Assuming LoadingState is the type for loadingReducer state
  visaReq: ReqParamsState;
  wallet: WalletState;
  switchState: SwitchState;
  currentRouteState: RouterState;
  multiformData: FormDataState;
}
const rootReducer:Reducer<RootState> = combineReducers({
  login: loginReducer,
  loading: loadingReducer,
  visaReq: visaReqReducer,
  wallet: walletReducer,
  switchState: autoSubmitReducer,
  currentRouteState: routerReducer,
  multiformData: multiformDataReducer,
});
export default rootReducer;