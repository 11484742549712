import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Divider,
  Select,
  Flex,
  Upload,
  Button,
  Breadcrumb,
  message,
  Card,
  Radio,
  Row,
  Col,
  Typography,
  Modal,
} from "antd";

import type { GetProp, UploadFile, UploadProps } from 'antd';
import { Option } from "antd/es/mentions";
import {
  DownloadOutlined,
  FileImageOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AxiosService from "../utils/APIService";
import { UserInterface } from "../interface";
import { RootState } from "../reducer";
import { connect } from "react-redux";
import { ROLE } from "../consts";
import FileUpload from "../component/FileUpload";
import coutnries from "../country/data.json"
import downloadDocument from "../utils/docDownloader";
import { decryptPassword, decryptSalt } from "../utils/passwordDecs";
import { cities } from "../country/city";
import { state } from "../country/state";
import useUserData from "../hooks/useUserData";
import FormModal from "../component/FormModal";
import { ContactInput, InputBox } from "../component/FormElements";

const UpdateUser = ({user, isLoading}:{user:UserInterface, isLoading:any}) => {
  const [loading, setLoading] = useState(false)
  const [userProfile , setUserProfile] = useState<any>(null);
  const [form1] = Form.useForm()
  const [searchParams] = useSearchParams()
  let userId = user?.role === ROLE.admin || user?.role === ROLE.subAdmin ? searchParams.get("user") : user?._id
  
  const {userData} = useUserData(userId)
  const [userInfo, setUserInfo] = useState<UserInterface | null>(null)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [passwordChange, setPasswordChange] = useState(false)
  const adminRole = ["ADMIN", "SUB_ADMIN"]
  const [form] = Form.useForm()
  useEffect(()=>{
    if(userId && user?.is_profile_updated && !adminRole.includes(user?.role)){
      getAgent()
      setUserInfo(user)
    }else if(userId && userData?.is_profile_updated && adminRole.includes(user?.role)){
      getAgent()
      setUserInfo(userData)
    } else {
      let userDt:any = userId && adminRole.includes(user?.role) ? userData : user
      form.setFieldValue("firstName" , userDt?.name?.first_name)
      form.setFieldValue("lastName" , userDt?.name?.last_name)
      form.setFieldValue("ownerEmail" , userDt?.email)
      form.setFieldValue("ownerPhoneNumber" , userDt?.contact_no)
      form.setFieldValue("country" , "IN")
      const salt:string = decryptSalt(userDt?.salt);
      const password:string = decryptPassword(userDt?.hashed_password as string, salt);
      setUserProfile({...userProfile,user: {...userProfile?.user, password}})
      setUserInfo(userDt)
    }
  }, [userData, user, userId])

  const isAgent = user?.role === ROLE.agent
  const isAdmin = user?.role === ROLE.admin || user?.role === ROLE.subAdmin

  const getAgent = ()=>{
    
    axiosService.get(`/agent/get-agent/${userId}`)
      .then((res:any)=>{
        const data = res.data
        setUserProfile(data)
        form.setFieldValue("companyName" , data?.companey_details?.company_name)
        form.setFieldValue("phoneNumber" , data?.companey_details?.phone_number)
        form.setFieldValue("company_email" , data?.companey_details?.email)
        form.setFieldValue("panNo" , data?.companey_details?.pan_no)
        form.setFieldValue("gstNo" , data?.companey_details?.gst_no)
        form.setFieldValue("fullAddress" , data?.companey_details?.full_address)
        form.setFieldValue("city" , data?.companey_details?.city)
        form.setFieldValue("state" , data?.companey_details?.state)
        form.setFieldValue("country" , data?.companey_details?.country)
        form.setFieldValue("pincode" , data?.companey_details?.pincode)
        form.setFieldValue("firstName" , data?.user?.name?.first_name)
        form.setFieldValue("lastName" , data?.user?.name?.last_name)
        form.setFieldValue("ownerPhoneNumber" , data?.user?.contact_no)
        form.setFieldValue("ownerEmail" , data?.user?.email)
        // form.setFieldValue("type", data?.companey_details?.company_type)

        const profileImg:any = data?.user?.profile_image ? [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: `${process.env.REACT_APP_API_URL}/user/profile-image/${userId}`,
          }
        ] : []
        setFileList(profileImg)

      })
      .catch(()=>{
        // navigate('/agent')
      })
    
  }
  const onChange = (file:any) => {
    if(file){
      const formData = new FormData();
      const image: any | undefined = file; 
      formData.append("image", image);
      axiosService.post(`/user/change-profile-image/${userId}` , formData)
      .catch((err:any)=>{
        console.log(err)
      })
    }
  };
  const axiosService = new AxiosService()

  const onFinish = (values: any) => {
    
    if(!userInfo?.is_profile_updated){
      if(!values?.cancelled_cheque || values.cancelled_cheque.fileList.length == 0){
        message.info("Cancelled Cheque Required")
        return
      }
      if(!values?.company_registration || values.company_registration.fileList.length == 0){
        message.info("Company Registration Document Required")
        return
      }
      if(!values.visiting_card || values.visiting_card.fileList.length == 0){
        message.info("Visiting Card Required")
        return
      }
      if(!values.pan_card || values.pan_card.fileList.length == 0){
        message.info("PAN Card Required")
        return
      }
    }
    const formData = new FormData();
    formData.append("company_type", values.type);
    formData.append("first_name", values.firstName);
    formData.append("last_name", values.lastName);
    formData.append("phone_number", values.ownerPhoneNumber);
    formData.append("email", values.ownerEmail);
    if(values.password) formData.append("password", values.password);
    formData.append("confirmPassword", values.confirmPassword);
    formData.append("company_name", values.companyName);
    formData.append("company_phone_number", values.phoneNumber);
    formData.append("company_email", values.company_email);
    formData.append("pan_no", values.panNo);
    if(values.gstNo) formData.append("gst_no", values.gstNo);
    formData.append("full_address", values.fullAddress);
    formData.append("city", values.city);
    formData.append("state", values.state);
    formData.append("country", values.country);
    formData.append("pincode", values.pincode);
    formData.append("cancelled_cheque", values?.cancelled_cheque?.file?.originFileObj);
    formData.append("pan_card", values?.pan_card?.file?.originFileObj);
    formData.append("visiting_card", values?.visiting_card?.file?.originFileObj);
    formData.append("company_registration", values?.company_registration?.file?.originFileObj);
    if(user?.role === "ADMIN" || user?.role === "SUB_ADMIN") formData.append("userId", userId as string)
    if(userInfo?.is_profile_updated){
      axiosService
        .put(`/agent/update/${userId}`, formData)
        .then(() => {
          getAgent()
        })
        .catch((error:any) => {
          console.log(error);
        });
    }else{
        axiosService
        .post(`/agent/create`, formData)
        .then(() => {
          form.resetFields(["password", "confirmPassword"])
          window.location.replace('/home')
          getAgent()
        })
        .catch((error:any) => {
          console.log(error.message)
        });
    }
    
    
  };

  const getDownloadLink = (filename:any) => {
    setLoading(true)
    const userId = userProfile?.user?._id;
    axiosService.get(`/agent/get-doc/${filename}/${userId}`)
        .then((res:any) => {
          downloadDocument(res, filename)
          setLoading(false)
        })
        .catch((error) => {
          console.error('Error downloading file:', error);
          setLoading(false)
        });
  };
  const validatePAN = (_rule: any, value: any) => {
    if (!value) {
      return Promise.resolve();
    }
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (panRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Invalid PAN number');
  }
  const validateGST = (_rule:any, value:any) => {
    if (!value) {
      return Promise.resolve();
    }
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Invalid GST number');
  };
  const validatePANWithGST = (getFieldValue:any) => ({
    validator(_rule:any, value:any) {
        const panValue = getFieldValue('panNo');
        if (!value || !panValue) {
          return Promise.resolve();
        }
        const panFromGST = value.substring(2, 12);
        
        if (panValue === panFromGST) {
            return Promise.resolve();
        }
        return Promise.reject("GST number and PAN number doesn't match");
    },
})
const resetPasswordHandle = (val:any) => {
  axiosService.put(`/agent/password-change/${userId}`, {
    password: val.password, 
    confirmPasword: val.confirmPassword
  })
  .then(() => {
    form1.resetFields()
    setPasswordChange(false)
  })
  .catch(e => {
    console.log(e);
  })

}
 
  return (
    
    <div className="registration update-user">
       <FormModal 
        buttonText="Save" 
        form={form1} 
        isModalOpen={passwordChange}
        title="Reset Password"
        loading={loading}
        handleCancel={() => setPasswordChange(false)}
        handleForm={resetPasswordHandle}
        width={400}
        >
        <InputBox name={'password'} rules={[
            {
              required: true,
              message: "Please enter password!",
            },
          ]} 
          placeholder="Enter Password"
          type="password"
        />
        <InputBox name={'confirmPassword'} rules={[
          {
            required: true,
            message: "Please enter confirm password!",
          },
          ({ getFieldValue }:any) => ({
            validator(_:any, value:any) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  "The two passwords that you entered do not match!"
                )
              );
            },
          }),
        ]}
        placeholder="Re-Enter Password"
        type="password"
        />
       </FormModal>
      <div className="flex max-md-mb-3 max-sm-block">
        <p className="page-title relative pr-4 mr-4 font-medium mb-2">
          Edit Profile
        </p>
        {isAdmin && <Breadcrumb
          items={[
            {
              title: "Home",
            },
            {
              title: "Agents",
              path: "/agent"
            },
            {
              title: "Agent Profile",
            },
          ]}
          itemRender={(data:any) => {
            if(data.path){
                return <Link to={data.path}>{data.title}</Link>
            }
            return data.title
        }}
        />}
        {
          isAgent && <Breadcrumb
          items={[
            {
              title: "Home",
            },
            {
              title: "My Profile",
            },
          ]}
        />
        }
      </div>
      <Card className="registration-form">
        <Form onFinish={onFinish} layout="vertical" form={form}>
          <div className="upload-profile-image">
            <Flex justify="space-between" className="form-part">
              <div>
                <Form.Item name="upload-profile">
                  <FileUpload onChange={onChange} placeholder={<UserOutlined
                        style={{ fontSize: "3rem", opacity: "0.5" }}
                      />} setFile={setFileList} fileData={fileList} />
                </Form.Item>
                <p>Maximum file size should be 1 MB</p>
              </div>
            </Flex>
          </div>

          <Divider />

          <div className="companey-details">
            {/* <Form.Item
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please select one option!",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="INDIVIDUAL">Individual</Radio>
                <Radio value="PROPRIETOSHIP_FIRM">Proprietorship Firm</Radio>
                <Radio value="PARTNERSHIP_FIRM/LLP/PVT.LTD">
                  Partnership Firm/LLp/PVT.LTD
                </Radio>
              </Radio.Group>
            </Form.Item> */}
            <Row className="form-part justify-between">
              <Col span={12}>
                <InputBox 
                  label="Company Name" 
                  name="companyName" 
                  rules={[
                    {
                      required: true,
                      message: "Please enter Company Name!",
                    },
                  ]}
                />
                {/* <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      style={{ width: "100%" }}
                      label="Phone Number"
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please enter phone number!",
                        },
                      ]}
                      
                    >
                      <Input
                        placeholder="Phone Number"
                        size="large"
                        type="number"
                        width={100}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Email"
                      name="company_email"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter email address!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Email address"
                        size="large"
                        width={100}
                      />
                    </Form.Item>
                  </Col>
                </Row> */}

                <Row gutter={16}>
                  <Col span={12}>
                    <InputBox
                      label="PAN No."
                      name="panNo"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter PAN number!",
                        },
                        {
                          validator: validatePAN
                        }
                      ]}
                      isUppercase={true}
                    />
                  </Col>
                  <Col span={12}>
                    <InputBox
                      label="GST No."
                      name="gstNo"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          validator: validateGST
                        },
                        validatePANWithGST(form.getFieldValue)
                      ]}
                      isUppercase={true}
                    />
                  </Col>
                </Row>
                <InputBox
                  label="Full address"
                  name="fullAddress"
                  rules={[
                    {
                      required: true,
                      message: "Please enter full address!",
                    },
                  ]}
                />
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="City"
                      name="city"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter city!",
                        },
                      ]}
                    >
                      <Select
                          showSearch
                          filterOption={(input: any, option: any) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={cities.map((ctr: any) => ({
                            value: ctr,
                            label: ctr,
                          }))}
                          size="large"
                          placeholder="Select Country"
                        />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="State"
                      name="state"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter state!",
                        },
                      ]}
                    >
                      <Select
                          showSearch
                          filterOption={(input: any, option: any) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={state.map((st: any) => ({
                            value: st.value,
                            label: st.label,
                          }))}
                          size="large"
                          placeholder="Select Country"
                        />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Country"
                      name="country"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please select country!",
                        },
                      ]}
                    >
                      <Select  
                        size="large" 
                        placeholder="Select Country" 
                        allowClear
                        showSearch
                        filterOption={(input:any, option:any) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={
                          coutnries.map((ctr:any) => ({
                              value: ctr.code, label: ctr.name
                          }))
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <InputBox
                      label="Pincode"
                      name="pincode"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter pincode!",
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <span style={{ border: "1px solid #DFE1E5" }}></span>
              <Col span={12}>
                <Row gutter={16}>
                  <Col span={12}>
                    <InputBox
                      label="First Name"
                      name="firstName"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter first name!",
                        },
                      ]}
                    />
                  </Col>
                  <Col span={12}>
                    <InputBox
                      label="Last Name"
                      name="lastName"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Last Name!",
                        },
                      ]}
                    />
                  </Col>
                  <Col span={12}>
                    <ContactInput form={form1} name="ownerPhoneNumber" label="Phone Number" />
                  </Col>
                  <Col span={12}>
                    <InputBox
                      label="Email"
                      name="ownerEmail"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter email address!",
                        },
                      ]}
                    />
                  </Col>
                </Row>
                <Form.Item
                  style={{ width: "100%" }}
                  label="Current Password"
                >
                  <Input.Password
                    size="large"
                    value={userProfile?.user?.password}
                    className="mb-1"
                    />
                  <Flex justify="end">
                    <Button style={{padding: 0}} type="link" onClick={() => setPasswordChange(true)}>Reset Password</Button>
                  </Flex>
                </Form.Item>
              </Col>
            </Row>
          </div>

          
          <>
            <Divider />

            <div className="Upload-Doc update-profile">
              <Row className="form-part justify-between">
                <Col span={12}>
                  <div className="mb-4">
                    <Form.Item
                      label="Scan Copy of Cancelled Cheque"
                      name="cancelled_cheque"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: !userInfo?.is_profile_updated,
                          message: "Please Select File!",
                        },
                      ]}
                    >
                      <Upload.Dragger
                        customRequest={({ onSuccess }: any) => {
                          setTimeout(() => {
                            onSuccess("ok");
                          }, 0);
                        }}
                        maxCount={1}
                        className="CustomDragger"
                      >
                        <p className="gray">
                          <FileImageOutlined />
                        </p>
                        <span className="upload-text">Upload a Canceled Check </span>
                        <Button className="primary">Browse</Button>
                      </Upload.Dragger>
                    </Form.Item>
                    {userProfile?.document?.cancelled_cheque && <Flex justify="space-between" className="imageDownload mt-2">
                      <Flex align="center" gap={30}>
                        <FileImageOutlined className="blue" />

                        <Flex vertical>
                          <p className="image-text">{userProfile?.document?.cancelled_cheque}</p>
                          <p className="image-size">1014.59KB</p>
                        </Flex>
                      </Flex>

                      <Button disabled={loading} onClick={()=>getDownloadLink(userProfile?.document?.cancelled_cheque)} type="text"><DownloadOutlined /></Button>
                    </Flex>}
                  </div>
                  <div>
                    <Form.Item
                      label="Pan Card Copy"
                      name="pan_card"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: !userInfo?.is_profile_updated,
                          message: "Please Select File!",
                        },
                      ]}
                    >
                      <Upload.Dragger
                        customRequest={({ onSuccess }: any) => {
                          setTimeout(() => {
                            onSuccess("ok");
                          }, 0);
                        }}
                        maxCount={1}
                        name="file"
                        className="CustomDragger"
                      >
                        <p className="gray">
                          <FileImageOutlined />
                        </p>
                        <span className="upload-text">Upload a pan card copy</span>
                        <Button className="primary">Browse</Button>
                      </Upload.Dragger>
                    </Form.Item>
                    {userProfile?.document?.pan_card && <Flex justify="space-between" className="imageDownload mt-2">
                      <Flex align="center" gap={30}>
                        <FileImageOutlined className="blue" />

                        <Flex vertical>
                          <p className="image-text">{userProfile?.document?.pan_card}</p>
                          <p className="image-size">1014.59KB</p>
                        </Flex>
                      </Flex>

                      <Button disabled={loading} onClick={()=>getDownloadLink(userProfile?.document?.pan_card)} type="text"><DownloadOutlined /></Button>
                    </Flex>}
                  </div>
                </Col>
                <span style={{ border: "1px solid #DFE1E5" }}></span>
                <Col span={12}>
                  <div className="mb-4">
                    <Form.Item
                      label="Company Registration"
                      name="company_registration"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: !userInfo?.is_profile_updated,
                          message: "Please Select File!",
                        }
                      ]}
                    >
                      <Upload.Dragger
                        customRequest={({ onSuccess }: any) => {
                          setTimeout(() => {
                            onSuccess("ok");
                          }, 0);
                        }}
                        maxCount={1}
                        className="CustomDragger"
                      >
                        <p className="gray">
                          <FileImageOutlined />
                        </p>
                        <span className="upload-text">
                          Upload a company registration copy
                        </span>
                        <Button className="primary">Browse</Button>
                      </Upload.Dragger>
                    </Form.Item>
                    {userProfile?.document?.company_registration && <Flex justify="space-between" className="imageDownload mt-2">
                      <Flex align="center" gap={30}>
                        <FileImageOutlined className="blue" />

                        <Flex vertical>
                          <p className="image-text">{userProfile?.document?.company_registration}</p>
                          <p className="image-size">1014.59KB</p>
                        </Flex>
                      </Flex>

                      <Button disabled={loading} onClick={()=>getDownloadLink(userProfile?.document?.company_registration)} type="text"><DownloadOutlined/></Button>
                    </Flex>}
                  </div>
                  <div>
                    <Form.Item
                      label="Visiting Card"
                      name="visiting_card"
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: !userInfo?.is_profile_updated,
                          message: "Please Select File!",
                        },
                      ]}
                    >
                      <Upload.Dragger
                        customRequest={({ onSuccess }: any) => {
                          setTimeout(() => {
                            onSuccess("ok");
                          }, 0);
                        }}
                        maxCount={1}
                        className="CustomDragger"
                      >
                        <p className="gray">
                          <FileImageOutlined />
                        </p>
                        <span className="upload-text">
                          Upload a Visiting card copy
                        </span>
                        <Button className="primary">Browse</Button>
                      </Upload.Dragger>
                    </Form.Item>
                    {userProfile?.document?.visiting_card && <Flex justify="space-between" className="imageDownload mt-2">
                      <Flex align="center" gap={30}>
                        <FileImageOutlined className="blue" />

                        <Flex vertical>
                          <p className="image-text">{userProfile?.document?.visiting_card}</p>
                          <p className="image-size">1014.59KB</p>
                        </Flex>
                      </Flex>

                      <Button disabled={loading} onClick={()=>getDownloadLink(userProfile?.document?.visiting_card)} type="text"><DownloadOutlined /></Button>
                    </Flex>}
                  </div>
                </Col>
              </Row>
            </div>
          </>

          <Flex className="mt-5" justify="flex-end">
            <Button htmlType="submit" type="primary" loading={isLoading}>
              Save
            </Button>
          </Flex>
        </Form>
      </Card>
    </div>
  );
};
const mapStateToProps = ({ login, loading }: RootState) => {
  const {user} = login;
  const {isLoading} = loading;
 
  return {user, isLoading}
}
const mapDispatchToProps ={}
export default connect(mapStateToProps , mapDispatchToProps)(UpdateUser);
