import { Breadcrumb, Button, Card, Col, DatePicker,
    Divider, Flex, Form, InputNumber, Radio,Row, Select,Typography,Upload,UploadProps,message } from "antd"
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AxiosService from "../utils/APIService";
import { RootState } from "../reducer";
import countries from '../country/data.json'
import { setVisaParams } from "../action";
import profassion from "../data/profession.json";
import getAppDocLink from "../utils/getAppDocLink";
import { PlusOutlined } from "@ant-design/icons";
import CountryFlag from 'react-country-flag';
import FileUpload from "../component/FileUpload"
import dayjs from "dayjs";
import uploadSvg from '../assets/svg/upload-svg.svg';
import { ROLE } from "../consts";
import { useForm } from "antd/es/form/Form";
import { UserInterface } from "../interface";
import {ContactInput, InputBox} from "../component/FormElements";

const FormDetail = ({
    reqParams,
    setVisaParams,
    isLoading,
    user
}: {
    reqParams: any,
    setVisaParams:any,
    isLoading: boolean,
    user: UserInterface
}) => {
    const axiosService = new AxiosService()
    const navigate = useNavigate();
    const [frontFileList, setFrontFileList] = useState<any[]>([]);
    const [backFileList, setBackFileList] = useState<any[]>([]);
    const [photoList, setPhotoList] = useState<any[]>([]);
    const [idList, setIdList] = useState<any[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [supportiveDocList, setSupportiveDoc] = useState<any[]>([]);
    const [updateAppData, setUpdateAppData] = useState<any> ();
    const [searchParams] = useSearchParams()
    const editId = searchParams.get("edit")
    const [form] = Form.useForm()
    const [maritalStatus, setMaritalStatus] = useState("")
    const maritalOpt = ["Single", "Married", "Divorced", "Widow", "Deceased", "Unspecific", "Child"]
    const castOpt = ["Muslim", "Christian", "Hindhu", "Budist", "Sikh", "Kadiani", "Bahaei", "Jewish"]
    const langOpt = ["Arabic", "English", "French", "Hindi", "Urdu", "German", "Italian", "Swahili", "Spanish", "Russian", "Indonesian", "Malaysian", "Malayan", "Balochi", "Turkish", "Japanese", "Chinese", "Thai", "Tagalog", "Farsi", "Sinhalese", "Bangla", "Telugu", "Unknown", "Others"]
    const allowStatus = ["MARRIED", "WIDOW"]
    let saveSubmit:boolean = false;
    const isAdmin = user?.role === ROLE.admin || user?.role === ROLE.subAdmin

    useEffect(() => {
        if (!reqParams && !editId) {
            navigate('/visa-request')
        }else if(!editId){
            form.setFieldValue("nationality", reqParams.nationality.toUpperCase())
        }
        if (editId) {
            setIsEditing(true)
            getDataForUpdate(editId)
        }
    }, [reqParams])
    useEffect(() => {
        document.body.classList.add('body-bg');
        form.setFieldValue("birth_country", "IN")
        form.setFieldValue("passport_type", "NORMAL PASSPORT")
        form.setFieldValue("passport_country", "IN")
        form.setFieldValue("language", "ENGLISH")
        form.setFieldValue("marital_status", "SINGLE")
        form.setFieldValue("religion", "HINDU")
    }, []);

    const getDataForUpdate = (id: string) => {
        axiosService.get(`/app/get-by-id/${id}`)
            .then(async (res: any) => {
                const data = res.data
                setUpdateAppData(data)
                form.setFieldValue("first_name", data.passanger_detail.name.first_name)
                form.setFieldValue("last_name", data.passanger_detail.name.last_name)
                form.setFieldValue("middle_name", data.passanger_detail.name.middle_name)
                form.setFieldValue("dob", dayjs(data.passanger_detail.date_of_birth))
                form.setFieldValue("birth_place", data.passanger_detail.birth_place)
                form.setFieldValue("birth_country", data.passanger_detail.birth_country)
                form.setFieldValue("marital_status", data.passanger_detail.marital_status)
                form.setFieldValue("religion", data.passanger_detail.religion)
                form.setFieldValue("profession", data.passanger_detail.profession)
                form.setFieldValue("gender", data.passanger_detail.gender)
                form.setFieldValue("passport_type", data.passport_detail.passport_type)
                form.setFieldValue("passport_number", data.passport_detail.passport_number)
                form.setFieldValue("issueDate", dayjs(data.passport_detail.issue_date))
                form.setFieldValue("passport_issue_city", data.passport_detail.issue_city)
                form.setFieldValue("passport_exp_date", dayjs(data.passport_detail.expire_date))
                form.setFieldValue("passport_country", data.passport_detail.passport_country)
                form.setFieldValue("nationality", data.passport_detail.nationality)
                form.setFieldValue("language", data.passport_detail.language)
                form.setFieldValue("father_name", data.family_detail.father_name)
                form.setFieldValue("mother_name", data.family_detail.mother_name)
                form.setFieldValue("spouse_name", data.family_detail.spouse_name)
                if(data?.family_detail?.group_membership) form.setFieldValue("group_membership", data?.family_detail?.group_membership)
                form.setFieldValue("address", data.address_detail.address)
                form.setFieldValue("city", data.address_detail.city)
                form.setFieldValue("phone_number", data.address_detail.phone_number)
                form.setFieldValue("email", data.address_detail.email)
                setMaritalStatus(data.passanger_detail.marital_status)
                const frontLink = await getAppDocLink(data.app_id, data.passport_detail?.front_image)
                const backLink = await getAppDocLink(data.app_id, data.passport_detail?.back_image)
                const travellerLink = await getAppDocLink(data.app_id, data.traveller_img)
                if(data.id_img){
                    const idlink = await getAppDocLink(data.app_id, data.id_img)
                    setIdList([{
                        uid: '-1',
                        name: data.id_img,
                        status: 'done',
                        url: idlink,
                    }])
                }
                let extraDoc = []
                
                for (let i = 0; i < data?.extra_doc.length; i++) {
                    const element = data?.extra_doc[i];
                    extraDoc.push({
                        uid: `-${i+1}`,
                        name: element,
                        status: 'done',
                        url: await getAppDocLink(data.app_id, element),
                    })
                }
                let passportFront = [{
                    uid: '-1',
                    name: data.passport_detail?.front_image,
                    status: 'done',
                    url: frontLink,
                }]
                let passportBack = [{
                    uid: '-1',
                    name: data.passport_detail?.back_image,
                    status: 'done',
                    url: backLink,
                }]
                let travellerPhoto = [{
                    uid: '-1',
                    name: data.traveller_img,
                    status: 'done',
                    url: travellerLink,
                }]
                form.setFieldValue("passportFront", passportFront)
                setFrontFileList(passportFront)
                form.setFieldValue("passportBack", passportBack)
                setBackFileList(passportBack)
                form.setFieldValue("travellerPhoto", travellerPhoto)
                setPhotoList(travellerPhoto)
                
                setSupportiveDoc(extraDoc)
            })
            .catch((e) => {
                console.log(e);
                navigate('/visa-request')
            })
    }

    const saveAndSubmit = ()=>{
        saveSubmit =true     
    }
    const navigatetoVisaPage = () => {
        let adminRoute = ['ADMIN', "SUB_ADMIN"]
        navigate(adminRoute.includes(user.role) ? '/visa' : '/visa-request  ')
    }
    const onFinish = (formVal: any) => {
        if ((frontFileList.length === 0 ||
            backFileList.length === 0 ||
            photoList.length === 0 ||
            idList.length === 0) &&
            !isEditing) {
                message.error("Visa Documents requires")
        } else {
            let excludeFields = ["passportFront", "passportBack", "travellerPhoto", "birthId"]
            const formData = new FormData();
            for (let key in formVal) {
                if(!excludeFields.includes(key) && formVal[key] != undefined) formData.append(key, formVal[key])
            }
            if(saveSubmit) formData.append("submitByAgent" , "true")
            if (!isEditing) {
                for (let i = 0; i < supportiveDocList.length; i++) {
                    const docFile:any = supportiveDocList[i];
                    formData.append(`extDoc_${i+1}`, docFile.originFileObj)
                }
                formData.append("passport_front_img", frontFileList[0].originFileObj)
                formData.append("passport_back_img", backFileList[0].originFileObj)
                formData.append("traveller_img", photoList[0].originFileObj)
                formData.append("id_img", idList.length > 0 ? idList[0].originFileObj : undefined)
                formData.append("visa_type", reqParams.visaType)
                formData.append("traveling", reqParams.travelling)

                axiosService.post('/app/create-application', formData)
                    .then(() => {
                        setVisaParams(null)
                        navigate('/visa-request')
                    })
                    .catch((e) => {
                        console.log(e);
                    })
            } else {
                if (frontFileList.length > 0 && frontFileList[0].originFileObj) {
                    formData.append("passport_front_img", frontFileList[0].originFileObj)
                }
                if (backFileList.length > 0 && backFileList[0].originFileObj) {
                    formData.append("passport_back_img", backFileList[0].originFileObj)
                }
                if (photoList.length > 0 && photoList[0].originFileObj) {
                    formData.append("traveller_img", photoList[0].originFileObj)
                }
                if (idList.length > 0 && idList[0].originFileObj) {
                    formData.append("id_img", idList[0].originFileObj)
                }
                
                let lastCount = 0;

                if (updateAppData.extra_doc.length > 0) {
                    const lastExtraDoc = updateAppData.extra_doc[updateAppData.extra_doc.length - 1];
                    const matchResult = lastExtraDoc.match(/\d+/g);
                    if (matchResult) {
                        lastCount = Number(matchResult[0]);
                    }
                }

                for (const file of formVal?.extraDoc?.fileList || []) {
                    if (file.hasOwnProperty("originFileObj")) {
                        lastCount++;
                        const fieldName = `extDoc_${lastCount}`;
                        const fileObject = file.originFileObj;
                        formData.append(fieldName, fileObject);
                    }
                }

                axiosService.put(`/app/update/${editId}`, formData)
                    .then(() => {
                        getDataForUpdate(editId || "")
                    })
                    .catch((e: any) => {
                       console.log(e);
                    })
            }
        }
    }
    const supportiveDocOnChange: UploadProps['onChange'] = ({ fileList: newFileList } :any) => {
        setSupportiveDoc(newFileList);
    };

    const custReq = ({ onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok")
        }, 0)
    }
    const removeExtraDoc = (file: any) => {
        if(!file.hasOwnProperty("originFileObj")){
            axiosService.delete(`/remove/${updateAppData.app_id}/${file.name}`)
            .catch((e) => {
                console.log(e);
            })
        }
    }
    const filePlaceholder = (<div className="flex flex-col items-center">
    <img className="mb-7" src={uploadSvg} style={{width: "200px"}} />
    <div>
        <p style={{color: "#1A73E8", fontSize: "14px"}}>Drag an Image here or click to upload</p>
        <span style={{color: "#99A1BB", fontSize: "14px"}}>Upload an image in either a PNG or JPEG format. Maximum size: 2MB.</span>
    </div>
    </div>)

    const fetchDataFromPassport = (file:any) => {
        if(file){
            const formData = new FormData()
            formData.append("image", file)
            axiosService.post(`${process.env.REACT_APP_PASSPORT_OCR}/upload-paasport-front`, formData, true, false)
            .then((res:any) => {
                const data:any = res.Response
                if(data){
                    form.setFieldValue("dob", dayjs(data.birth_date, 'DD-MM-YYYY'))
                    form.setFieldValue("passport_exp_date", dayjs(data.expiry_date, 'DD-MM-YYYY'))
                    form.setFieldValue("issueDate", dayjs(data.issue_date, 'DD/MM/YYYY'))
                    form.setFieldValue("first_name", data.name)
                    form.setFieldValue("passport_number", data.passport_no)
                    form.setFieldValue("birth_place", data.place_of_birth)
                    form.setFieldValue("passport_issue_city", data.place_of_issue)
                    form.setFieldValue("gender", data.sex == "M" ? "MALE" : "FEMALE")
                    form.setFieldValue("last_name", data.surname)
                }
                
            })
            .catch(e => console.log(e))
        }
    }

    const backPassportDataFetch = (file:any) => {
        if(file){
            const formData = new FormData()
            formData.append("image", file)
            axiosService.post(`${process.env.REACT_APP_PASSPORT_OCR}/upload-paasport-back`, formData, true, false)
            .then((res:any) => {
                const data:any = res.Response
                form.setFieldValue("address", data.address)
                form.setFieldValue("city", data.place_of_issue)
                form.setFieldValue("mother_name", data.name_of_mother)
                form.setFieldValue("father_name", data.legal_guardian)
                if(data.name_of_spouse){
                    form.setFieldValue("spouse_name", data.name_of_spouse)
                    form.setFieldValue("marital_status", "MARRIED")
                    setMaritalStatus("MARRIED")
                }
            })
            .catch(e => console.log(e))
        }
    }

    return (
        <div className="container">
            <div className="flex justify-between items-center mb-4 max-md-block">
                <div className="flex max-md-mb-3 max-sm-block">
                    <p className="page-title relative pr-4 mr-4 font-medium mb-2">Form Details</p>
                    <Breadcrumb
                        items={[
                            {
                                title: "Country Wise Data",
                                path: "/home"
                            },
                            {
                                title: 'Visa',
                                path: isAdmin ? "/visa" : "/visa-request"
                            },
                            {
                                title: 'Form Details',
                            }
                        ]}
                        itemRender={(data:any) => {
                            if(data.path){
                                return <Link to={data.path}>{data.title}</Link>
                            }
                            return data.title
                        }}
                    />
                </div>
            </div>
            <div className="form-detail">
                <Card style={{ marginBottom: "30px" }}>
                    <Form
                        onFinish={onFinish}
                        autoComplete="off"
                        layout="vertical"
                        form={form}
                        scrollToFirstError
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Flex align="center" className="mb-3">
                                    <span className="section-counter rounded-full border flex items-center justify-center font-bold mr-5">01</span>
                                    <Typography.Text style={{fontSize: "18px", fontWeight: 500}}>Passport Front Details</Typography.Text>
                                </Flex>
                            </Col>
                            <Col span={11}>
                                <Form.Item 
                                    style={{position:"sticky" , top:"0", zIndex:"11" , background:"white"}} 
                                    className="mb-0 passport-dropbox" 
                                    label="Upload Passport Front Side" 
                                    name={"passportFront"} 
                                    rules={[{ required: true, message: 'Please Upload Passport Front Image!' }]}
                                >
                                    <FileUpload onRemove={() => {
                                        form.resetFields(["passportFront"])
                                    }} accept=".png, .jpeg, .jpg" required placeholder={filePlaceholder} setFile={(val:any) => {
                                        fetchDataFromPassport(val[0]?.originFileObj)
                                        setFrontFileList(val)
                                    }} fileData={frontFileList} />
                                </Form.Item>
                            </Col>
                            <Col span={13}>
                                <Row gutter={10}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Passport Type"
                                            name="passport_type"
                                            rules={[{ required: true, message: 'Please Select Passport Type!' }]}
                                        >
                                            <Select
                                                placeholder="- Select Passport Type -"
                                                allowClear
                                                size="large"
                                            >
                                                <Select.Option value="NORMAL PASSPORT">Normal Passport</Select.Option>
                                                <Select.Option value="DIPLOMATIC PASSPORT">Diplomatic Passport</Select.Option>
                                                <Select.Option value="TRAVEL DOCUMENT">Travel Document</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <InputBox 
                                            label="Passport Number"
                                            name="passport_number"
                                            rules={[{ required: true, message: 'Please Insert Passport Number!' }]}
                                            isUppercase={true}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <InputBox 
                                            label="Last Name"
                                            name="last_name"
                                            isUppercase={true}
                                         />
                                    </Col>
                                    <Col span={12}>
                                        <InputBox
                                            label="First Name"
                                            name="first_name"
                                            rules={[{ required: true, message: 'Please Insert First Name!' }]}
                                            isUppercase={true}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <InputBox
                                            label="Middle Name"
                                            name="middle_name"
                                            isUppercase={true}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Birth Date"
                                            name="dob"
                                            rules={[{ required: true, message: 'Please Insert Date of Birth!' }]}
                                        >
                                            <DatePicker className="w-full" format={"DD/MM/YYYY"} placeholder="DD/MM/YYYY" size="large" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Gender"
                                            name="gender"
                                            rules={[{ required: true, message: 'Please Select Gender!' }]}
                                        >
                                            <Radio.Group>
                                                <Radio value={'MALE'}>Male</Radio>
                                                <Radio value={'FEMALE'}>Female</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <InputBox
                                            label="Birth Place"
                                            name="birth_place"
                                            rules={[{ required: true, message: 'Please Insert Birth Place!' }]}
                                            isUppercase={true}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Birth Country"
                                            name="birth_country"
                                            rules={[{ required: true, message: 'Please Select Birth Country!' }]}
                                        >
                                            <Select
                                                placeholder="- Select Birth Country -"
                                                allowClear
                                                size="large"
                                                showSearch
                                                filterOption={(input:any, option:any) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                options={countries.map((cntr: any) => ({
                                                    value: cntr.code,
                                                    label: cntr.name
                                                }))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <InputBox
                                            label="Passport Issue City"
                                            name="passport_issue_city"
                                            rules={[{ required: true, message: 'Please Insert Passport Issue City!' }]}
                                            isUppercase={true}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Passport Country"
                                            name="passport_country"
                                            rules={[{ required: true, message: 'Please Insert Passport Country!' }]}
                                        >
                                            <Select
                                                placeholder="- Select Passport Country -"
                                                allowClear
                                                size="large"
                                                showSearch
                                                filterOption={(input:any, option:any) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                options={countries.map((cntr: any) => ({
                                                    label: cntr.name,
                                                    value: cntr.code
                                                }))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Passport Issue Date"
                                            name="issueDate"
                                            rules={[
                                                { required: true, message: 'Please Insert Passport Issue Date!' },
                                                {
                                                validator(_, value) {
                                                    if (value && dayjs(value.toString()).isAfter(dayjs())) {
                                                    return Promise.reject(new Error('Invalid Issue Date'));
                                                    }
                                                    return Promise.resolve();
                                                },
                                                },
                                            ]}
                                        >
                                            <DatePicker className="w-full" format={"DD/MM/YYYY"} placeholder="DD/MM/YYYY" size="large" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Passport Expiry Date"
                                            name="passport_exp_date"
                                            rules={[
                                                { required: true, message: 'Please Select Passport Expiry Date' },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        const issueDate = getFieldValue('issueDate');
                                                        if (!issueDate || !value) {
                                                            return Promise.resolve(); 
                                                        }
                                            
                                                        if (dayjs(value).isSame(issueDate, 'day')) {
                                                            return Promise.reject(new Error('Issue Date should not be the same as Expiry Date'));
                                                        }
                                                        if (dayjs(value).isBefore(dayjs())) {
                                                            return Promise.reject(new Error('Invalid Expiry Date'));
                                                        }
                                                        if (dayjs(value).isBefore(dayjs().add(6, 'months'))) {
                                                            return Promise.reject(new Error('Expiry Date should be more than 6 months from now'));
                                                        }
                                                        
                                                        return Promise.resolve(); 
                                                    },
                                                })
                                            ]}
                                        >
                                            <DatePicker className="w-full" format={"DD/MM/YYYY"} placeholder="DD/MM/YYYY" size="large" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={16}>
                            <Col span={24}>
                                <Flex align="center" className="mb-3">
                                    <span className="section-counter rounded-full border flex items-center justify-center font-bold mr-5">02</span>
                                    <Typography.Text style={{fontSize: "18px", fontWeight: 500}}>Passport Back Details</Typography.Text>
                                </Flex>
                            </Col>
                            <Col span={11}>
                                <Form.Item 
                                    style={{position:"sticky" , top:"0", zIndex:"11" , background:"white"}} 
                                    className="mb-0 passport-dropbox" 
                                    label="Upload Passport Back Side" 
                                    name={"passportBack"} 
                                    rules={[{ required: true, message: 'Please Upload Passport Back Image!' }]} 
                                >
                                    <FileUpload onRemove={() => {
                                        form.resetFields(["passportBack"])
                                    }} accept=".png, .jpeg, .jpg" placeholder={filePlaceholder} setFile={(val:any) => {
                                        backPassportDataFetch(val[0]?.originFileObj)
                                        setBackFileList(val)
                                    }} fileData={backFileList} />
                                </Form.Item>
                            </Col>
                            <Col span={13}>
                                <Row gutter={10}>
                                    <Col span={24}>
                                        <InputBox
                                            label="Father Full Name"
                                            name="father_name"
                                            rules={[{ required: true, message: 'Please Insert Father Name!' }]}
                                            isUppercase={true}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <InputBox
                                            label="Mother Full Name"
                                            name="mother_name"
                                            rules={[{ required: true, message: 'Please Insert Mother Name!' }]}
                                            isUppercase={true}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Marital Status"
                                            name="marital_status"
                                            rules={[{ required: true, message: 'Please Select Marital Status!' }]}
                                        >
                                            <Select
                                                placeholder="- Select Marital Status -"
                                                allowClear
                                                size="large"
                                                onChange={(val) => setMaritalStatus(val)}
                                            >
                                                {maritalOpt.map(mrt => <Select.Option key={mrt} value={mrt.toUpperCase()}>{mrt}</Select.Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        {allowStatus.includes(maritalStatus) && 
                                        <InputBox
                                            label="Spouse Name"
                                            name="spouse_name"
                                            rules={[{ required: true, message: 'Please Insert Spouse Name!' }]}
                                            isUppercase={true}
                                        />}
                                    </Col>
                                    <Col span={24}>
                                        <InputBox
                                            label="Address"
                                            name="address"
                                            rules={[{ required: true, message: 'Please Insert Address!' }]}
                                            isUppercase={true}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <InputBox
                                            label="City"
                                            name="city"
                                            rules={[{ required: true, message: 'Please Insert City!' }]}
                                            isUppercase={true}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <InputBox
                                            label="Nationality"
                                            name="nationality"
                                            isUppercase={true}
                                            disabled
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Language"
                                            name="language"
                                            rules={[{ required: true, message: 'Please Insert Language!' }]}
                                        >
                                            <Select
                                                placeholder="- Select Language -"
                                                allowClear
                                                size="large"
                                                showSearch
                                                filterOption={(input:any, option:any) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                options={langOpt.map(lang => ({
                                                    value: lang,
                                                    label: lang
                                                }))}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={16}>
                            <Col span={24}>
                                <Flex align="center" className="mb-3">
                                    <span className="section-counter rounded-full border flex items-center justify-center font-bold mr-5">03</span>
                                    <Typography.Text style={{fontSize: "18px", fontWeight: 500}}>Other Details</Typography.Text>
                                </Flex>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Religion"
                                    name="religion"
                                    rules={[{ required: true, message: 'Please Select Religion!' }]}
                                >
                                    <Select
                                        placeholder="- Select Religion -"
                                        allowClear
                                        size="large"
                                        showSearch
                                    >
                                        {castOpt.map(cst => <Select.Option key={cst} value={cst.toUpperCase()}>{cst}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Profession"
                                    name="profession"
                                    rules={[{ required: true, message: 'Please Select Profession!' }]}
                                >
                                    <Select
                                        placeholder="- Select Profession -"
                                        allowClear
                                        size="large"
                                        showSearch
                                    >
                                        {profassion.map(prf => <Select.Option key={prf} value={prf.toUpperCase()}>{prf}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {(updateAppData?.family_detail?.group_membership || (reqParams?.type && reqParams?.type !== 'Single')) && <Col span={8}>
                                <Form.Item
                                    label="Group Membership"
                                    name="group_membership"
                                    rules={[{ required: true, message: 'Please Select Group Membership!' }]}
                                >
                                    <Select
                                        placeholder="- Select Group Membership -"
                                        allowClear
                                        size="large"
                                    >
                                        <Select.Option value="Main/Only Person in a Group">Main/Only Person in a Group</Select.Option>
                                        <Select.Option value="Son of Main Person">Son of Main Person</Select.Option>
                                        <Select.Option value="Daughter of Main Person">Daughter of Main Person</Select.Option>
                                        <Select.Option value="Wife of Main Person">Wife of Main Person</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>}
                            {!(updateAppData?.passanger_detail?.profession === "CHILD" || 
                            updateAppData?.passanger_detail?.profession === "STUDENT") &&
                                <>
                                    <Col span={12}>
                                        <ContactInput form={form} name="phone_number" label="Phone Number" />
                                    </Col>
                                    <Col span={12}>
                                        <InputBox
                                            label="Email Id"
                                            name="email"
                                            rules={[{ required: true, type: "email", message: 'Please Insert Email Id!' }]}
                                            isUppercase={true}    
                                        />
                                    </Col>
                                </>
                            }
                        </Row>
                        <Divider />
                        <Row gutter={16} className="w-full">
                            <Col lg={12} xs={24}>
                                <Form.Item className="mb-0" label="Upload Traveller's photo" name={"travellerPhoto"} rules={[{ required: true, message: "Please Upload Traveller's Photo!" }]}>
                                    <FileUpload onRemove={() => {
                                form.resetFields(["travellerPhoto"])
                            }} accept=".png, .jpeg, .jpg" placeholder={filePlaceholder} setFile={setPhotoList} fileData={photoList} />
                                </Form.Item>
                            </Col>
                            {!(updateAppData?.passanger_detail?.profession === "CHILD" || 
                            updateAppData?.passanger_detail?.profession === "STUDENT") && <Col lg={12} xs={24}>
                            <Form.Item className="mb-0" name={"birthId"} label="PAN Card" rules={[{ required: true, message: "Please Upload Traveller's PAN Card!" }]}>
                                <FileUpload onRemove={() => {
                            form.resetFields(["birthId"])
                        }} accept=".png, .jpeg, .jpg" placeholder={filePlaceholder} setFile={setIdList} fileData={idList} />
                            </Form.Item>
                        </Col>}
                        </Row>
                        
                        <Form.Item  className="mb-0" label="Extra Document" name="extraDoc">
                            <Upload
                                listType="picture-card"
                                onChange={(e: any) => { supportiveDocOnChange(e) }}
                                customRequest={custReq}
                                multiple
                                fileList={supportiveDocList}
                                onRemove={removeExtraDoc}
                            >
                                <button style={{ border: 0, background: 'none' }} type="button">
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </button>
                            </Upload>
                        </Form.Item>
                        <Divider />
                        <Flex gap={5} justify="right" className="text-right">
                            <Button type="default" onClick={navigatetoVisaPage}>Cancel</Button>
                            <Button className="max-sm-w-full" name="save" type="default" htmlType="submit" loading={isLoading}>
                                {isEditing ? "Update" : "Save as Draft"}
                            </Button>
                            <Button onClick={saveAndSubmit} className="max-sm-w-full" name="saveandsubmit" type="primary" htmlType="submit" loading={isLoading}>
                                {isEditing ? "Update & Submit" : "Save & Submit"}
                            </Button>
                        </Flex>
                    </Form>
                </Card>
            </div>
        </div>
    )
}
const mapStateToProps = ({ visaReq, loading, login }: RootState) => {
    const { reqParams } = visaReq
    const { isLoading } = loading
    const { user } = login

    return { reqParams, isLoading, user }
}
const mapDispatchToProps = {
    setVisaParams
}
export default connect(mapStateToProps, mapDispatchToProps)(FormDetail)