import { EyeInvisibleOutlined, EyeOutlined, LockOutlined, PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Flex, Form, Input, Select, Space, Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import AxiosService from "../utils/APIService";
import FormModal from "../component/FormModal";
import { connect } from "react-redux";
import { RootState } from "../reducer";
import { decryptSalt , decryptPassword} from '../utils/passwordDecs'
import user1 from "../assets/img/user1.png";
import { InputBox } from "../component/FormElements";
const ToggleView = ({text}: {text:string}) => {
    const [isVisible, setisVisible] = useState(false);

    return (
        <>
            {isVisible ? <div className="flex items-center">
                <span className="text-sm block mr-2">{text}</span>
                <Button type="text" onClick={() => setisVisible(false)} icon={<EyeInvisibleOutlined />}></Button>
            </div> : <div className="flex items-center">
                <span className="text-sm block mr-2">*********</span>
                <Button type="text" onClick={() => setisVisible(true)} icon={<EyeOutlined />}></Button>
            </div>}
            
        </>
    )
}
const SubAdmin = ({isLoading}: {
    isLoading: boolean
}) => {
    const [form] = Form.useForm()
    const axiosService = new AxiosService()
    const [size , setSize] = useState<number>()
    const [tableParams, setTableParams] = useState<any>({
        pagination: {
          current: 1,
          pageSize: 10,
        },
      });
    const [filter , setFilter] = useState<any>()
    useEffect(() => {
        document.body.classList.add('body-bg');
    }, []);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const columns: ColumnsType<any> = [
        {
            title: 'Username',
            dataIndex: 'profile',
            key: 'profile',
            render: (e) => (<>
                <div className="flex items-center">
                    <img className="avatar" src={e.userImage} />
                    <span className="text-sm block" style={{color: "#5F617A"}}>{e.username}</span>
                </div>
            </>)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
            render: (e) => (<ToggleView text={e} />),
            width: '30%'

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (e:any) => (
                <Select
                    className="status-dropdown"
                    style={{ width: 120 }}
                    value={e.text}
                    onChange={(val) => {
                        statusChangeHandle(val, e.id)
                    }}
                >
                    <Select.Option value="ACTIVE"><Flex align="center"><span className="dot active"/> <span>Active</span></Flex></Select.Option>
                    <Select.Option value="DEACTIVE"><Flex align="center"><span className="dot deactive"/> <span>Deactivate</span></Flex></Select.Option>
                </Select>
            )
        },
        {
            title: 'Permission',
            dataIndex: 'permission',
            key: 'permission',
            render: (_, rec) => {
                return (<>
                <Space>
                    <Button onClick={() => updateClickHandle(_, rec)} shape="round" size={"small"} className="text-sm" style={{color: "#5F617A"}}>Edit</Button>
                    <Button onClick={() => {navigate(`/admin-permission?user=${_}`)}} shape="round" size={"small"} className="text-sm" style={{color: "#5F617A"}}><LockOutlined /></Button>
                </Space>
                </>)
            },
            width: '8%',
            align: "center"
        }
    ];

    const [userList, setUserList] = useState([])
    const [updateId, setUpdateId] = useState("")

    useEffect(() => {
        getSubAdmins({page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize});
    },[tableParams])
    const showModal = () => {
        setIsModalOpen(true);
    };
    const updateClickHandle = (id:any, rec:any) => {
        setUpdateId(id)
        form.setFieldValue("name", rec.profile.username)
        form.setFieldValue("email", rec.email)
        form.setFieldValue("password", rec.password)
        setIsModalOpen(true)
    }
    const statusChangeHandle = (data:any, id:any) => {
        axiosService.post(`/sub-admin/change-status/${id}`, {
            status: data
        }).then(() => {
            getSubAdmins({page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize})
        })
        .catch((e:any) => {
            console.log(e);
        })
        
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setUpdateId("")
        form.resetFields()
    };
    const addUserHandler = (val:any) => {
        if(updateId){
            axiosService.put(`/sub-admin/update/${updateId}`, {
                firstName: val.name, 
                email: val.email,
                password: val.password
            })
            .then(() => {
                setIsModalOpen(false)
                form.resetFields()
                getSubAdmins({page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize})
            }).catch((e) => {
                console.log(e);
            })
        }else {
            axiosService.post('/sub-admin/create', {
                firstName: val.name, 
                email: val.email,
                password: val.password
            })
            .then(() => {
                setIsModalOpen(false)
                form.resetFields()
                getSubAdmins({page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize})
            }).catch((e) => {
                console.log(e);
            })
        }
    }
    const getSubAdmins = ({page , pageSize}:{page:number , pageSize:number}) => {
        axiosService.get('/sub-admin/list', {
            page:page,
            pageSize:pageSize
        })
            .then((res:any) => {
                const usrData = res.data.map((data:any) => {
                    const salt:string = decryptSalt(data.salt);
                    const password:string = decryptPassword(data.hashed_password, salt);    
                    return {
                        profile: {
                            username: data.name.first_name,
                            userImage: user1
                        },
                        email: data.email,
                        password: password,
                        status: {
                            text: data.status,
                            id: data._id
                        },
                        permission: data._id
                    };
                });
                setUserList(usrData)
                setSize(res.size)
            })
            .catch((e:any) => {
                console.log(e);
            })
    }
    return (
        <div className="container">
            <FormModal 
                title={updateId ? "Update Member" :"Add New Member" }
                buttonText={updateId ? "Update Member" :"Add Member"}
                width={500}
                form={form}
                handleCancel={handleCancel}
                handleForm={addUserHandler}
                isModalOpen={isModalOpen}
                loading={isLoading}
            >
                <InputBox
                    label="First Name"
                    name="name"
                    className="mb-4"
                    rules={[{ required: true, message: 'Please input your Name!' }]}
                />
                <InputBox
                    label="Mail id"
                    name="email"
                    className="mb-4"
                    rules={[{ required: true, message: 'Please input your email!', type: "email" }]}
                />
                <InputBox
                    label="Password"
                    name="password"
                    className="mb-4"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                    type="password"
                />
            </FormModal>
            <div className="flex justify-between items-center mb-4 max-md-block">
                <div className="flex max-md-mb-3 max-sm-block">
                    <p className="page-title relative pr-4 mr-4 font-medium mb-2">Manage Users</p>
                    <Breadcrumb
                        items={[
                            {
                                title: 'Home',
                            },
                            {
                                title: 'User List',
                            },
                        ]}
                    />
                </div>
                <div>
                    <Space size={"middle"} className="max-sm-block">
                        <Button onClick={showModal} type="primary" className="max-sm-w-full" style={{background: "#1A73E8"}} icon={<PlusOutlined />}>Add New User</Button>
                    </Space>
                </div>
            </div>
            <Table
                pagination={{showSizeChanger:true,
                    onChange(page, pageSize) {
                        setTableParams({
                            pagination: {
                              current: page,
                              pageSize: pageSize,
                            },
                          })
                    },
                    total:size
                }}
                className='border-around'
                columns={columns}
                dataSource={userList}
                scroll={{ x: 1219 }}
                loading={isLoading}
            />
        </div>
    )
}
const mapStateToProps = ({loading}:RootState) => {
    const {isLoading} = loading
    return {
        isLoading
    }
}
export default connect(mapStateToProps, {}) (SubAdmin)