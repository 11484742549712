import {
  EyeInvisibleOutlined,
  EyeOutlined,
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
  SearchOutlined,
  ExportOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Card,
  Row,
  Select,
  Space,
  Table,
  TableColumnsType,
  message,
  Flex,
  Modal,
  DatePicker,
  Avatar,
} from "antd";
import { useEffect, useState } from "react";
import AxiosService from "../utils/APIService";
import { useNavigate } from "react-router-dom";
import FormModal from "../component/FormModal";
import { connect } from "react-redux";
import { RootState } from "../reducer";
import TextArea from "antd/es/input/TextArea";
import useTeam from "../hooks/useTeam";
import { InputBox } from "../component/FormElements";

interface DataType {
  key: React.Key;
  company: string;
  assignto: string;
  stage: string;
  status: string;
  note: string;
}

//Agent Component
const ClientList = ({ isLoading }: { isLoading: boolean }) => {
  const axiosService = new AxiosService();
  const navigate = useNavigate()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectClient, setselectClient] = useState(0);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [listParams, setlistParams] = useState({
    page: 1,
    pageLimit: 10,
    totalRecord: 0
  })
  const [tableData, setTableData] = useState([])
  const {memberList} = useTeam()
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const columns: TableColumnsType<DataType> = [
    {
      title: "Comapny Name",
      dataIndex: "company",
      key: "profile",
    },
    {
      title: "City Name",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      render: (e: any, rec:any) => (
        <Select
          className="status-dropdown"
          style={{ width: 120 }}
          value={e}
          options={[
            { value: 1, label: "Stage1" },
            { value: 2, label: "Stage2" },
            { value: 3, label: "Stage3" },
          ]}
          onChange={(val:number) => stageChangeHandle(val, rec)}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (e: any, rec:any) => {
        let options:any = [
          ([1,2,3].includes(rec.stage) && { value: "Chat", label: "Chat" }),
          ([2,3].includes(rec.stage) && { value: "Call", label: "Call" }),
          ([3].includes(rec.stage) && { value: "Meeting", label: "Meeting" })
        ].filter(Boolean)
        return (
        <Select
          className="status-dropdown"
          style={{ width: 120 }}
          value={e}
          options={options}
          onChange={(val:string) => statusChangeHandle(val, rec)}
        />
      )},
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (newSelectedRowKeys.length > 0) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }

    setSelectedRowKeys(newSelectedRowKeys);
    setselectClient(newSelectedRowKeys.length);
  };

  const stageChangeHandle = async (val:any, rec:any) => {
    axiosService.put(`/crm/change-stage/${rec.key}`,{
      stage: val
    })
    .then(() => {
      fetchLeadList({page: listParams.page, pageLimit: listParams.pageLimit})
    })
  }
  const statusChangeHandle = async (val:any, rec:any) => {
    axiosService.put(`/crm/change-status/${rec.key}`,{
      status: val
    })
    .then(() => {
      fetchLeadList({page: listParams.page, pageLimit: listParams.pageLimit})
    })
  }

  useEffect(() => {
    document.body.classList.add('body-bg');
  }, []);
  
  useEffect(() => {
    fetchLeadList(listParams)
  }, [listParams]);

  const fetchLeadList = ({page, pageLimit}: {
    page: number,
    pageLimit:number
  }) => {
    axiosService.get('/crm/get',{
      page,
      pageSize: pageLimit
    })
      .then((res:any) => {
        const data = res.data.map((dt:any)=> ({
          key: dt._id,
          company: dt.client.company_detail.business_name,
          city: dt.client.company_detail.address.city,
          stage: dt.stage,
          status: dt.lead_status,
          note: dt.client.note
        }))
        setTableData(data)
      })
      .catch(e => console.log(e))
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleCancel = () => {
    setIsFormModalOpen(false);
    form.resetFields()
  };
  const onCancel = () => {
    setIsModalOpen(false)
    setSelectedRowKeys([])
  }
  const create = (val:any) => {
    axiosService.post('/crm/create-task',{
      taskName: val.taskname, 
      assignTo: val.assignto, 
      dueDate: val.duedate.toString(), 
      action: val.action, 
      note: val.note, 
      clientId: selectedRowKeys
    })
    .then(() => {
      setIsFormModalOpen(false)
      setIsModalOpen(false)
      setSelectedRowKeys([])
      form.resetFields()
    })
    .catch(e => console.log(e))
  };
  return (
    <div className="client-list container">
      <FormModal
        buttonText="Create"
        form={form}
        handleCancel={handleCancel}
        handleForm={create}
        isModalOpen={isFormModalOpen}
        loading={isLoading}
        title="Create Task"
        width={500}
      >
        <InputBox
          label="Task Name"
          name="taskname"
          className="mb-4"
          rules={[{ required: true, message: "Please enter task name!" }]}
        />
        <Form.Item
          label="Assign To"
          name="assignto"
          className="mb-4"
          rules={[{ required: true, message: "Please select agent!" }]}
        >
          <Select
            size="large"
            mode="multiple"
            className="status-dropdown"
            options={memberList.map((dt:any) => ({
              value: dt._id, 
              label: dt?.name?.first_name,
              emoji: dt.profile_image
            }))}
            optionRender={(option) => (
              <Space>
                <span role="img" aria-label={option.data.label}>
                  {option.data.emoji ? 
                  <Avatar src={`${process.env.REACT_APP_API_URL}/user/profile-image/${option.data.value}`} /> :
                  <Avatar style={{ backgroundColor: '#87d068' }} src icon={<UserOutlined />} />}
                </span>
                {option.data.label}
              </Space>
            )}
          />
        </Form.Item>
        <Form.Item
          label="Due Date"
          name="duedate"
          className="mb-4"
          rules={[{ required: true, message: "Please select date!" }]}
        >
          <DatePicker size="large" style={{width:'100%'}} />
        </Form.Item>
        <Form.Item
          label="Action Type"
          name="action"
          className="mb-4"
          rules={[{ required: true, message: "Please select action!" }]}
        >
          <Select
            size="large"
            className="status-dropdown"
            options={[
              { value: "Chat", label: "Chat" },
              { value: "Call", label: "Call" },
              { value: "Meeting", label: "Meeting" },
            ]}
            onChange={() => {}}
          />
        </Form.Item>
        <Form.Item label="Notes" name="note" className="mb-4">
          <TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
        </Form.Item>
      </FormModal>
      <div className="client-list-model">
        <Modal
          width={700}
          className="client-list-model"
          style={{ top: "50px" }}
          zIndex={1}
          mask={false}
          footer={null}
          open={isModalOpen}
          onCancel={onCancel}
        >
          <Flex align="center">
            <Flex gap={30} align="center">
              <Flex className="round" align="center" justify="center">
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    color: "#FFFFFF",
                  }}
                >
                  {selectClient}
                </p>
              </Flex>
              <p className="client-model-title">Client List</p>
            </Flex>
            <Button
              type="primary"
              size="middle"
              onClick={() => setIsFormModalOpen(true)}
              style={{
                marginLeft: "auto",
                paddingLeft: "2em",
                paddingRight: "2em",
                marginRight: "2em",
                fontWeight: "500",
              }}
            >
              Create Task
            </Button>
          </Flex>
        </Modal>
      </div>

      <div className="flex justify-between items-center mb-4 max-md-block">
        <div className="flex max-md-mb-3 max-sm-block">
          <p className="page-title relative pr-4 mr-4 font-medium mb-2">
            Client List
          </p>
          <Breadcrumb
            items={[
              {
                title: "Home",
              },
              {
                title: "Agents",
              },
            ]}
          />
        </div>
        <Flex gap="middle">
          <Space size={"middle"} className="max-sm-block">
            <Input placeholder="Search..." prefix={<SearchOutlined />} />
            <Button
              icon={<FilterOutlined />}
              className="max-sm-mb-2 max-sm-w-full"
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            >
              Filter
            </Button>
          </Space>
          <Button className="primary" type="primary" value="large" onClick={() => navigate("/crm/add-client")}>
            <PlusOutlined /> Add Client
          </Button>
        </Flex>
      </div>
      {isDrawerOpen ? (
        <Card style={{ marginBottom: "1em" }}>
          <Form layout="vertical" form={form}>
            <Row align={"middle"} gutter={16}>
              <Col lg={4}>
                <Form.Item
                  style={{ width: "100%" }}
                  label="Status"
                  name="status"
                >
                  <Select
                    allowClear
                    placeholder="select Status"
                    options={[
                      { label: "Active", value: "ACTIVE" },
                      { label: "Deactive", value: "DEACTIVE" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col lg={4}>
                <Form.Item style={{ width: "100%" }} label="Type" name="type">
                  <Select
                    allowClear
                    placeholder="select Type"
                    options={[
                      { label: "B2B", value: "B2B" },
                      { label: "B2B2B", value: "B2B2B" },
                      { label: "B2C", value: "B2C" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Space>
                  <Button
                    style={{ marginTop: ".5em" }}
                    className="max-sm-w-full"
                    type="primary"
                    htmlType="submit"
                  >
                    Filter
                  </Button>
                  <Button
                    style={{ marginTop: ".5em" }}
                    className="max-sm-w-full"
                    type="default"
                  >
                    Reset Filter
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      ) : null}

      <Table
        className='border-around'
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        style={{ position: "relative", zIndex: "2" }}
        scroll={{ x: 1219 }}
      />
    </div>
  );
};

const mapStateToProps = ({ loading }: RootState) => {
  const { isLoading } = loading;
  return { isLoading };
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ClientList);
