import { DownloadOutlined, FilterOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, DatePicker, Card, Form, Row, Select, Space, Input } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useState } from "react";
import uae from "../assets/svg/uae.png";
import { useEffect } from 'react';
import AxiosService from "../utils/APIService";
import moment from "moment";
import { connect } from "react-redux";
import { RootState } from "../reducer";
import CountryFlag from 'react-country-flag';
import useAdminAgents from "../hooks/useAdminAgents";
import { UserInterface } from "../interface";
import { ROLE } from "../consts";
import Alert from "antd/es/alert/Alert";
import Export from "../component/Export";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { getCountryNameByCountryCode } from "../country";
import { InputBox } from "../component/FormElements";
const { RangePicker } = DatePicker;

const Transaction = ({isLoading , user , adminPermission}: {
    isLoading:boolean;
    user:UserInterface;
    adminPermission:any
}) => {
    const axiosService = new AxiosService();
    const {agentList} = useAdminAgents()
    const [transactionList, setTransactionList] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [form] = Form.useForm()
    const [filter , setFilter] = useState<any>()
    const [size , setSize] = useState<number>()
    const [tableParams, setTableParams] = useState<any>({
        pagination: {
          current: 1,
          pageSize: 10,
        },
      });
    useEffect(() => {
        document.body.classList.add('body-bg');
        fetchTransactionList({startDate:filter?.startDate, endDate:filter?.endDate , agents:filter?.agent , desc:filter?.desc , search:filter?.search,page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize})
    }, [tableParams , filter]);
    const checkPermissionForTableRow = (key:string) => {
        const per = adminPermission?.TRANSACTION?.find((data:any) => data.key === key)
        return (user?.role === ROLE.subAdmin && per?.allow) || user?.role === ROLE.admin
    }
    const columns: ColumnsType<any> = [
        checkPermissionForTableRow("COUNTRY") && 
        { 
          title: 'Country', 
          dataIndex: 'country', 
          key: 'country', 
          render: (e) => (
            <div className="flex items-center">
              <CountryFlag className="mr-1" style={{
                                   width:"28px",
                                   height:"28px",
                                   borderRadius: '50%',
                                   objectFit:"cover"
                                }} countryCode={e} svg /> {getCountryNameByCountryCode(e)}
            </div>
          ),
        },
        checkPermissionForTableRow("AGENT") && { 
          title: 'Agent', 
          dataIndex: 'agent', 
          key: 'agent' 
        },
        checkPermissionForTableRow("DATE") && { 
          title: 'Date', 
          dataIndex: 'date', 
          key: 'date', 
          render: (e) => (
            <span style={{color: "#5F617A"}} className="text-sm block mb-1">{moment(e).format('DD-MM-YYYY')}</span>
          ),
        },
        checkPermissionForTableRow("TRANSACTION_ID") && { 
          title: 'Transactions ID', 
          dataIndex: 'transactionsID', 
          key: 'transactionsID', 
          render: (e) => (
            <span style={{color: "#1A73E8"}} className="text-sm block mb-1">{e}</span>
          ),
        },
        checkPermissionForTableRow("DESCRIPTION") && { 
          title: 'Description', 
          dataIndex: 'description', 
          key: 'description', 
          render: (e, rec) => (
            <p style={{color: "#5F617A"}} className="items-center">{e}{rec?.application?.app_id && ` (${rec?.application?.app_id})` }</p>
          ),
        },
        {
            title: 'By',
            dataIndex: 'by', 
            key: 'by', 
        },
        checkPermissionForTableRow("DEBIT_CREDIT") && { 
          title: 'Debit / Credit', 
          dataIndex: 'debitCredit', 
          key: 'debitCredit', 
          render: (e) => {
            if (e < 0) {
              return <p style={{color: "#FF4D49"}} className="items-center text-[#FF4D49]">{e.toLocaleString()} Dr</p>   
            } else {
              return <p style={{color: "#24C141"}} className="items-center text-[#24C141]">{e.toLocaleString()} Cr</p>
            }
          },
        },
        checkPermissionForTableRow("TRANSACTION_BALANCE") && { 
          title: 'Balance', 
          dataIndex: 'balance', 
          key: 'balance', 
          render: (e) => (
            <p style={{color: "#1A2A3D"}} className="items-center font-medium">{e.toLocaleString()}</p>
          ),
        },
      ];
      const fetchTransactionList = ({startDate, endDate, agents, desc, search , page , pageSize}:
        {startDate?:any, endDate?:any, agents?:any[], desc?:string, search?:string , page:number , pageSize:number}) => {
        axiosService.get('/transaction/get-list',{
            startDate: startDate,
            endDate: endDate,
            agents: agents,
            desc: desc,
            search: search,
            page:page,
            pageSize:pageSize
        })
        .then((res:any) => {
            const listData = res.data.map((trns:any) => ({
                key: trns._id,
                transactionsID: trns.transaction_id,
                agent: `${trns?.user?.name?.first_name} ${trns?.user?.name?.last_name}`,
                date: trns.created_at,
                description: trns.description,
                debitCredit: trns.credit_amount ? trns.credit_amount : (0 - trns.debit_amount),
                balance: trns.main_balance,
                country: trns?.user?.country,
                by: trns?.by?.name.first_name,
                application: trns?.application
            }))
            setTransactionList(listData)
            setSize(res.size)
        })
        .catch((e) => console.log(e))
    }

    const filterHandler = (val:any) => {
        
        const startDate = val.dateRange?.length > 0 ? moment(val.dateRange[0].toString()).format("DD-MM-YYYY") : null
        const endDate = val.dateRange?.length > 0 ? moment(val.dateRange[1].toString()).format("DD-MM-YYYY") : null
        
        val.startDate = startDate
        val.endDate = endDate
        setFilter(val)
    }

    const resetFilter = () => {
        form.resetFields()
        setIsDrawerOpen(false)
        fetchTransactionList({page:tableParams.pagination.current , pageSize:tableParams.pagination.pageSize})
    }

    const exportToCSV = () => {     
        const header = ["Date", "Agent", "Transactions ID",   "Description", "Debit / Credit",  "Balance"];
        const csvRows = [];
        csvRows.push(header.join(','));
        transactionList.forEach((item:any) => {
            const row = [
                moment(item.date).format("DD-MM-YYYY HH:mm"),
                item.agent,
                item.transactionsID,
                item.description,
                item?.debitCredit ,
                item?.balance 
            ];
            csvRows.push(row.join(','));
        });
        const csvData = csvRows.join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "transactions.csv");
        link.click();
    };
    const exportToPDF = () => {
       
        const header = ["Date", "Agent", "Transactions ID",   "Description", "Debit / Credit",  "Balance"];
        let transactionListArray = transactionList.map((item:any) => [
            moment(item.date).format("DD-MM-YYYY HH:mm"),
            item.agent,
            item.transactionsID,
            item.description,
            item?.debitCredit ,
            item?.balance 
        ]);
        const doc = new jsPDF();
        doc.setFontSize(10);
        autoTable(doc, {
            head: [header],
            body: transactionListArray,
          });
        doc.save('transactions.pdf');
    };

    return (
        <div className="container">
            <div className="flex justify-between items-center mb-4 max-md-block">
                <div className="flex max-md-mb-3 max-sm-block">
                    <p className="page-title relative pr-4 mr-4 font-medium mb-2">Transactions</p>
                    <Breadcrumb
                        items={[
                            {
                                title: 'Home',
                            },
                            {
                                title: 'Transactions',
                            },
                        ]}
                    />
                </div>
                <div>
                    <Space size={"middle"} className="max-sm-block">
                        <Export 
                            exportToCsv={exportToCSV}
                            exportToPdf={exportToPDF}
                        />
                        <Button icon={<FilterOutlined />} className="max-sm-mb-2 max-sm-w-full" onClick={() => setIsDrawerOpen(!isDrawerOpen)}>Filter</Button>
                    </Space>
                </div>
            </div>
            {
                isDrawerOpen ? (
                    <Card
                    style={{marginBottom:"1em"}}
               >
                   <Form onFinish={filterHandler} layout="vertical" form={form}>
                       <Row align={'middle'} gutter={16}>
                           <Col lg={5}>
                               <Form.Item label="Request Date" name="dateRange">
                                   <RangePicker />
                               </Form.Item>
                           </Col>
                           <Col lg={5}>    
                               <Form.Item style={{width:"100%"}} label="Agent" name="agent">
                                   <Select
                                       mode="multiple"
                                       allowClear
                                       placeholder="select agent"
                                       options={agentList.map((agt:any) => ({
                                           label: `${agt?.name?.first_name} ${agt?.name?.last_name}`,
                                           value: agt._id
                                       }))}
                                   />
                               </Form.Item>
                           </Col>
                           <Col lg={5}>    
                               <Form.Item style={{width:"100%"}} label="Description" name="desc">
                                   <Select
                                       allowClear
                                       placeholder="Description"
                                       options={[
                                        {value: "Visa Application", label: "Visa Application"},
                                        {value: "Amount credit to wallet", label: "Amount credit to wallet"},
                                        {value: "Amount Add By Admin", label: "Amount Add By Admin"}
                                       ]}
                                   />
                               </Form.Item>
                           </Col>
                           <Col lg={5}>    
                               <InputBox style={{width:"100%"}} label="Transaction ID" name="transId" />
                           </Col>
                           <Col>
                                <Space>
                                    <Button style={{marginTop:".5em"}} className="max-sm-w-full" type="primary" htmlType="submit">
                                        Filter
                                    </Button>
                                    <Button style={{marginTop:".5em"}} className="max-sm-w-full" type="default" onClick={resetFilter}>
                                        Reset Filter
                                    </Button>
                                </Space>
                           </Col>
                       </Row>

                   </Form>
               </Card>
                ):(null)
            }
              {
                checkPermissionForTableRow("COUNTRY") ||
                checkPermissionForTableRow("AGENT") ||
                checkPermissionForTableRow("DATE") ||
                checkPermissionForTableRow("TRANSACTION_ID") ||
                checkPermissionForTableRow("DESCRIPTION") ||
                checkPermissionForTableRow("DEBIT_CREDIT") ||
                checkPermissionForTableRow("TRANSACTION_BALANCE") ? (
            <Table
                pagination={{showSizeChanger:true,
                    onChange(page, pageSize) {
                        setTableParams({
                            pagination: {
                            current: page,
                            pageSize: pageSize,
                            },
                        })
                    },
                    total:size
                }}
                className='border-around'
                columns={columns.filter(Boolean)}
                dataSource={transactionList}
                scroll={{ x: 1219 }}
                loading={isLoading}
            />   ):(<Alert message="You Don't Have Enough Permissions" type="error" />)}
        </div>
    )
}
const mapStateToProps = ({loading , login}:RootState) => {
    const { user , adminPermission } = login
    const {isLoading} = loading;
    return {isLoading , user , adminPermission}
}

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps) (Transaction);