import React, {useState} from 'react'
import { Breadcrumb, Button, Checkbox, Divider, Flex, Select, Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from 'react';
import AxiosService from '../utils/APIService';
import { PERMISSION_KEY, ROLE } from '../consts';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getCountryNameByCountryCode } from '../country';
import { connect } from 'react-redux';
import { RootState } from '../reducer';
import { HomeOutlined } from '@ant-design/icons';
import { UserInterface } from '../interface';


interface DataType {
    key: React.ReactNode;
    name: string;
    permission?: boolean;
    children?: DataType[];
    dataKey:string;
  }
const Permission = ({isLoading, user}: {
    isLoading:boolean,
    user: UserInterface
}) => {
    
    const [selectAll, setSelectAll] = useState(false)
    const apiService = new AxiosService()
    const [searchParams] = useSearchParams()
    const userId = searchParams.get("user")
    const [data, setData] =useState<DataType[]>([]);
    const navigate = useNavigate()

    const isAdmin = user?.role === ROLE.admin || user?.role === ROLE.subAdmin

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: () => <Checkbox checked={selectAll} onChange={selectAllChange} />,
            dataIndex: 'permission',
            key: 'permission',
            width: '5%',
            render: (val:boolean, rec:DataType) => {
                if(rec.children && rec.children.length > 0){
                    return ( <Checkbox checked={rec.permission}  onChange={(e:any) => selectAllFunc(e, rec)}/>)
                }else{
                    return ( <Checkbox checked={val}  onChange={(e:any) => permissionChangeHandle(e, rec)}/>)
                }
            }
        },
    ];

    const selectAllFunc = (e:any, rec:DataType) => {
        const updatedData = data.map(item => {
            const newItem = { ...item };
            if (item.key === rec.key && item.children) {
                newItem.children = item.children.map(child => ({
                    ...child,
                    permission: e.target.checked
                }));
                newItem.permission = e.target.checked;
            }
            return newItem;
        });
        setData(updatedData)
    }

    const permissionChangeHandle = (event:any, record:DataType) => {

        const permissionList = [...data]
        permissionList.forEach((item:DataType) => {
            if (item.children) {
                item.children.forEach((child:DataType) => {
                    if (child.key === record.key) {
                        child.permission = event.target.checked;
                    }
                });
                item.permission = item?.children?.every(dt => dt.permission === true)
            }else{
                if(item.key == record.key){
                    item.permission = event.target.checked
                }
            }
        })
        setData(permissionList)
    }
    const selectAllChange = (e:any) => {
        const dataList = [...data]
        updatePermissions(dataList,e.target.checked)
        function updatePermissions(data:any, newValue:boolean) {
            data.forEach((item:any) => {
                item.permission = newValue;
                if (item.children) {
                    updatePermissions(item.children, newValue);
                }
            });
        }
        setSelectAll(e.target.checked)
        setData(dataList)
    }
    

    useEffect(() => {
        document.body.classList.add('body-bg');
        fetchSchema()
    }, []);

    const fetchSchema = () => {
        apiService.get(`/permission/get-permission/${userId}`)
        .then((res:any) => {
            setData(convertData(res.data))
        })
        .catch((e:any) => {
            console.log(e);
            
        })
    }

    const convertData = (data:any) => {
        const convertedArray:DataType[] = [];
        let index = 0
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const element = data[key];
                if(Array.isArray(element)){
                    const dataArray = element.map((per:any, i:number) => ({
                        key: index * 10 + i + 1,
                        name: key === "COUNTRY" ?  getCountryNameByCountryCode(per.key) : PERMISSION_KEY[per.key],
                        permission: per.allow,
                        dataKey: per.key
                    }))

                    convertedArray.push({
                        key: index + 1,
                        name: PERMISSION_KEY[key],
                        dataKey: key,
                        permission: dataArray.every(ch => ch.permission === true),
                        children: dataArray
                    })
                }else{
                    convertedArray.push({
                        key: index + 1,
                        name: PERMISSION_KEY[key],
                        dataKey: key,
                        permission: element.allow,
                    })
                }
                index++;
            }
        }
        setSelectAll(checkPermissions(convertedArray));
        return convertedArray
    }

    function checkPermissions(data:any) {
        for (let item of data) {
            if ('children' in item) {
                for (let child of item.children) {
                    if (!child.permission) {
                        return false;
                    }
                }
            }
            if (!item.permission) {
                return false;
            }
        }
        return true;
    }

    const permissionSubmit = () => {
        let permissionData = [...data]

        let payload:any = {}

        permissionData.forEach((per: DataType) => {
            if(per.children){
                payload[per.dataKey] = per.children.map((cld:DataType) => ({
                    key: cld.dataKey,
                    allow: cld.permission
                }))
            }else{
                payload[per.dataKey] = {
                    key: per.dataKey,
                    allow: per.permission
                }
            }
        })

        apiService.put(`/permission/edit/${userId}`, payload)
            .catch((e) => {
                console.log(e);
            })
    }

    return (
        <div className="container">
            <div className="flex justify-between items-center mb-4 max-md-block">
                <div className="flex max-md-mb-3 max-sm-block">
                    <p className="page-title relative pr-4 mr-4 font-medium mb-2">User Permission</p>
                    <Breadcrumb
                        items={[
                            {
                                title: <HomeOutlined />,
                                path: "/home"
                            },
                            {
                                title: isAdmin ? "Agent" :'Manage User',
                                path: isAdmin ? "/agent" : "/manage-user"
                            },
                            {
                                title: 'User Permission',
                            }
                        ]}
                        itemRender={(data:any) => {
                            if(data.path){
                                return <Link to={data.path}>{data.title}</Link>
                            }
                            return data.title
                        }}
                    />
                </div>
            </div>
            <Table
                className='border-around'
                columns={columns}
                dataSource={data}
                pagination={false}
            />
            <Divider />
            <Flex justify='end' className='mb-4'>
                <Button onClick={() => navigate('/manage-user')}>Cancel</Button>
                <Button loading={isLoading} 
                type="primary" 
                onClick={permissionSubmit} 
                className="max-sm-w-full ml-2" 
                style={{background: "#1A73E8"}}>Save Permission</Button>
            </Flex>
            
        </div>)
}
const mapStateToProps = ({loading, login}:RootState) => {
    const {isLoading} = loading
    const {user} = login
    return {
        isLoading,
        user
    }
}
export default connect (mapStateToProps, {})(Permission);