import React, { useState } from 'react';
import type { MenuProps } from 'antd';
import { Dropdown, message } from 'antd';
import arrow from "../assets/svg/dropdwon-arrow.svg"
import enIcon from "../assets/svg/en_flag.svg"
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../assets/svg/logo.svg"
import { Button, Form, Input  } from 'antd';
import AxiosService from '../utils/APIService';
import { LocalStorageService } from '../utils/LocalStorage';
import { connect } from 'react-redux';
import {isLogin, onLogin, setWalletAmount, setSwitchVal, setPermission, setAdminPermission} from "../action"
import { RootState } from '../reducer';
import FormModal from '../component/FormModal';
import { AppPropsInterface } from '../interface';
import { ROLE } from '../consts';
import { InputBox } from '../component/FormElements';

const Login = ({
  onLogin,
  isLogin,
  setWalletAmount,
  setSwitchVal,
  setPermission,
  isLoading
}: AppPropsInterface) => {
    const navigate =useNavigate()
    const axiosService:any = new AxiosService();
    const [isModalOpen , setIsModalOpen] = useState(false)
    const [form] = Form.useForm()
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Link to="/home">
                    Home
                </Link>
            ),
        }
    ]
    const onFinish = async (values: any) => {
        axiosService.post('/auth/signin', {
            email: values.email,
            password: values.password
        })
        .then((res:any) => {
            if(res.token){
                LocalStorageService.setItem('userToken', res?.token)
            }
            isLogin(true)
            onLogin(res.user)
            if(res.user){
                LocalStorageService.setItem("userData", res?.user)
            }
            
            setWalletAmount(res?.mainBalance);
            setSwitchVal(res?.autoSubmit)
            if (res?.user?.role === ROLE.admin) {
                navigate("/agent");
            } else {
                if(res?.user?.role === ROLE.subAgent){
                  setPermission(res.permission)
                }
                navigate("/home");
            }
        })
        .catch(() => {
            isLogin(false)
            onLogin(null)
        })
    };
    const showModal = ()=>{
        setIsModalOpen(true)
    }
    const hideModal = ()=>{
        setIsModalOpen(false)
        form.resetFields()
    }
  const forgotPassword = async (value: any) => {
    axiosService.post("/auth/forgot-password", { email: value.email })
      .then(() => {
        setIsModalOpen(false)
      })
      .catch((e:any) => {
        console.log(e);
      })
  }
    return (
      <div style={{ height: "100vh" }}>

        <div
          className="login-header flex items-center justify-end m-auto"
          style={{ paddingTop: "26px", paddingBottom: "26px", width: "95%" }}
        >
          <Dropdown menu={{ items }} className="lang-dropdown">
            <a onClick={(e) => e.preventDefault()}>
              <div className="dropdown-align inline-flex items-center">
                <img src={enIcon} alt="flag" style={{ marginRight: "10px" }} />
                <span style={{ marginRight: "10px" }}>English</span>
                <img src={arrow} alt="down-arrow" />
              </div>
            </a>
          </Dropdown>
          <p className="header-text relative" style={{ paddingLeft: "15px" }}>
            Don't have an account? <Link to={"/registration"}>Sign Up</Link>
          </p>
        </div>
        <div
          className="flex items-center justify-center"
          style={{ height: "calc(100vh - 81px)" }}
        >
          <div
            className="flex flex-col justify-between login-content w-full"
            style={{
              maxWidth: "400px",
              margin: "auto",
            }}
          >
            <div>
              <img
                src={Logo}
                alt="logo"
                className="block m-auto"
                style={{ marginBottom: "43px" }}
              />
              <Form
                name="login"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                style={{ marginBottom: "20px" }}
              >
                <InputBox
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                  placeholder="Enter Email"
                />

                <InputBox
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  placeholder="Enter password"
                  type='password'
                />
                <Button
                  className="signin-btn"
                  htmlType="submit"
                  block
                  loading={isLoading}
                >
                  Sign In
                </Button>
              </Form>
              <p className="text-center">
                <a className="text-sm cursor-pointer" onClick={showModal}>Forgot username or password?</a>
              </p>
            </div>
            <FormModal
              title="Forgot Password" 
              buttonText="Send Email"
              width={500}
              form={form}
              handleCancel={hideModal}
              handleForm={forgotPassword}
              isModalOpen={isModalOpen}
              loading={isLoading ? isLoading : false}
            >
              <InputBox
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                  placeholder="Enter Email!"
                />
            </FormModal>

            <div
              className="text-sm flex justify-between ml-auto mr-auto footer-text"
              style={{
                color: "#5F617A",
                width: "380px",
              }}
            >
              <span>Got a question?</span>
              <a href="#" className="underline">
                contact@company.com
              </a>
              <span className="resp-hide">|</span>
              <a href="#">+91 9426773719</a>
            </div>
          </div>
        </div>
      </div>
    );
}
const mapStateToProps = ({loading}: RootState) => {
    const {isLoading} = loading
    return {isLoading}
}
const mapDispatchToProps = {
    onLogin,
    isLogin,
    setWalletAmount,
    setSwitchVal,
    setPermission,
    setAdminPermission
}
export default connect(mapStateToProps, mapDispatchToProps) (Login);