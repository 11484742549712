import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { LocalStorageService } from "../utils/LocalStorage";
import { ROLE } from "../consts";

const ProtectedRoute: React.FC<{
  layout: React.ComponentType<any>;
  component: React.ComponentType<any>;
  role: string;
  path?: string;
}> = ({ layout: Layout, component: Component, role, login, ...rest }: any) => {
  const userData = login.user || LocalStorageService.getItem("userData")

  const isAuthenticated = () => {
    const token = LocalStorageService.getItem('userToken')
    return (login.isUserLogin || token)
  };

  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }
  if ((role === "admin" && (userData?.role !== ROLE.admin && userData?.role !== ROLE.subAdmin)) || 
    (role === "agent" && (userData?.role !== ROLE.agent && userData?.role !== ROLE.subAgent))) {
    if(userData?.role === ROLE.admin || userData?.role === ROLE.subAdmin){
      return <Navigate to="/agent" />;
    }else if(userData?.role === ROLE.agent || userData?.role === ROLE.subAgent ){
      return <Navigate to="/home" />;
    }else{
      return <Navigate to="/login" />;
    }
  }
  
  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
};
const mapStateToProps = ({ login }: any) => {
  return { login };
};
export default connect(mapStateToProps, {})(ProtectedRoute);
