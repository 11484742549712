import {IS_LOGIN, ON_LOGIN, SET_PERMISSION , SET_ADMINPERMISSION} from "../action/loginAction"

interface PermissionValueInterface{
    key: string,
    allow: boolean
}
export interface PermissionInterface {
    country: PermissionValueInterface[];
    VISA_LIST: PermissionValueInterface[];
    NEW_VISA_REQUEST: PermissionValueInterface;
    BALANCE: PermissionValueInterface[];
    ADD_BALANCE: PermissionValueInterface;
}
export interface AdminPermissionInterface {
    VISA:PermissionInterface[];
    AGENT:PermissionInterface[];
    TRANSACTION:PermissionInterface[];
}
export interface LoginState {
    isUserLogin: boolean;
    user: any; // Define a proper type for the user property
    permission: PermissionInterface | null;
    adminPermission: AdminPermissionInterface | null;
}
const initialState:LoginState = {
    isUserLogin: false,
    user: null,
    permission: null,
    adminPermission: null,
}

const loginReducer = (state = initialState, action:any) =>{
    switch (action.type) {
        case IS_LOGIN:
            return {
                ...state,
                isUserLogin: action.payload
            }

        case ON_LOGIN:
            return {
                ...state,
                user: action.payload
            }

        case SET_PERMISSION:
            return {
                ...state,
                permission: action.payload
            }
        case SET_ADMINPERMISSION:
                return{
                    ...state,
                    adminPermission:action.payload
                }
        default:
            return state;
    }
}

export default loginReducer
